import {
	axios
} from '@/utils/request'

/**
 * 分页查询系统字典类型
 *
 * @author yubaoshan
 * @date 2020/5/17 01:46
 */
export function sysAccountTypePage(parameter) {
	return axios({
		url: '/account/list',
		method: 'post',
		params: parameter
	})
}

/**
 * 获取用户角色
 *
 * @author ymsc
 * @date 2021/05/05 12:08
 */
export function sysAccountRole() {
	return axios({
		url: '/account/accountRold',
		method: 'get'
	})
}

/**
 * 获取用户角色
 *
 * @author ymsc
 * @date 2021/05/05 12:08
 */
export function sysAccountRoleEdit() {
	return axios({
		url: '/account/accountRoldEdit',
		method: 'get'
	})
}

/**
 * 添加系统字典类型
 *
 * @author yubaoshan
 * @date 2020/5/17 01:46
 */
export function sysAccountAdd(parameter) {
	return axios({
		url: '/account/save_data',
		method: 'post',
		data: parameter
	})
}

/**
 * 编辑系统字典类型
 *
 * @author yubaoshan
 * @date 2020/5/17 01:50
 */
export function sysAccountEdit(parameter) {
	return axios({
		url: '/account/update_data',
		method: 'post',
		data: parameter
	})
}

/**
 * 删除系统字典类型
 *
 * @author yubaoshan
 * @date 2020/5/17 01:50
 */
export function sysAccountDelete(parameter) {
	return axios({
		url: '/account/delete',
		method: 'post',
		data: parameter
	})
}

/**
 * 重置密码
 *
 * @author yubaoshan
 * @date 2020/5/17 01:50
 */
export function sysAccountPassWord(parameter) {
	return axios({
		url: '/account/update_password',
		method: 'post',
		data: parameter
	})
}

/**
 * 导出
 *
 * @author ymsc
 * @date 2020/7/02 01:46
 */
export function exportExcel(parameter) {
	return axios({
		url: '/account/exportExcel',
		method: 'get',
		params: parameter,
		responseType: 'blob'
	})
}

/**
 * 获取字典类型下所有字典，举例，返回格式为：[{code:"M",value:"男"},{code:"F",value:"女"}]
 *
 * @author yubaoshan
 * @date 2020/6/10 00:10
 */
export function sysDictTypeDropDown(parameter) {
	return axios({
		url: '/sysDictType/dropDown',
		method: 'post',
		params: parameter
	})
}

/**
 * 获取所有字典，启动时加入缓存使用
 *
 * @author yubaoshan
 * @date 2020/6/10 00:10
 */
export function sysDictTypeTree(parameter) {
	return axios({
		url: '/sysDictType/tree',
		method: 'get',
		params: parameter
	})
}

export function getMenuByRoleId(parameter) {
	return axios({
		url: '/account/getMenuByRoleId',
		method: 'post',
		params: parameter
	})
}

/**
 * check删除时是否存在商户
 *
 * @author ymsc
 * @date 2020/7/02 01:46
 */
export function getCheck(parameter) {
	return axios({
		url: '/account/getCheck',
		method: 'post',
		params: parameter
	})
}
