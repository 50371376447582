import { axios } from '@/utils/request'
/**
 * 修改密码
 *
 * @author yubaoshan
 * @date 2020/5/26 19:06
 */
export function modifyPwd (parameter) {
  return axios({
    url: '/modifyPwd',
    method: 'post',
    data: parameter
  })
}

export function get () {
  return axios({
    url: '/get',
    method: 'get',
    params: null
  })
}
