<template>

	<a-row :gutter="24">
		<a-col :md="3" :sm="24">
			<a-card :bordered="false" :loading="treeLoading">
				<div v-if="this.logTree!='' ">
					<a-tree style="scroll:true" :tree-data="logTree" v-if="logTree.length" defaultExpandAll
						@select="handleClick" :replaceFields="replaceFields" :defaultSelectedKeys="defaultSelectedKeys">
					</a-tree>
				</div>
				<div v-else>
					<a-empty :image="simpleImage" />
				</div>
			</a-card>
		</a-col>

		<a-col :md="21" :sm="24">
			<a-card :bordered="false">
				<div class="table-page-search-wrapper">
					<a-form layout="inline">
						<a-row :gutter="48">
							<a-col :md="8" :sm="24">
								<a-form-item label="操作时间">
									<a-date-picker @change="onChange" v-model="queryParam.operateDay" allow-clear />
								</a-form-item>

							</a-col>

							<a-col :md="8" :sm="24">
								<a-form-item label="操作者">
									<a-input v-model="queryParam.operateUserName" allow-clear placeholder="请输入模块名称" />
								</a-form-item>

							</a-col>

							<a-col :md="8" :sm="24">
								<a-form-item label="模块名称">
									<a-input v-model="queryParam.moduleName" allow-clear placeholder="请输入模块名称" />
								</a-form-item>
							</a-col>

							<template v-if="advanced">
								<a-col :md="8" :sm="24">
									<a-form-item label="操作类型">
										<a-select v-model="queryParam.operateType" allow-clear placeholder="请选择操作类型"
											default-value="">
											<a-select-option v-for="(item,index) in operateTypeBox" :key="index"
												:value="item.dictCode">
												{{item.dictValue}}
											</a-select-option>
										</a-select>
									</a-form-item>
								</a-col>

								<a-col :md="8" :sm="24">
									<a-form-item label="期间">
										<a-select v-model="queryParam.searchTime" allow-clear placeholder="请选择期间"
											default-value="">
											<a-select-option v-for="(item,index) in searchTimeBox" :key="index"
												:value="item.dictCode">
												{{item.dictValue}}
											</a-select-option>
										</a-select>
									</a-form-item>
								</a-col>
							</template>
						</a-row>
					</a-form>
				</div>

				<div class="table-operator">
					<a-row>
						<a-col :md="24" :sm="24" style="text-align: right;">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQueryParam">重置</a-button>
								<a @click="toggleAdvanced" style="margin-left: 8px">
									{{ advanced ? '收起' : '展开' }}
									<a-icon :type="advanced ? 'up' : 'down'" />
								</a>
							</span>
						</a-col>
					</a-row>
				</div>

				<s-table ref="table" bordered size="default" :columns="columns" :data="loadData" :alert="true"
					:scroll="scroll" rowKey="id" :customRow="selectRow" :rowClassName="rowClassName" :style="operaHigh">
				</s-table>

			</a-card>
		</a-col>
	</a-row>

</template>

<script>
	import {
		STable
	} from '@/components'
	import {
		Empty
	} from 'ant-design-vue'
	import {
		getLogQueryBox,
		getLogTree,
		getLogPage
	} from '@/api/modular/system/logManage'

	export default {
		components: {
			STable
		},

		data() {
			return {
				// 高级搜索 展开/关闭
				advanced: false,
				//行选中Id
				selectRowId: '',
				// 查询参数
				queryParam: {
					logType: '10'
				},
				// 检索参数区行数
				queryRows: {
					count: 2,
					current: 1
				},
				//表格scroll
				scroll: {
					x: true,
					y: 397,
				},

				user: {},
				// 表头
				columns: [{
						title: '序号',
						dataIndex: 'no',
						width: 95,
						align: 'center'
					}, {
						title: '操作时间',
						dataIndex: 'operateTime',
						width: 180,
						align: 'center'
					},
					{
						title: '操作者',
						dataIndex: 'operateUserName',
						width: 120,
						align: 'center'
					},
					{
						title: 'IP地址',
						dataIndex: 'ipAddress',
						width: 150,
						align: 'center'
					},
					{
						title: '模块名称',
						dataIndex: 'moduleName',
						width: 250,
						align: 'center'
					},
					{
						title: '操作类型',
						dataIndex: 'operateTypeText',
						width: 120,
						align: 'center'
					},
					{
						title: '执行结果',
						dataIndex: 'executeResultText',
						width: 120,
						align: 'center'
					},
					{
						title: '执行详细',
						dataIndex: 'resultJson',
						width: 120,
					}
				],

				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					return getLogPage(Object.assign(parameter, this.queryParam)).then((res) => {
						let pageNo = res.data.pageNo;
						let pageSize = res.data.pageSize;
						res.data.rows.forEach((item, index) => {
							item.no = (pageNo - 1) * pageSize + index + 1;
						});
						return res.data
					})
				},

				logTree: [],
				selectedRowKeys: [],
				selectedRows: [],
				defaultExpandedKeys: [],
				defaultSelectedKeys: [],
				// 搜索的三个参数
				expandedKeys: [],
				searchValue: '',
				autoExpandParent: true,
				treeLoading: true,
				simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
				replaceFields: {
					key: 'dictCode',
					title: 'dictValue'
				},

				searchTimeBox: [],
				operateTypeBox: []

			}
		},

		created() {
			this.user = {
				userId: localStorage.getItem("userId"),
			};

			this.getLogTree();
			this.getLogQueryBox();
		},

		// 计算属性
		computed: {
			// 表格头部功能区高度
			operaHigh() {
				return {
					"--operaHigh": (this.queryRows.current * 56 + 268) + 'px',
				}
			},
		},

		methods: {

			/**
			 * 获取到log树，展开顶级下树节点，考虑到后期数据量变大，不建议全部展开
			 */
			getLogTree() {
				getLogTree(Object.assign(this.queryParam)).then(res => {
					this.treeLoading = false
					if (!res.success) {
						return
					}

					//获取tree类型数据
					let tree = this.toTreeData(res.data)
					this.logTree = tree

					let defaultCode = tree[0].children[0].dictCode;
					this.defaultSelectedKeys = [defaultCode];

					//默认检索
					this.queryParam.logType = defaultCode;

					this.$refs.table.refresh();
				})
			},

			/**
			 * 获取下拉框查询参数列表
			 */
			getLogQueryBox() {
				getLogQueryBox(Object.assign(this.queryParam)).then(res => {
					this.treeLoading = false
					if (!res.success) {
						return
					}

					let data = res.data;
					this.searchTimeBox = data.SearchTimeSpan;
					this.operateTypeBox = data.OperateType;
				})
			},

			handleClick(e) {
				this.queryParam = {
					logType: e.toString()
				}
				if (this.user.level != 0) {
					this.queryParam.operateUserId = this.user.userId;
				}
				this.$refs.table.refresh(true)
			},

			toggleAdvanced() {
				this.advanced = !this.advanced;
				this.queryRows.current = this.advanced ? this.queryRows.count : 1;
			},

			handleOk() {
				this.getLogTree()
				this.$refs.table.refresh()
			},

			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
			},

			onChange(date, dateString) {
				this.queryParam.operateDay = dateString;
				// console.log(date, dateString);
			},

			//改变为tree类型数据
			toTreeData(ds) {
				let l = ds.filter(function(x) {
					return x.parentTreeId == 0
				});

				//禁用顶级
				l.forEach((d) => {
					d.disabled = true;
				})

				l.forEach((y) => {
					y.children = ds.filter(function(x) {
						return x.parentTreeId == y.id;
					});

				});

				return l;
			},

			resetQueryParam() {
				this.queryParam = {
					logType: this.queryParam.logType,
				}

				if (this.user.level != 0) {
					this.queryParam.operateUserId = this.user.userId;
				}
			},

			// 点击行变色 rowkey
			selectRow(record) {
				return {
					props: {},
					on: {
						click: () => {
							this.selectRowId = record.id
						},
					}
				}
			},

			// 点击行变色 class
			rowClassName(record) {
				return record.id === this.selectRowId ? 'selectRow' : '';
			},
		}
	}
</script>

<style scoped lang="less">
</style>
