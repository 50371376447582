import { axios } from '@/utils/request'

/**
 * 分页查询系统操作日志
 *
 * @author yubaoshan
 * @date 2020/5/17 01:46
 */
export function getLogPage (parameter) {
  return axios({
    url: '/syslog/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取log树
 *
 * @author ymsc
 * @date 2021/05/05 12:08
 */
export function getLogTree (parameter) {
  return axios({
    url: '/syslog/tree',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取log查询参数数据
 *
 * @author ymsc
 * @date 2021/05/05 12:08
 */
export function getLogQueryBox (parameter) {
  return axios({
    url: '/syslog/queryBox',
    method: 'get',
    params: parameter
  })
}