<template>
	<a-modal title="增加店铺" :width="900" :visible="visible" :maskClosable="false" :confirmLoading="confirmLoading" okText="保存" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">

				<a-form-item label="店铺名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入店铺名称" :max-length="50" autocomplete="off"
						v-decorator="['shopName', {rules: [{required: true, message: '请输入店铺名称！'}]}]" />
				</a-form-item>
				<a-form-item label="省名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-select placeholder="请选择省"
						v-decorator="['provinceId', {rules: [{required: true, message: '请选择省名称！'}]}]"
						@change="provincesChange">
						<a-select-option v-for="(item,index) in provinces" :key="'province'+index"
							:value="item.provinceId">
							{{item.provinceName}}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="市名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-select placeholder="请选择市"
						v-decorator="['cityId', {rules: [{required: true, message: '请选择市名称！'}]}]">
						<a-select-option v-for="(item,index) in citys" :key="'city'+index" :value="item.cityId">
							{{item.cityName}}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="详细地址" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入详细地址" :max-length="50" autocomplete="off"
						v-decorator="['address', {rules: [{required: true, message: '请输入详细地址！'}]}]" />
				</a-form-item>

			</a-form>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		vShopInfoAdd
	} from '@/api/modular/business/vShopInfoManage'
	import {
		initProvinceBoxs,
		changeCityBox,
	} from '@/api/modular/system/areaManage.js'

	export default {
		data() {
			return {
				form: this.$form.createForm(this),
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},

				//登录用户信息
				user: {
					userId: localStorage.getItem("userId"),
				},
				queryParam: {},
				provinces: [],
				provinceId: '',
				citys: [],
				roles: [],
				visible: false,
				confirmLoading: false,
			}
		},

		created() {
			this.getProvinces();
		},

		methods: {
			// 初始化方法
			add() {
				this.visible = true
				this.provinceId = ''
				this.citys = []
			},

			//权限省下拉选择
			provincesChange(value) {
				this.provinceId = value
				this.citys = [];
				setTimeout(() => {
					this.form.resetFields(
						['cityId']
					);
					this.confirmLoading = false
				}, 200)
				this.getCitys();
			},

			//获取市数据
			getProvinces() {
				var that = this
				initProvinceBoxs().then(res => {
					if (res.success) {
						that.provinces = res.data;
					}
				})
			},

			getCitys() {
				//联动查询省级下市列表
				let param = {
					id: this.provinceId
				}
				//查询下级市列表
				changeCityBox(param).then(res => {
					if (res.success) {
						this.citys = res.data;
					}
				})
			},

			//ok方法  提交
			handleSubmit() {
				const {
					form: {
						validateFields
					}
				} = this;
				this.confirmLoading = true;
				// 校验
				validateFields((errors, values) => {
					// 校验通过
					if (!errors) {
						// 调用接口
						vShopInfoAdd(values).then((res) => {
							// 返回成功
							if (res.success) {
								this.$message.success('新增成功');
								this.visible = false;
								this.$emit('ok', values);
								this.form.resetFields();
								// 返回失败
							} else {
								this.$warning({
									okText: '确定',
									title: '提示信息',
									content: "新增失败，原因：" + res.message
								});
							}
							// 异常
						}).catch((err) => {
							this.$error({
								okText: '确定',
								title: '提示信息',
								content: "新增失败，原因：" + err.response.data.message
							});
							// finally
						}).finally(() => {
							this.confirmLoading = false;
						})
						// 校验不通过
					} else {
						this.confirmLoading = false;
					}
				})
			},

			// -> 基础功能
			// 取消、关闭Modal
			handleCancel() {
				this.visible = false;
				this.confirmLoading = false;
				this.form.resetFields();
				this.provinceId = ''
				this.citys = []
			},
		}
	}
</script>
