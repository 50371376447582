<template>
	<a-modal title="增加字典" :width="900" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" okText="保存" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">

				<a-form-item label="字典类型" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :max-length="50" placeholder="请输入类型名称"
						v-decorator="['dictType', {rules: [{required: true, message: '请输入字典类型！'}]}]" />
				</a-form-item>

				<a-form-item label="字典编码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :max-length="50" placeholder="请输入字典编码"
						v-decorator="['dictCode', {rules: [{required: true, message: '请输入字典编码！'}]}]" />
				</a-form-item>

				<a-form-item label="字典值" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :max-length="200" placeholder="请输入字典值" style="width: 100%;"
						v-decorator="['dictValue', {rules: [{required: true, message: '请输入字典值！'}]}]" />
				</a-form-item>

				<a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-textarea :max-length="500" :rows="4" placeholder="请输入备注" v-decorator="['memo']"></a-textarea>
				</a-form-item>

			</a-form>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		sysDictTypeAdd
	} from '@/api/modular/system/dictManage'

	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this)
			}
		},

		methods: {
			// 初始化方法
			add() {
				this.visible = true
			},

			// 提交
			handleSubmit() {
				const {
					form: {
						validateFields
					}
				} = this
				this.confirmLoading = true
				validateFields((errors, values) => {
					if (!errors) {
						sysDictTypeAdd(values).then((res) => {
							if (res.success) {
								this.$message.success('新增成功')
								this.visible = false
								this.confirmLoading = false
								this.$emit('ok', values)
								this.form.resetFields()
							} else {
								this.$message.error('新增失败：' + res.message)
							}
						}).catch(() => {
							this.$message.error('新增失败！')
						}).finally(() => {
							this.confirmLoading = false
						})
					} else {
						this.confirmLoading = false
					}
				})
			},

			// 取消、关闭对话框
			handleCancel() {
				this.form.resetFields()
				this.visible = false
			}
		}
	}
</script>
