<template>
	<div :class="[ disabled ? 'upload-item-disabled' : 'upload-item' ]">

		<a-upload :file-list="files" list-type="picture" :show-upload-list="false" :customRequest="uploadFile"
			:multiple="false" accept=".jpg,.png,.jpeg" :remove="removeFile" :disabled="disabled">

			<img v-if="value" :src="imageSrc" alt="" style="width: 100%;height: 100%;" />

			<div v-else class="upload-pointer">
				<a-icon :type="loading ? 'loading' : 'plus'" class="upload-icon" />
				<div class="ant-upload-text">
					上传
				</div>
			</div>

		</a-upload>
	</div>
</template>

<script>
	import {
		mixin
	} from '@/utils/mixin'
	import {
		uploadFile
	} from '@/api/modular/system/fileManage'

	export default {
		name: 'ImageUpload',
		mixins: [mixin],
		model: {
			prop: "value",
			event: "change"
		},
		props: {
			value: {
				type: String,
				required: true,
				default: '',
			},
			imgHttpPath: {
				type: String,
				required: false,
				default: '',
			},
			disabled: {
				type: Boolean,
				default: false
			},
			avatar: {
				type: Boolean,
				default: false
			}
		},

		// data
		data() {
			return {
				// 文件列表
				files: [],
				filePath: this.value,
				loading: false,
			}
		},

		// 侦听
		watch: {
			value(val) {
				this.filePath = val;
			}
		},

		// 计算属性
		computed: {
			// 全路径
			imageSrc() {
				return this.imgHttpPath + this.filePath;
			},
		},

		// 方法函数
		methods: {

			// 文件上传
			uploadFile(options) {
				// loading
				this.loading = true;

				// 存在相同
				let same = false;

				//判定
				this.files.forEach((item) => {
					// 名字相同  存在重复
					if (item.name == options.file.name) {
						same = true;
					}
				});

				//判定结果
				if (same) {
					this.$message.error("请不要上传重复文件")
					return;
				}

				// 上传  设置别名（other）
				if (options.filename) {
					// 设置other
					options.file.other = options.filename;
				}

				// 文件数据
				let data = new FormData();
				data.append('file', options.file);
				data.append('type', options.file.type)

				// 上传文件
				uploadFile(data).then((res) => {
					// 进度条结束
					options.onSuccess(res, options.file);
					// 保存
					// this.files.push(options.file);
					// 文件路径
					this.filePath = res.filePath;
					// 打包回执
					this.bundleReceipt();

				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '错误信息',
						content: "上传失败，原因：" + err.response.data.message,
					});
				}).finally(() => {
					// loading结束
					this.loading = false;
				})
			},

			//移除文件
			removeFile(file) {
				// 声明操纵文件数组
				let files = this.files;
				// 检索
				let index = files.findIndex((item) => {
					return item.uid == file.uid;
				})
				// 存在
				if (index != -1) {
					files.splice(index, 1)
				} else {
					return false;
				}
			},

			// 发送回执
			bundleReceipt() {
				this.$emit("change", this.filePath);
			}
		}
	}
</script>


<style lang="less">
</style>
