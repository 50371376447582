import Vue from 'vue'
import App from './App.vue'
import router from './router'
import utils from './utils/utils.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/'
import './components/global.less'
import 'ant-design-vue/dist/antd.css'
import antDesignVue from 'ant-design-vue'
import VueStorage from 'vue-ls'
  import * as echarts from 'echarts'

import {
	ACCESS_TOKEN
} from '@/store/mutation-types'
import bootstrap from './core/bootstrap'

Vue.use(antDesignVue, store, VueAxios, axios)
Vue.use(VueStorage, {})
Vue.config.productionTip = false
Vue.prototype.utils = utils
Vue.prototype.$echarts = echarts

router.beforeEach((to, from, next) => {
	const require = to.matched.some(function(item) { // 是否需要登录
		return item.meta.requireAuth
	})
	if (require) { //判断该路由是否需要登录
		if (Vue.ls.get(ACCESS_TOKEN)) { //vuex获取当前token是否存在
			next()
		} else {
			next({
				path: '/',
				query: {
					redirect: to.fullPath
				} //将跳转的路由path作为参数，登录成功后跳转该路由
			})
		}
	} else {
		//无需登录直接跳转页面
		next()
	}
})

/* eslint-disable no-new */
new Vue({
	router,
	render: h => h(App),
	store,
	created: bootstrap,
}).$mount("#app")
