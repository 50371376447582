<template>
	<a-card :bordered="false" :confirmLoading="confirmLoading">
		<a-spin :spinning="confirmLoading">
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :sm="24" :md="8">
							<a-form-item label="店铺名称">
								<a-input v-model="queryParam.shopName" placeholder="请输入店铺名称" tabindex="1"
									maxlength="500" allow-clear />
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</div>

			<div class="table-operator">
				<a-row>
					<a-col :md="17" :sm="24">
						<a-button type="primary" icon="plus" @click="$refs.addForm.add()">增加店铺</a-button>

						<a-upload :file-list="fileList" list-type="picture" :show-upload-list="false"
							:customRequest="uploadFile" :multiple="false" accept=".xls,.xlsx"
							:disabled="uploadDisabled">
							<a-button icon="upload">导入店铺</a-button>
						</a-upload>
					</a-col>
					<a-col :md="7" :sm="24" style="text-align: right;">
						<span class="table-page-search-submitButtons"
							:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
							<a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
							<a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
						</span>
					</a-col>
				</a-row>
			</div>

			<s-table bordered ref="table" size="default" :columns="columns" :data="loadData" :alert="false"
				:scroll="scroll" rowKey="shopId" :customRow="selectRow" :rowClassName="rowClassName" :style="operaHigh">
				<span slot="action" slot-scope="text, record">
					<a @click="$refs.editForm.edit(record)">编辑</a>
					<a-divider type="vertical" />
					<a @click="delvShopInfo(record)">删除</a>
				</span>

			</s-table>
		</a-spin>

		<add-form ref="addForm" @ok="handleOk" />
		<edit-form ref="editForm" @ok="handleOk" />

	</a-card>
</template>

<script>
	import {
		STable
	} from '@/components'
	import {
		initRelatedParam,
		vShopInfoPage,
		vShopInfoDelete,
		importExcel
	} from '@/api/modular/business/vShopInfoManage'
	import {
		uploadFile
	} from '@/api/modular/system/fileManage'

	import addForm from './addForm'
	import editForm from './editForm'
	export default {
		components: {
			STable,
			addForm,
			editForm
		},

		data() {
			return {
				// 高级搜索 false: 默认收取 true: 默认关闭
				advanced: true,
				//行选中Id
				selectRowId: '',
				// 查询参数
				queryParam: {
					type: 0 // 0：用户管理  1：子账号管理
				},
				// 检索参数区相关
				queryRows: {
					count: 2,
					current: 1
				},
				//表格scroll
				scroll: {
					x: true,
					y: 397,
				},
				// 表头
				columns: [{
						title: '序号',
						dataIndex: 'no',
						width: 95,
						align: 'center'
					},
					{
						title: '店铺名称',
						dataIndex: 'shopName',
						align: 'left',
					},
					{
						title: '地址',
						dataIndex: 'detailedAddress',
						align: 'left',
						width: "60%",
					},
					{
						title: '操作',
						width: 110,
						dataIndex: 'action',
						align: 'center',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					return vShopInfoPage(Object.assign(parameter, this.queryParam)).then((res) => {
						res.data.rows.forEach((item, index) => {
							item.no = (res.data.pageNo - 1) * res.data.pageSize + index + 1;
							item.detailedAddress = item.provinceName + ' ' + item.cityName + ' ' + item
								.address
						});
						return res.data;
					})
				},
				selectedRowKeys: [],
				selectedRows: [],
				confirmLoading: false,

				fileList: [],
				uploadDisabled: false,
			}
		},

		created() {},

		// 计算属性
		computed: {
			// 表格头部功能区高度
			operaHigh() {
				return {
					"--operaHigh": (this.queryRows.current * 56 + 268) + 'px',
				}
			},
		},

		methods: {

			initRelatedParam() {
				initRelatedParam({}).then((res) => {
					// 成功
					if (res.success) {
						// 关联参数取得
						this.relatedParam = res.data;
						// 初始化子组件关联参数
						this.$refs
							.addForm.initRelatedParam(res.data);
						this.$refs
							.editForm.initRelatedParam(res.data);
					} else {
						this.$error({
							okText: '确定',
							title: '提示信息',
							content: "请求关联参数失败，原因：" + res.message
						});
					}
				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '提示信息',
						content: "请求关联参数失败，原因：" + err.response.data.message,
					});
				})
			},

			// 点击行变色 rowKey
			selectRow(record) {
				return {
					props: {},
					on: {
						click: () => {
							this.selectRowId = record.shopId
						},
					}
				}
			},

			// 点击行变色 class
			rowClassName(record) {
				return record.shopId === this.selectRowId ? 'selectRow' : '';
			},

			handleOk() {
				this.$refs.table.refresh()
			},

			// 删除
			delvShopInfo(record) {
				// 询问框
				this.$confirm({
					title: '删除确认',
					content: '您是否要删除吗？',
					okText: '确定',
					cancelText: '取消',
					onOk: () => {
						// 调用接口
						vShopInfoDelete(record).then((res) => {
							// 返回成功
							if (res.success) {
								this.$message.success('删除成功');
								this.$refs.table.refresh();
								// 返回失败
							} else {
								//TODO + res.message
								this.$warning({
									okText: '确定',
									title: '提示信息',
									content: "删除失败，原因：" + res.message
								});
							}
							// 异常
						}).catch((err) => {
							this.$error({
								okText: '确定',
								title: '提示信息',
								content: "删除失败，原因：" + err.response.data.message
							});
						})
					},
					// 取消按钮
					onCancel() {}
				})
			},

			/* ------------------------------------ */
			// 文件上传
			uploadFile(options) {
				// loading
				this.confirmLoading = true;
				this.uploadDisabled = true;

				// 上传  设置别名（other）
				if (options.filename) {
					// 设置other
					options.file.other = options.filename;
				}

				// 文件数据
				let data = new FormData();
				data.append('file', options.file);
				data.append('type', options.file.type)

				// 上传文件
				uploadFile(data).then((res) => {
					// 进度条结束
					options.onSuccess(res, options.file);
					// 文件名称
					let fileName = res.fileName;
					// 导入
					this.importExcel(fileName);

				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '错误信息',
						content: "上传失败，原因：" + err.response.data.message,
					});
				}).finally(() => {
					// loading结束
					this.confirmLoading = false;
					this.uploadDisabled = false;
				})
			},

			// 导入
			importExcel(fileName) {
				// loading
				this.confirmLoading = true;
				this.uploadDisabled = true;
				// 导入
				importExcel({
					fileName: fileName
				}).then((res) => {
					// 成功
					if (res.success) {
						this.$message.success('导入成功');
						this.$refs.table.refresh();
						if (res.other) {
							this.$info({
								title: '提示',
								content: res.other,
								onOk() {},
							});
						}
					}
					// 失败
					else {
						this.$error({
							okText: '确定',
							title: '导入失败',
							content: (h) => {
								return h("div", {
									domProps: {
										innerHTML: res.message
									}
								})
							}
						});
					}

				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '错误信息',
						content: "导入失败，原因：" + err.response.data.message,
					});
				}).finally(() => {
					// loading结束
					this.confirmLoading = false;
					this.uploadDisabled = false;
				})
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/.ant-card-body .ant-table-thead>tr>th {
		text-align: center !important
	}
</style>
