import {
	mapState
} from 'vuex'
const mixin = {
	computed: {
		...mapState({
			navTheme: state => state.app.theme,
			primaryColor: state => state.app.color,
			headerColor: state => state.app.headerColor,
		})
	},
}

export {
	mixin
}
