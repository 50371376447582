<template>
  <a-spin :spinning="confirmLoading">
    <a-anchor :affix="true" @click="handleAnchorClick" :showInkInFixed="false">
      <div class="anchor-card">
        <a-row :gutter="24" type="flex" justify="center">
          <a-col :span="3">
            <a-anchor-link href="Anchor_Basic" title="基础信息" />
          </a-col>
          <!-- 			<a-col :span="3">
						<a-anchor-link href="Anchor_activity" title="生涯信息" />
					</a-col> -->
        </a-row>
      </div>
    </a-anchor>

    <a-form :form="form">
      <a-card id="Anchor_Basic">
        <div slot="title" style="margin-left: 1.5rem;">
          <a-divider type="vertical" class="divider-tag" style="background-color:#0077DD;" />
          <span class="card-title">基础信息</span>
        </div>

        <a-form-item v-if="isOperate" style="display: none;">
          <a-input v-decorator="['openId']" />
        </a-form-item>

        <!-- 图片选择器 -->
        <a-form-item label="头像" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['headImage', {rules: [{required: true, message: '请上传头像！'}]}]" placeholder="请上传头像"
            style="display: none;" />

          <div :class="[ isSee ? 'upload-item-disabled' : 'upload-item' ]" style="width: 7.5rem;">
            <a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" name="headImage"
              :file-list="files.headImage" list-type="picture" :customRequest="uploadFile" :multiple="false"
              accept=".jpg,.png,.jpeg" :disabled="isSee">

              <img v-if="uploadRes.headImage.sign" :src="uploadRes.headImage.src" alt="avatar"
                style="width: 6.25rem;height: 6.25rem;" />

              <div v-else class="upload-pointer">
                <a-icon type="plus" class="upload-icon" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
          </div>
        </a-form-item>

        <a-form-item label="用户姓名" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input placeholder="请输入用户姓名" :maxlength="255" :disabled="isSee"
            v-decorator="['userName', {rules: [{required: false, message: '请输入用户姓名！'}]}]" />
        </a-form-item>

        <a-form-item label="昵称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input placeholder="请输入昵称" :max-length="255" :disabled="isSee"
            v-decorator="['nickName', {rules: [{required: false, message: '请输入昵称！'}]}]" />
        </a-form-item>

        <!-- 				<a-form-item label="手机号码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入手机号码" :maxlength="20" :disabled="isSee"
						v-decorator="['phone', {rules: [{required: false, message: '请输入手机号码！'}]}]" />
				</a-form-item> -->

        <!-- <a-form-item label="性别" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-select v-decorator="['sex', {rules: [{required: false, message: '请选择性别！'}]}]" :disabled="isSee"
						:getPopupContainer="(triggerNode)=>triggerNode.parentNode" placeholder="请选择性别"
						:filter-option="filterOption" show-search option-filter-prop="children" style="width: 100%">
						<a-select-option v-for=" (item) in relatedParam.sexs" :key="item.dictCode">
							{{ item.dictValue }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入邮箱" :max-length="50" :disabled="isSee"
						v-decorator="['email', {rules: [{required: false, message: '请输入邮箱！'}]}]" />
				</a-form-item>

				<a-form-item label="身份证号" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入身份证号" :max-length="50" :disabled="isSee"
						v-decorator="['idCard', {rules: [{required: false, message: '请输入身份证号！'}]}]" />
				</a-form-item> -->

        <a-form-item label="注册时间" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-date-picker show-time :format="dateFormat" :disabled="true"
            v-decorator="['createdDatetime', {rules:[{ required: true, message: '请选择注册时间!' }]}]" />
        </a-form-item>
      </a-card>

    </a-form>
    <div style="height: 60px;">
      <div class="r">
        <div v-if="isSee">
          <a-button @click="handleCancel">
            关闭
          </a-button>
        </div>
        <!-- <div v-else>
          <a-button type="primary" @click="handleSubmit">
            保存
          </a-button>
          <a-button @click="handleCancel" style="margin-left: 40px;">
            关闭
          </a-button>
        </div> -->
      </div>
    </div>

  </a-spin>
</template>

<script>
  import moment from 'moment'
  import {
    vWechatUserEdit,
  } from '@/api/modular/business/wechatUserManage'

  import {
    uploadFile
  } from '@/api/modular/system/fileManage'

  export default {
    data() {
      let _t = this;
      return {
        // 表单layout
        labelCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 3
          }
        },
        wrapperCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 18
          }
        },
        offsetWrapperCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 18,
            offset: 3,
          }
        },
        tableWrapperCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 18,
            offset: 3,
          }
        },
        // loading
        confirmLoading: false,
        // 关联参数
        relatedParam: {},
        // 表单
        form: this.$form.createForm(this),
        // 操作区分   true：编辑   false：新增
        isOperate: false,
        // 是否查看
        isSee: false,
        // 时间格式化
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        // 文件列表
        files: {
          // 头像
          headImage: [],
        },
        // 上传回执   单图特化
        uploadRes: {
          // 头像
          headImage: {
            sign: false,
            src: ''
          }
        },
      }
    },

    // 方法函数
    methods: {

      // 编辑 || 查看 初始化方法
      operate(record, look) {
        // 编辑时为true
        this.isOperate = true;
        // 是否查看
        this.isSee = look;
        // 延时赋初值
        setTimeout(() => {

          // 预览图渲染  必填项无需判定
          this.uploadRes = {
            // 预览图
            headImage: {
              sign: true,
              src: record.headImage
            }
          };

          this.form.setFieldsValue({
            openId: record.openId,
            headImage: record.headImage,
            userName: record.userName,
            nickName: record.nickName,
            // phone: record.phone,
            email: record.email,
            idCard: record.idCard,
            createdDatetime: record.createdDatetime,
          })

          if (record.sex) {
            this.form.setFieldsValue({
              sex: record.sex ? record.sex : null,
            })
          }

        }, 100);
      },

      //ok方法  提交
      handleSubmit() {
        const {
          form: {
            validateFields
          }
        } = this;
        this.confirmLoading = true;
        // 校验
        validateFields((errors, values) => {
          // 校验通过
          if (!errors) {
            // 数据处理
            let data = this.processData(values);

            // 调用编辑接口 
            vWechatUserEdit(data).then((res) => {
              // 返回成功
              if (res.success) {
                this.$message.success('编辑成功');
                this.$emit('ok', data);
                // 重置数据
                this.reset();

                // 返回失败
              } else {
                this.$warning({
                  okText: '确定',
                  title: '提示信息',
                  content: "编辑失败，原因：" + res.message
                });
              }
              // 异常
            }).catch((err) => {
              this.$error({
                okText: '确定',
                title: '提示信息',
                content: "编辑失败，原因：" + err.response.data.message
              });
              // finally
            }).finally(() => {
              this.confirmLoading = false;
            })

            // 校验不通过
          } else {
            this.confirmLoading = false;
          }
        })
      },

      // 处理数据
      processData(values) {
        // 声明  时间为必填项  无需特殊判断
        let data = {
          ...values,
        }

        // 返回
        return data;
      },


      // ->图片上传
      // 文件上传
      uploadFile(options) {
        // 存在相同
        let same = false;

        //判定
        this.files[options.filename].forEach((item) => {
          // 名字相同  存在重复
          if (item.name == options.file.name) {
            same = true;
          }
        });

        //判定结果
        if (same) {
          this.$message.error("请不要上传重复文件")
          return;
        }

        // 上传  设置别名（other）
        if (options.filename) {
          // 设置other
          options.file.other = options.filename;
          // 保存文件数据
          this.files[options.filename].push(options.file);
        }

        //文件数据
        let data = new FormData();
        data.append('file', options.file);
        data.append('type', options.file.type)

        //上传文件
        uploadFile(data).then((res) => {
          //进度条结束
          options.onSuccess(res, options.file);

          // res区分
          if (options.filename == "headImage") {
            //表单赋值
            this.form.setFieldsValue({
              headImage: res.filePath,
            });
            // 回执结果
            this.uploadRes['headImage'] = {
              sign: true,
              src: res.src
            }
            // 每次置空   只上传一张
            this.files[options.filename] = [];

          } else {
            //表单赋值
            this.form.setFieldsValue({
              internalAccessoryUrl: res.filePath,
            });
          }

        }).catch((err) => {
          this.$error({
            okText: '确定',
            title: '错误信息',
            content: "上传失败，原因：" + err.response.data.message,
          });
        }).finally(() => {
          this.confirmLoading = false;
        })
      },

      //移除文件
      removeFile(file) {
        // 声明操纵文件数组
        let files;
        // 根据other确定声明
        if (file.other == "accessoryUrl") {
          files = this.files.accessoryFiles;
          //表单赋值
          this.form.setFieldsValue({
            accessoryUrl: null,
          });
        } else {
          files = this.files.internalAccessoryFiles;
          //表单赋值
          this.form.setFieldsValue({
            internalAccessoryUrl: null,
          });
        }

        // 检索
        let index = files.findIndex((item) => {
          return item.uid == file.uid;
        })

        // 存在
        if (index != -1) {
          files.splice(index, 1)
        } else {
          return false;
        }
      },


      // ---> 基础功能
      // 关联参数初始化
      initRelatedParam(relatedParam) {
        this.relatedParam = relatedParam;
        this.confirmLoading = false;
      },

      /**
       * 准备渲染上传文件
       * @param  attribute 文件列表(files) 的属性名
       * @param  fileSrc  渲染数据的src
       */
      prepareDoneFile(attribute, fileSrc) {
        // 默认uid  name
        let name = JSON.parse(JSON.stringify(fileSrc))
        // 是否存在文件夹
        let index = name.lastIndexOf("/")
        // 存在
        if (index != -1) {
          name = name.substring(index + 1);
        }
        // 设置文件
        this.files[attribute] = [{
          uid: name,
          name: name,
          status: 'done',
          url: this.relatedParam.imgHttpPath + fileSrc,
          other: attribute
        }, ]
      },

      // 取消、关闭Modal
      handleCancel() {
        this.$emit('no', {});
        this.confirmLoading = false;
        this.reset();
      },

      // 重置数据
      reset() {
        // 表单
        this.form.resetFields();
        // 文件列表
        this.files = {
          headImage: [],
        };
        // 文件回执
        this.uploadRes = {
          headImage: {
            sign: false,
            src: ''
          }
        };

        // 标志
        this.isOperate = false;
        this.isSee = false;
      },

      //模板匹配
      filterOption(input, option) {
        return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0);
      },

      // 日期控件默认时分秒
      momentTime(time, format) {
        return moment(time, format)
      },

      // 日期禁用
      disabledDate(current) {
        // moment(this.relatedParam.today)
        return current < moment().subtract(1, 'day');
      },

      // 快速跳转
      handleAnchorClick(e, link) {
        // 阻止点击的默认事件修改路由
        e.preventDefault();
        var srcolls = document.getElementById(link.href);
        srcolls.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      },

    }
  }
</script>

<style scoped lang="less">
  /deep/.r {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 70px;
    background-color: #ededed;
    left: 125px;
    text-align: center;
    padding: 20px 0;
    z-index: 99;
  }

  /deep/.bottom {
    width: 30%;
    height: 37px;
    font-size: 16px;
    font-weight: 700;
    position: fixed;
    bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    left: 40%;
    z-index: 99;
  }

  /deep/.ant-card-head {
    border-bottom: none;
  }

  /deep/.divider-tag {
    width: 0.3rem;
    height: 1rem;
  }

  /deep/.ant-anchor-link-title {
    font-size: 17px;
    font-weight: 900;
  }

  /deep/.card-title {
    font-size: 19px;
    font-weight: 900;
  }

  /deep/.ant-card-body {
    .ant-table-body {
      min-height: 0.0625rem;
    }

    .ant-table-placeholder {
      margin-top: 0;
      border: 1px solid #e8e8e8
    }
  }

  /deep/.ant-anchor-wrapper {
    padding: 0.2rem;

    .anchor-card {
      text-align: center;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      padding: 0.625rem;
      font-size: 1rem;
    }

    .ant-anchor-ink {
      height: 0;
    }

    .ant-anchor-ink-ball.visible {
      display: none;
    }
  }

  /deep/.editor-ontainer {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    margin-top: 10px;
    z-index: 500;

    .w-e-text-container {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: bold;
      }

      h1 {
        font-size: 2em;
      }
    }

    .toolbar {
      border-bottom: 1px solid #ccc;
    }

    .astrict {
      height: 70vh;
      overflow-y: hidden
    }
  }

  /deep/.editor-ontainer-disabled {
    .w-e-toolbar {
      background-color: #f5f5f5;
    }

    .w-e-text-container {
      background-color: #f5f5f5;
    }
  }
</style>
