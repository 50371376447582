<template>
	<a-card :bordered="false">
		<div class="table-page-search-wrapper">
			<a-form layout="inline">
				<a-row :gutter="48">
					<a-col :md="8" :sm="24">
						<a-form-item label="字典类型">
							<a-input :max-length="50" v-model="queryParam.dictType" allow-clear placeholder="请输入字典类型" />
						</a-form-item>
					</a-col>
					<!-- <a-col :md="8" :sm="24">
						<a-form-item label="字典编码">
							<a-input :max-length="50" v-model="queryParam.dictCode" allow-clear placeholder="请输入字典编码" />
						</a-form-item>
					</a-col> -->
					<a-col :md="8" :sm="24">
						<a-form-item label="字典值">
							<a-input :max-length="100" v-model="queryParam.dictValue" allow-clear
								placeholder="请输入字典值" />
						</a-form-item>
					</a-col>
				</a-row>
			</a-form>
		</div>

		<div class="table-operator">
			<a-row>
				<a-col :md="24" :sm="24" style="text-align: right;">
					<span class="table-page-search-submitButtons"
						:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
						<a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
						<a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
					</span>
				</a-col>
			</a-row>
		</div>

		<s-table ref="table" bordered size="default" :columns="columns" :data="loadData" :alert="false" :scroll="scroll"
			rowKey="id" :customRow="selectRow" :rowClassName="rowClassName" :style="operaHigh">
			<span slot="status" slot-scope="text">
				{{ statusFilter(text) }}
			</span>

			<span slot="action" slot-scope="text, record">
				<a @click="$refs.editForm.edit(record)">编辑</a>
				<!-- <a-divider type="vertical" /> -->
				<!-- <a @click="delDict(record)">删除</a> -->
			</span>

		</s-table>

		<add-form ref="addForm" @ok="handleOk" />
		<edit-form ref="editForm" @ok="handleOk" />

	</a-card>
</template>

<script>
	import {
		STable
	} from '@/components'
	import {
		sysDictTypePage,
		sysDictTypeDelete
	} from '@/api/modular/system/dictManage'
	import addForm from './addForm'
	import editForm from './editForm'

	export default {
		components: {
			STable,
			addForm,
			editForm
		},

		data() {
			return {

				// 高级搜索 false: 默认收取 true: 默认关闭
				advanced: true,
				//行选中Id
				selectRowKey: '',
				// 查询参数
				queryParam: {},
				// 检索参数区行数
				queryRows: {
					count: 2,
					current: 1
				},
				//表格scroll
				scroll: {
					x: true,
					y: 397,
				},
				// 表头
				columns: [{
						title: '序号',
						dataIndex: 'no',
						width: 95,
						align: 'center'
					},
					{
						title: '字典类型',
						dataIndex: 'dictType',
						width: 200
					},
					{
						title: '字典编码',
						dataIndex: 'dictCode',
						width: 100,
					},
					{
						title: '字典值',
						dataIndex: 'dictValue',
						width: 180,
					},
					{
						title: '备注',
						dataIndex: 'memo',
						// width: 230,
					},
					{
						title: '操作',
						width: 90,
						dataIndex: 'action',
						align: 'center',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					let that = this;
					return sysDictTypePage(Object.assign(parameter, this.queryParam)).then((res) => {
						let pageNo = res.data.pageNo;
						let pageSize = res.data.pageSize;
						res.data.rows.forEach((item, index) => {
							item.no = (pageNo - 1) * pageSize + index + 1;
						});
						return res.data
					})
				},
				selectedRowKeys: [],
				selectedRows: [],
				statusDict: [],
			}
		},

		created() {},

		// 计算属性
		computed: {
			// 表格头部功能区高度
			operaHigh() {
				return {
					"--operaHigh": (this.queryRows.current * 56 + 268) + 'px',
				}
			},
		},

		// 方法函数
		methods: {

			statusFilter(status) {
				// eslint-disable-next-line eqeqeq
				const values = this.statusDict.filter(item => item.code == status)
				if (values.length > 0) {
					return values[0].value
				}
			},

			toggleAdvanced() {
				this.advanced = !this.advanced
			},

			handleOk() {
				this.$refs.table.refresh()
			},

			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
			},

			delDict(record) {
				this.$confirm({
					title: '删除确认',
					content: '您是否要删除吗？',
					okText: '确定',
					cancelText: '取消',
					onOk: () => {
						sysDictTypeDelete(record).then((res) => {
							if (res.success) {
								this.$message.success('删除成功')
								this.$refs.table.refresh()
							} else {
								this.$message.error('删除失败：' + res.message)
							}
						}).catch((err) => {
							this.$message.error('删除错误：' + err.message)
						})
					},
					onCancel() {}
				})
			},

			// 点击行变色 rowkey
			selectRow(record) {
				return {
					props: {},
					on: {
						click: () => {
							this.selectRowKey = record.id
						},
					}
				}
			},

			// 点击行变色 class
			rowClassName(record) {
				return record.id === this.selectRowKey ? 'selectRow' : '';
			},
		}
	}
</script>

<style scoped lang="less">
</style>
