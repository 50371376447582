import {axios} from '@/utils/request'

/**
 * 初始化相关参数
 *
 * @author YMSC
 * @date 2022-05-06
 */
export function initRelatedParam(parameter) {
    return axios({
        url: '/vVoteInfo/initRelatedParam',
        method: 'post',
        params: parameter
    })
}

/**
 * 分页查询的数据
 *
 * @author YMSC
 * @date 2022-05-06
 */
export function vVoteInfoPage(parameter) {
    return axios({
        url: '/vVoteInfo/page',
        method: 'post',
        params: parameter
    })
}

/**
 * 添加
 *
 * @author YMSC
 * @date 2022-05-06
 */
export function vVoteInfoAdd(data) {
    return axios({
        url: '/vVoteInfo/save_data',
        method: 'post',
        data: data
    })
}

/**
 * 编辑
 *
 * @author YMSC
 * @date 2022-05-06
 */
export function vVoteInfoEdit(data) {
    return axios({
        url: '/vVoteInfo/edit_data',
        method: 'post',
        data: data
    })
}

/**
 * 删除
 *
 * @author YMSC
 * @date 2022-05-06
 */
export function vVoteInfoDelete(data) {
    return axios({
        url: '/vVoteInfo/delete',
        method: 'post',
        data: data
    })
}