<template>
	<a-modal title="编辑区域" :width="900" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" okText="修改" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">

				<!-- 省编辑 -->
				<template v-if="type == 1">
					<a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input v-decorator="['provinceId']" />
					</a-form-item>

					<a-form-item label="省份名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="20" placeholder="请输入省份名称"
							v-decorator="['provinceName', {rules: [{required: true, message: '请输入省份名称！'}]}]" />
					</a-form-item>

					<a-form-item label="行政区划代码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="50" placeholder="请输入行政区划代码" v-decorator="['code']" />
					</a-form-item>

					<a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="6" placeholder="请输入排序" v-decorator="['sort']" />
					</a-form-item>

					<a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="50" type="textarea" placeholder="请输入备注" v-decorator="['remark']" />
					</a-form-item>
				</template>

				<!-- 市编辑 -->
				<template v-if="type == 2">
					<a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input v-decorator="['cityId']" />
					</a-form-item>

					<a-form-item label="省" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-select v-decorator="['provinceId',{ rules: [{ required: true, message: '请选择省！' }] },]"
							placeholder="请选择省" disabled>
							<a-select-option v-for="(item,index) in provinceBoxs" :key="'provinceId' + index"
								:value="item.provinceId">
								{{item.provinceName}}
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="市名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="20" placeholder="请输入市名称"
							v-decorator="['cityName', {rules: [{required: true, message: '请输入市名称！'}]}]" />
					</a-form-item>

					<a-form-item label="行政区划代码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="50" placeholder="请输入行政区划代码" v-decorator="['code']" />
					</a-form-item>

					<a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="6" placeholder="请输入排序" v-decorator="['sort']" />
					</a-form-item>
				</template>

				<!-- 区编辑 -->
				<template v-if="type == 3">
					<a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input v-decorator="['districtId']" />
					</a-form-item>

					<a-form-item label="省" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-select v-decorator="['provinceId',{ rules: [{ required: true, message: '请选择省！' }] },]"
							placeholder="请选择省" @change="changeCityBox" disabled>
							<a-select-option v-for="(item,index) in provinceBoxs" :key="'provinceId' + index"
								:value="item.provinceId">
								{{item.provinceName}}
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="市" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-select v-decorator="['cityId',{ rules: [{ required: true, message: '请选择市！' }] },]"
							placeholder="请选择市" disabled>
							<a-select-option v-for="(item,index) in cityBoxs" :key="'cityId' + index"
								:value="item.cityId">
								{{item.cityName}}
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="区名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="20" placeholder="请输入区名称"
							v-decorator="['districtName', {rules: [{required: true, message: '请输入区名称！'}]}]" />
					</a-form-item>

					<a-form-item label="行政区划代码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="50" placeholder="请输入行政区划代码" v-decorator="['code']" />
					</a-form-item>

					<a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="6" placeholder="请输入排序" v-decorator="['sort']" />
					</a-form-item>
				</template>

			</a-form>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		initEditData,
		changeCityBox,
		updateProvince,
		updateCity,
		updateDistrict
	} from '@/api/modular/system/areaManage'
	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},

				type: '',
				provinceBoxs: [],
				cityBoxs: [],
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this)
			}
		},
		methods: {
			// 初始化方法
			edit(record) {
				this.visible = true;
				this.initEditData(record);
			},

			//获取编辑数据集
			initEditData(record) {
				let param = {
					id: record.id,
					type: record.type
				}
				//表单初始化分支
				initEditData(param).then(res => {
					if (res.success) {
						if (record.type == 1) {
							this.setProvinceData(res.data);
						} else if (record.type == 2) {
							this.setCityDatas(res.data);
						} else if (record.type == 3) {
							this.setDistrictDatas(res.data);
						}
					}
				})
			},

			//省级数据集初始化
			setProvinceData(result) {
				this.type = result.areaType;
				//表单初始化
				setTimeout(() => {
					this.form.setFieldsValue({
						provinceId: result.province.provinceId,
						provinceName: result.province.provinceName,
						code: result.province.code,
						sort: result.province.sort,
						remark: result.province.remark,
					})
				}, 100)
			},

			//市级数据初始化
			setCityDatas(result) {
				this.type = result.areaType;
				this.provinceBoxs = result.provinceList;
				let city = result.city;
				//表单初始化
				setTimeout(() => {
					this.form.setFieldsValue({
						cityId: city.cityId,
						cityName: city.cityName,
						provinceId: city.provinceId,
						code: city.code,
						sort: city.sort
					})
				}, 100)
			},

			//区级数据初始化
			setDistrictDatas(result) {
				this.type = result.areaType;
				this.provinceBoxs = result.provinceList;
				this.cityBoxs = result.cityList;
				let city = result.city;
				let district = result.district;
				//表单初始化
				setTimeout(() => {
					this.form.setFieldsValue({
						districtId: district.districtId,
						districtName: district.districtName,
						cityId: district.cityId,
						provinceId: city.provinceId,
						code: district.code,
						sort: district.sort
					})
				}, 100)
			},

			//联动查询省级下市列表
			changeCityBox(value) {
				let param = {
					id: value
				}
				//查询下级市列表
				changeCityBox(param).then(res => {
					if (res.success) {
						this.cityBoxs = res.data;
						setTimeout(() => {
							this.form.setFieldsValue({
								cityId: res.data[0].cityId,
							})
						}, 100)
					}
				})
			},

			//省修改
			submitProvince(values) {
				//更新
				updateProvince(values).then((res) => {
					//成功
					if (res.success) {
						this.axiosOk(values);
					} else {
						this.$message.error('编辑失败：' + res.message);
						this.confirmLoading = false;
					}
				}).error(() => {
					console.log("error")
					this.confirmLoading = false;
				}).finally(() => {
					this.confirmLoading = false;
				})
			},

			//市修改
			submitCity(values) {
				//更新
				updateCity(values).then((res) => {
					//成功
					if (res.success) {
						this.axiosOk(values);
					} else {
						this.$message.error('编辑失败：' + res.message);
						this.confirmLoading = false;
					}
				}).error(() => {
					console.log("error")
					this.confirmLoading = false;
				}).finally(() => {
					this.confirmLoading = false;
				})
			},

			//区修改
			submitDistrict(values) {
				//更新
				updateDistrict(values).then((res) => {
					//成功
					if (res.success) {
						this.axiosOk(values);
					} else {
						this.$message.error('编辑失败：' + res.message);
						this.confirmLoading = false;
					}
				}).error(() => {
					console.log("error");
					this.confirmLoading = false;
				}).finally(() => {
					this.confirmLoading = false;
				})
			},

			//提交
			handleSubmit() {
				const {
					form: {
						validateFields
					}
				} = this;
				this.confirmLoading = true;
				//表单验证
				validateFields((errors, values) => {
					if (!errors) {

						//提交分支
						if (this.type == 1) {
							this.submitProvince(values);
						} else if (this.type == 2) {
							this.submitCity(values);
						} else if (this.type == 3) {
							this.submitDistrict(values);
						}
					} else {
						this.confirmLoading = false;
					}
				})
				this.confirmLoading = false
			},

			//取消  关闭 
			handleCancel() {
				this.form.resetFields();
				this.visible = false;
				this.type = '';
				this.provinceBoxs = [];
				this.cityBoxs = [];
			},

			axiosOk(values) {
				this.$message.success('编辑成功');
				this.visible = false;
				this.confirmLoading = false;
				this.$emit('ok', values);
				this.form.resetFields();
				this.type = '';
				this.provinceBoxs = [];
				this.cityBoxs = [];
			}
		}
	}
</script>
