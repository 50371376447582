<template>
	<a-modal title="增加角色" :width="900" :visible="visible" :maskClosable="false" :confirmLoading="confirmLoading" okText="保存" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">
				<a-form-item label="角色名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :max-length="200" placeholder="请输入类型名称"
						v-decorator="['name', {rules: [{required: true, message: '请输入角色名称！'}]}]" />
				</a-form-item>
				<a-form-item label="描述" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-textarea :max-length="255" :rows="4" placeholder="请输入描述" v-decorator="['memo']"></a-textarea>
				</a-form-item>
			</a-form>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		sysRoleAdd
	} from '@/api/modular/system/roleManage'
	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this)
			}
		},

		methods: {
			// 初始化方法
			add() {
				this.visible = true
			},

			// 提交方法
			handleSubmit() {
				const {
					form: {
						validateFields
					}
				} = this
				this.confirmLoading = true
				validateFields((errors, values) => {
					if (!errors) {
						sysRoleAdd(values).then((res) => {
							if (res.success) {
								this.$message.success('新增成功')
								this.visible = false
								this.confirmLoading = false
								this.$emit('ok', values)
								this.form.resetFields()
							} else {
								this.$message.error('新增失败：' + res.message)
								this.confirmLoading = false
							}
						}).catch(() => {
							this.confirmLoading = false
						}).finally(() => {
							this.confirmLoading = false
						})
					} else {
						this.confirmLoading = false
					}
				})
			},

			// 取消、关闭弹窗
			handleCancel() {
				this.visible = false;
				this.form.resetFields();
				this.confirmLoading = false;
			},

		}
	}
</script>
