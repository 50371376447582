<template>
	<a-modal title="编辑店铺" :width="900" :visible="visible" :maskClosable="false" :confirmLoading="confirmLoading" okText="修改" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">

				<a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input v-decorator="['shopId']" />
				</a-form-item>

				<a-form-item label="店铺名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :max-length="50" placeholder="店铺名称"
						v-decorator="['shopName', {rules: [{required: true, message: '请选择省名称！'}]}]" />
				</a-form-item>

				<a-form-item label="省名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-select placeholder="请选择省"
						v-decorator="['provinceId', {rules: [{required: true, message: '请选择省名称！'}]}]"
						@change="provincesChange">
						<a-select-option v-for="(item,index) in provinces" :key="'province'+index"
							:value="item.provinceId">
							{{item.provinceName}}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="市名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-select placeholder="请选择市"
						v-decorator="['cityId', {rules: [{required: true, message: '请选择市名称！'}]}]">
						<a-select-option v-for="(item,index) in citys" :key="'city'+index" :value="item.cityId">
							{{item.cityName}}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item label="详细地址" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :max-length="50" placeholder="详细地址"
						v-decorator="['address', {rules: [{required: true, message: '请选择省名称！'}]}]" />
				</a-form-item>

			</a-form>

		</a-spin>
	</a-modal>
</template>

<script>
	import {
		vShopInfoEdit
	} from '@/api/modular/business/vShopInfoManage'
	import {
		initProvinceBoxs,
		changeCityBox,
	} from '@/api/modular/system/areaManage.js'

	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},
				roles: [],
				queryParam: {},
				provinces: [],
				provinceId: '',
				citys: [],
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this)
			}
		},

		methods: {
			// 初始化方法
			edit(record) {
				this.confirmLoading = true
				this.visible = true
				this.provinceId = record.provinceId
				setTimeout(() => {
					this.form.setFieldsValue({
						shopId: record.shopId,
						shopName: record.shopName,
						provinceId: record.provinceId,
						cityId: record.cityId,
						address: record.address
					})
					this.confirmLoading = false
				}, 500)

				this.getProvinces();
				this.getCitys();
			},

			//模板类型搜索
			filterOption(input, option) {
				return (
					option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0);
			},

			//权限省下拉选择
			provincesChange(value) {
				this.provinceId = value
				this.citys = [];
				setTimeout(() => {
					this.form.resetFields(
						['cityId']
					);
					this.confirmLoading = false
				}, 200)
				this.getCitys();

			},

			//获取市数据
			getProvinces() {
				var that = this
				initProvinceBoxs().then(res => {
					if (res.success) {
						that.provinces = res.data;
					}
				})
			},

			getCitys() {
				//联动查询省级下市列表
				let param = {
					id: this.provinceId
				}
				//查询下级市列表
				changeCityBox(param).then(res => {
					if (res.success) {
						this.citys = res.data;
					}
				})
			},

			//ok方法  提交
			handleSubmit() {
				const {
					form: {
						validateFields
					}
				} = this;
				this.confirmLoading = true;
				// 校验
				validateFields((errors, values) => {
					// 校验通过
					if (!errors) {
						// 调用接口
						vShopInfoEdit(values).then((res) => {
							// 返回成功
							if (res.success) {
								this.$message.success('编辑成功');
								this.visible = false;
								this.$emit('ok', values);
								this.form.resetFields();
								// 返回失败
							} else {
								this.$warning({
									okText: '确定',
									title: '提示信息',
									content: "编辑失败，原因：" + res.message
								});
							}
							// 异常
						}).catch((err) => {
							this.$error({
								okText: '确定',
								title: '提示信息',
								content: "编辑失败，原因：" + err.response.data.message
							});
							// finally
						}).finally(() => {
							this.confirmLoading = false;
						})
						// 校验不通过
					} else {
						this.confirmLoading = false;
					}
				})
			},

			// -> 基础功能
			// 取消、关闭Modal
			handleCancel() {
				this.visible = false;
				this.confirmLoading = false;
				this.form.resetFields();
			},
		}
	}
</script>
