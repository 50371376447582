<template>
	<div class="editor-ontainer" :class="{'editor-ontainer-disabled': disabled}">
		<!-- 工具栏 -->
		<Toolbar class="toolbar" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" :style="{'display': disabled ? 'none' : ''}" />
		<!-- 编辑器 -->
		<Editor v-model="data" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" @onChange="onChange"
			class="astrict" />
	</div>
</template>

<script>
	import {
		mixin
	} from '@/utils/mixin';
	import '@wangeditor/editor/dist/css/style.css';
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue';
	import {
		uploadFile
	} from '@/api/modular/system/fileManage';

	export default {
		name: 'XEditor',
		mixins: [mixin],
		components: {
			Editor,
			Toolbar
		},
		model: {
			prop: "value",
			event: "change"
		},
		props: {
			value: {
				type: String,
				default: '',
			},
			mode: {
				type: String,
				default: 'default',
			},
			disabled: {
				type: Boolean,
				default: false,
			}
		},

		// 数据
		data() {
			let _t = this;
			return {
				// 数据
				data: this.value,
				// 富文本
				editor: null,
				// 富文本配置
				// 工具栏配置
				toolbarConfig: {},
				// 编辑器配置
				editorConfig: {
					// 占位符
					placeholder: '请输入内容...',
					// 只读
					readOnly: false,
					// 菜单
					MENU_CONF: {
						// 图片
						uploadImage: {
							// 自定义上传
							customUpload(file, insertFn) {
								_t.upload(file, insertFn)
							}
						},
						// 视频
						uploadVideo: {
							// 自定义上传
							customUpload(file, insertFn) {
								_t.upload(file, insertFn)
							}
						}
					}
				},

				// 表单check
				check: false,
			}
		},

		// 侦听器
		watch: {
			value(val, old) {
				this.data = val;
			},
			disabled(val) {
				// 只读、编辑切换
				val ? this.editor.disable() : this.editor.enable();
			}
		},

		// 销毁
		beforeDestroy() {
			const editor = this.editor;
			if (editor == null) return;
			editor.destroy(); // 组件销毁时，及时销毁编辑器
		},

		// 方法函数
		methods: {
			// ---> 富文本创建完成
			onCreated(editor) {
				// 一定要用 Object.seal() ，否则会报错
				this.editor = Object.seal(editor);
			},

			// ---> 富文本自定义上传
			upload(file, insertFn) {
				// 文件
				let data = new FormData();
				data.append('file', file)

				//上传文件
				uploadFile(data).then((res) => {
					// res区分
					if (res.code == 0) {
						// 插入图片
						insertFn(res.src);

					} else {
						this.$error({
							okText: '确定',
							title: '提示信息',
							content: "上传失败",
						});
					}
				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '错误信息',
						content: "上传失败，原因：" + err.response.data.message,
					});
				}).finally(() => {})
			},

			// ---> change事件
			onChange(editor) {
				// 数据
				this.data = editor.isEmpty() ? '' : editor.getHtml();
				// 首次创建时会默认填充渲染 此时无需发送回执
				if (this.check) {
					this.bundleReceipt();
				} else {
					// 改变状态 创建完成需发送回执
					this.check = !this.check;
				}
			},

			// ---> 清空重置
			clear() {
				// 清空
				this.editor.clear();
				// 重新渲染校验
				this.check = false;
			},

			// ---> 发送回执
			bundleReceipt() {
				// 首次创建渲染后发送回执
				if (this.check) this.$emit("change", this.data)
			}

		}
	}
</script>

<style lang="less">
	.editor-ontainer {
		border: 1px solid #d9d9d9;
		border-radius: 3px;
		margin-top: 10px;
		z-index: 500;

		.w-e-text-container {

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-weight: bold;
			}

			h1 {
				font-size: 2em;
			}
		}

		.toolbar {
			border-bottom: 1px solid #ccc;
		}

		.astrict {
      //max-height: 50vh;
			//min-height: 160px;
			//height: 50vh;
			// height: 70vh;
			overflow-y: hidden
		}

		.w-e-modal {
			.button-container {
				button {
					line-height: 0;
				}
			}
		}
	}

	.editor-ontainer-disabled {
		.w-e-toolbar {
			background-color: #f5f5f5;
		}

		.w-e-text-container {
			cursor: not-allowed;
			// color: rgba(0, 0, 0, 0.25);
			background-color: #f5f5f5;
		}
	}
</style>
