import Vue from 'vue'
import store from '@/store/'
import {
	DEFAULT_COLOR,
	DEFAULT_THEME,
	DEFAULT_HEADER,
} from '@/store/mutation-types'
import config from '@/config/defaultSettings'

export default function Initializer() {
	// console.log(`API_URL: ${process.env.VUE_APP_API_BASE_URL}`)

	store.commit('TOGGLE_THEME', Vue.ls.get(DEFAULT_THEME, config.navTheme))
	store.commit('TOGGLE_COLOR', Vue.ls.get(DEFAULT_COLOR, config.primaryColor))
	store.commit('TOGGLE_HEADER', Vue.ls.get(DEFAULT_HEADER, config.headerColor))

	// last step
}
