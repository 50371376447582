<template>
	<a-row :gutter="24">
		<a-col :md="6" :sm="24">
			<a-card :bordered="false" :loading="treeLoading">
				<div v-if="this.treeData!=''">
					<a-tree :load-data="onLoadData" :tree-data="treeData" @select="onSelect" class="tree"
						:default-selected-keys="defaultSelectedKeys">
						<template slot="custom" slot-scope="item">
							<span class="node-title">{{ item.name }} </span>

							<span class="icon-wrap">
								<a-icon type="edit" @click="operationNode(item, 1)" />
							</span>

							<template v-if="item.type == 1">
								<span class=" icon-wrap">
									<a-icon type="plus" @click="operationNode(item, 0)" />
								</span>
							</template>

							<span class="icon-wrap">
								<a-icon type="delete" @click="deleteArea(item)" />
							</span>
						</template>
					</a-tree>
				</div>
				<div v-else>
					<a-empty :image="simpleImage" />
				</div>
			</a-card>
		</a-col>

		<a-col :md="18" :sm="24">
			<a-card :bordered="false">
				<a-table ref="table" size="default" :columns="columns" :loading="tableLoading" :dataSource="tableData"
					:alert="true" bordered :rowKey="(record) => record.id" :pagination="false"
					:scroll="{ x: true,y: 400  }" :customRow="selectRow"
					:rowClassName="(record)=>{return selectRowId === record.id ? 'selectRow' : ''}">
				</a-table>
			</a-card>
		</a-col>

		<add-form ref="addForm" @ok="handleOk" />
		<edit-form ref="editForm" @ok="handleOk" />
	</a-row>
</template>

<script>
	import {
		initProvinceTree,
		getCityTree,
		getDistrictTree,
		deleteArea
	} from '@/api/modular/system/areaManage'
	import {
		Empty
	} from 'ant-design-vue'
	import addForm from './addForm'
	import editForm from './editForm'

	export default {
		components: {
			addForm,
			editForm
		},

		data() {
			return {
				//行选中Id
				selectRowId: '',
				//加载状态
				treeLoading: true,
				tableLoading: false,

				//数据
				treeData: [],
				tableData: [],

				// 表头
				columns: [{
						title: '名称',
						dataIndex: 'name',
						width: 300
					},
					{
						title: '行政区划码',
						dataIndex: 'code',
						align: 'center'
					},
					{
						title: '排序',
						dataIndex: 'sort',
						width: 150,
						align: 'center'
					}
				],

				defaultSelectedKeys: [],
				//tree无数据展示
				simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,

				//记录表格操作
				operationTableData: {
					//表格当前点击Id
					currTableId: '',
					//表格当前点击级别
					currTableType: '',
					//表格当前点击父级Id
					currTableParentId: '',
				},

				//记录操作节点数据
				operationNodeData: {
					//当前操作节点Id
					nodeId: '',
					//当前操作节点级别
					nodeType: '',
					//当前操作节点Key
					nodeKey: '',
					//当前操作种类 （0:添加   1:编辑）
					operateType: '',
				},
			}
		},
		created() {
			this.initProvinceTree();
		},

		methods: {
			//初始化  获取全部省
			initProvinceTree() {
				initProvinceTree().then((res) => {
					if (res.success) {
						this.treeLoading = false;
						this.treeData = this.toTreeData(res.data);
						//默认选中
						this.defaultSelectedKeys = [this.treeData[0].key];
						//模拟点击事件
						let defaultInfo = {
							node: {
								dataRef: this.treeData[0]
							}
						}
						//模拟点击
						this.onSelect(this.defaultSelectedKeys[0], defaultInfo)
					} else {
						this.$message.error('初始化省市区管理失败：' + res.message);
					}
				})
			},

			//加载下级数据
			onLoadData(treeNode) {
				return new Promise(resolve => {
					//子数据存在  或者  区节点
					if (treeNode.dataRef.children || treeNode.dataRef.type == 3) {
						resolve();
						return;
					}

					//获取省级下市数据
					if (treeNode.dataRef.type == 1) {
						//查询下级市列表
						getCityTree({
							id: treeNode.dataRef.id
						}).then(res => {
							if (res.success) {
								//转化结构化
								let childrenData = this.toTreeData(res.data);

								//填充数据
								treeNode.dataRef.children = childrenData;
								this.treeData = [...this.treeData];
								//请求结束
								resolve();
							} else {
								this.$message.error('获取市数据失败：' + res.message);
								resolve();
								return;
							}
						})

						//获取市下级数据
					} else if (treeNode.dataRef.type == 2) {
						//查询下级区列表
						getDistrictTree({
							id: treeNode.dataRef.id
						}).then(res => {
							if (res.success) {
								//转化结构化
								let childrenData = this.toTreeData(res.data);

								//填充数据
								treeNode.dataRef.children = childrenData;
								this.treeData = [...this.treeData];
								//请求结束
								resolve();
							} else {
								this.$message.error('获取区数据失败：' + res.message);
								resolve();
								return;
							}
						})
					}
				});
			},

			//数据结构化
			toTreeData(d) {
				d.forEach((x) => {
					//key
					if (x.type == 1) {
						x.key = "pro_" + x.id;
					} else if (x.type == 2) {
						x.key = "city_" + x.id;
						// 此项目只到区
						x.isLeaf = true;
					} else if (x.type == 3) {
						x.key = "dis_" + x.id;
						// 叶子节点
						x.isLeaf = true;
					}

					//title
					x.scopedSlots = {
						title: "custom"
					}
				});
				return d;
			},

			onSelect(selectedKeys, info) {
				this.tableLoading = true;
				let o = info.node.dataRef;

				//修改表格记录
				this.operationTableData.currTableType = o.type;
				this.operationTableData.currTableId = selectedKeys;
				//修改表格记录
				if (o.type != 1) {
					this.operationTableData.currTableParentId = o.parentId;
				} else {
					this.operationTableData.currTableParentId = '';
				}

				//区数据无表格数据
				if (o.type == 3) {
					this.tableData = [o];
					this.tableLoading = false;

				} else {
					//如果当前已有数据
					if ('children' in o) {
						this.tableData = o.children;
						this.tableLoading = false;

					} else {

						//获取省级下市数据
						if (o.type == 1) {
							//查询下级市列表
							getCityTree({
								id: o.id
							}).then(res => {
								if (res.success) {
									//转化结构化
									let childrenData = this.toTreeData(res.data);

									//树填充数据
									o.children = childrenData;
									this.treeData = [...this.treeData];

									//表格数据
									this.tableData = childrenData;
									this.tableLoading = false;
								} else {
									this.$message.error('获取市数据失败：' + res.message);
									this.tableLoading = false;
								}
							})

							//获取市下级数据
						} else if (o.type == 2) {
							this.tableData = [o];
							this.tableLoading = false;

							// //查询下级区列表
							// getDistrictTree({
							// 	id: o.id
							// }).then(res => {
							// 	if (res.success) {
							// 		//转化结构化
							// 		let childrenData = this.toTreeData(res.data);

							// 		//树填充数据
							// 		o.children = childrenData;
							// 		this.treeData = [...this.treeData];
							// 		//表格数据
							// 		this.tableData = childrenData;
							// 		this.tableLoading = false;
							// 	} else {
							// 		this.$message.error('获取区数据失败：' + res.message);
							// 		this.tableLoading = false;
							// 	}
							// })
						}
					}
				}
			},

			handleOk(e) {
				//添加
				if (this.operationNodeData.operateType == 0) {
					//清空数据 重新加载
					//加载状态
					this.treeLoading = true;
					this.tableLoading = false;
					this.treeData = [];
					this.tableData = [];
					this.defaultSelectedKeys = [];

					//重新加载
					this.initProvinceTree();

					//编辑
				} else {
					//处理编辑数据
					let record = this.disposeData(e, this.operationNodeData.nodeType, this.operationNodeData.nodeKey);
					this.treeDataOperate(this.treeData, this.operationNodeData.nodeKey, record);

					//模拟点击事件
					let defaultInfo = {
						node: {
							dataRef: record,
						}
					}
					//模拟点击
					this.onSelect(record.key, defaultInfo)
				}
			},

			//处理保存数据
			disposeData(record, nodeType, nodeKey) {
				//返回
				let result = {
					key: nodeKey,
					code: record.code,
					sort: record.sort,
					scopedSlots: {
						title: "custom"
					}
				};

				//省
				if (nodeType == 1) {
					result.id = record.provinceId;
					result.name = record.provinceName;
					result.remark = record.remark;
					result.type = "1";
					result.typeName = "省";

					//市
				} else if (nodeType == 2) {
					result.id = record.cityId;
					result.name = record.cityName;
					result.parentId = record.provinceId;
					result.type = "2";
					result.typeName = "市";

					//区
				} else if (nodeType == 3) {
					result.id = record.districtId;
					result.name = record.districtName;
					result.parentId = record.cityId;
					result.type = "3";
					result.typeName = "区";
					result.isLeaf = true;
				}

				return result;
			},

			//操作
			operationNode(item, type) {
				//修改操作信息
				this.operationNodeData.nodeType = item.type;
				this.operationNodeData.nodeId = item.id;
				this.operationNodeData.nodeKey = item.key;
				this.operationNodeData.operateType = type;

				//初始化对应操作
				if (type == 0) {
					this.$refs.addForm.add(item);
				} else {
					this.$refs.editForm.edit(item)
				}
			},

			// 根据id属性从数组（树结构）中匹配元素，执行action操作
			treeDataOperate(treeSouce, key, record) {
				if (!treeSouce || !treeSouce.length) {
					return;
				}
				for (let i = 0; i < treeSouce.length; i++) {
					if (treeSouce[i].key === key) {
						treeSouce[i] = record;
						this.treeData = [...this.treeData];
						this.$forceUpdate();
						break;
					}
					this.treeDataOperate(treeSouce[i].children, key, record);
				}
			},

			//删除
			deleteArea(record) {
				this.$confirm({
					title: '删除确认',
					content: '您是否要删除吗？',
					okText: '确定',
					cancelText: '取消',
					onOk: () => {
						let param = {
							id: record.id,
							type: record.type,
						}
						//删除
						deleteArea(param).then((res) => {
							//成功
							if (res.success) {
								this.$message.success(res.data);
								//重新加载
								this.initProvinceTree();
							} else {
								this.$message.error(res.data);
							}
						}).catch((err) => {
							this.$message.error('删除错误：' + err.message);
						})
					},
					onCancel() {}
				})
			},

			selectRow(record) {
				return {
					props: {},
					on: {
						click: () => {
							this.selectRowId = record.id
						},
					}
				}
			},
		}
	}
</script>

<style scoped lang="less">
	.ant-table-thead>tr>th {
		text-align: center !important;
	}

	.icon-wrap {
		margin-left: 1rem;
	}

	.ant-table-row-expand-icon {
		display: none !important;
	}

	.tree {
		height: calc(100vh - 100px);
		display: inline-block;
		overflow: auto;
	}
</style>
