import {
	axios
} from '@/utils/request'

/**
 * 初始化相关参数
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function initRelatedParam(data) {
	return axios({
		url: '/homeEchart/initRelatedParam',
		method: 'post',
		data: data
	})
}

/**
 * 小程序浏览量图表数据
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function appletVolumes(data) {
	return axios({
		url: '/homeEchart/appletVolumes',
		method: 'post',
		data: data
	})
}

/**
 * 活动浏览量图表数据
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function activityVolumes(data) {
	return axios({
		url: '/homeEchart/activityVolumes',
		method: 'post',
		data: data
	})
}

/**
 * 城市图表数据
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function cityVolumes(data) {
	return axios({
		url: '/homeEchart/cityVolumes',
		method: 'post',
		data: data
	})
}


/**
 * 作品图表数据
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function enrollVolumes(data) {
	return axios({
		url: '/homeEchart/enrollVolumes',
		method: 'post',
		data: data
	})
}

/**
 * 排名数据
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function rankVolumes(data) {
	return axios({
		url: '/homeEchart/rankVolumes',
		method: 'post',
		data: data
	})
}

