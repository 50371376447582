<template>
	<a-modal title="增加用户" :width="900" :visible="visible" :maskClosable="false" :confirmLoading="confirmLoading" okText="保存" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">

				<a-form-item label="登录账号" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入登录账号" :max-length="50" autocomplete="off"
						v-decorator="['account', {rules: [{required: true, message: '请输入登录账号！'}]}]" />
				</a-form-item>

				<a-form-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入姓名" :max-length="20" autocomplete="off"
						v-decorator="['userName', {rules: [{required: true, message: '请输入姓名！'}]}]" />
				</a-form-item>

				<a-form-item label="性别" :labelCol="labelCol" :wrapperCol="wrapperCol">
					<a-radio-group
						v-decorator="['sex',{rules: [{ required: true, message: '请选择性别！' }] ,initialValue: 1 }]">
						<a-radio :value="1">男</a-radio>
						<a-radio :value="2">女</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-form-item label="密码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input-password type="password" autocomplete="off" placeholder="请输入6位或6位以上的半角英文和数字"
						@blur="passwordBlur" style="width: 100%;" :max-length="50"
						v-decorator="['password', {rules: [{required: true, message: '请输入密码！'}]}]" />
				</a-form-item>

				<a-form-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入手机号" autocomplete="off" style="width: 100%;" @blur="accountBlur"
						:max-length="11" v-decorator="['phone']" />
				</a-form-item>

				<a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入邮箱" autocomplete="off" style="width: 100%;" @blur="emailBlur"
						:max-length="50" v-decorator="['email']" />
				</a-form-item>

				<a-form-item label="用户角色" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-select placeholder="请选择用户角色"
						v-decorator="['roleId', {rules: [{required: true, message: '请选择用户角色！'}]}]">
						<a-select-option v-for="(item,index) in roles" :key="index" :value="item.roleId">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

			</a-form>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		sysAccountRole,
		sysAccountAdd,
	} from '@/api/modular/system/accountManage'

	export default {
		data() {
			return {
				form: this.$form.createForm(this),
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},

				//登录用户信息
				user: {
					userId: localStorage.getItem("userId"),
				},

				roles: [],
				visible: false,
				confirmLoading: false,
			}
		},

		created() {
			this.addRold();
		},

		methods: {
			// 初始化方法
			add() {
				this.visible = true
			},

			addRold() {
				sysAccountRole().then((res) => {
					if (!res.success) {
						return
					}
					this.roles = res.data.roles;
				})
			},

			//手机号码格式
			accountBlur(e) {
				const val = e.target.value
				if (!val) {
					return;
				}
				let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
				if (!myreg.test(val)) {
					const arr = [{
						message: '手机号码格式不正确!',
						field: 'phone'
					}]
					this.form.setFields({
						phone: {
							value: '',
							errors: arr
						}
					})
				}
			},

			//邮箱格式
			emailBlur(e) {
				const val = e.target.value
				if (!val) {
					return;
				}
				let reg = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
				if (!reg.test(val)) {
					const arr = [{
						message: '邮箱格式不正确!',
						field: 'email'
					}]
					this.form.setFields({
						email: {
							value: '',
							errors: arr
						}
					})
				}
			},

			//密码格式
			passwordBlur(e) {
				const val = e.target.value
				let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/;
				if (!reg.test(val)) {
					const arr = [{
						message: '密码格式不正确!',
						field: 'password'
					}]
					this.form.setFields({
						password: {
							value: '',
							errors: arr
						}
					})
				}
			},

			handleSubmit() {
				const {
					form: {
						validateFields
					}
				} = this
				this.confirmLoading = true
				validateFields((errors, values) => {
					values.level = this.level
					if (!errors) {
						sysAccountAdd(values).then((res) => {
							if (res.success) {
								this.$message.success('新增成功')
								this.visible = false
								this.confirmLoading = false
								this.$emit('ok', values)
								this.form.resetFields()
							} else {
								this.$message.error('新增失败：' + res.other)
								this.confirmLoading = false
							}
						}).error(() => {
							console.log("error")
							this.confirmLoading = false
						}).finally(() => {
							console.log("finally")
							this.confirmLoading = false
						})
					} else {
						console.log("error")
						this.confirmLoading = false
					}
				})
				this.confirmLoading = false
			},
			handleCancel() {
				this.form.resetFields()
				this.visible = false
			}
		}
	}
</script>
