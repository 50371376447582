<template>
	<a-card :bordered="false">

		<transition name="trans">
			<div v-show="!visible">
				<modal ref="modal" @ok="handleOk" @no="visabledModal" />
			</div>
		</transition>

		<transition name="trans">
			<div v-show="visible">
				<div class="table-page-search-wrapper">
					<a-form layout="inline">
						<a-row :gutter="48">
							<a-col :sm="24" :md="8">
								<a-form-item label="标题">
									<a-input v-model="queryParam.title" placeholder="请输入标题" :max-length="50"
										allow-clear />
								</a-form-item>
							</a-col>

							<a-col :sm="24" :md="8">
								<a-form-item label="状态">
									<a-select v-model="queryParam.state" allow-clear placeholder="请选择状态"
										default-value="">
										<a-select-option v-for="(item) in relatedParam.activityStates"
											:key="item.dictCode" :value="item.dictCode">
											{{item.dictValue}}
										</a-select-option>
									</a-select>
								</a-form-item>
							</a-col>
						</a-row>
					</a-form>
				</div>

				<div class="table-operator">
					<a-row :gutter="16">
						<a-col :sm="24" :md="24" style="text-align: right;">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="()=> $refs.table.refresh(true)">查询</a-button>
								<a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
							</span>
						</a-col>
					</a-row>
				</div>

				<s-table ref="table" bordered size="default" :columns="columns" :data="loadData" :alert="false"
					rowKey="activityId" :scroll="scroll" :customRow="selectRow" :rowClassName="rowClassName"
					:style="operaHigh">

					<!-- 预览图 -->
					<template slot="previewUrl" slot-scope="text">
						<a :href="previewSrc(text)" target="_blank" v-if="text">
							<img :src="previewSrc(text)" alt="" style="width: 50px;height: 30px;" />
						</a>
					</template>

					<!-- 文字提示 -->
					<template slot="titleTooltip" slot-scope="text">
						<a-tooltip placement="topLeft" :mouseEnterDelay="0.15" overlayClassName="general_tooltip">
							<template slot="title">
								{{ text }}
							</template>
							<div class="multi-ellipsis">{{ text }}</div>
						</a-tooltip>
					</template>

					<!-- 操作列 -->
					<template slot="action" slot-scope="text,record">
						<div style="text-align: center;">
							<template v-if="record.state != 6">
								<a @click="visabledModal(record, false)">设定</a>
								<a-divider type="vertical" />
							</template>
							<a @click="visabledModal(record, true)">查看</a>
						</div>
					</template>
				</s-table>
			</div>
		</transition>

	</a-card>
</template>

<script>
	import {
		STable
	} from '@/components'
	import {
		vActivityInfoPage,
	} from '@/api/modular/business/activityManage'
	import {
		initRelatedParam,
	} from '@/api/modular/business/rankManage'

	import modal from './modal'

	export default {
		components: {
			STable,
			modal,
		},

		data() {
			return {
				// 主页可视化
				visible: true,
				// 高级搜索  false: 收取   true: 展开
				advanced: false,
				//行选中key
				selectRowKey: '',
				//关联参数
				relatedParam: {},
				// 查询参数
				queryParam: {},
				// 检索参数区行数
				queryRows: {
					count: 2,
					current: 1
				},
				// 时间格式化
				dateFormat: 'YYYY-MM-DD',
				//表格scroll
				scroll: {
					x: true,
					y: 397
				},
				// 表头
				columns: [{
						title: '序号',
						dataIndex: 'no',
						width: 50,
						align: 'center'
					},
					{
						dataIndex: 'title',
						title: '标题',
						width: 230,
						scopedSlots: {
							customRender: 'titleTooltip'
						}
					},
					{
						dataIndex: 'publishDate',
						title: '获奖公布日',
						align: 'center',
						width: 165,
					},
					{
						dataIndex: 'stateName',
						title: '状态',
						align: 'center',
						width: 110,
					},
					{
						title: '操作',
						width: 110,
						align: 'center',
						dataIndex: 'action',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					// 接口
					return vActivityInfoPage(Object.assign(parameter, this.queryParam)).then((res) => {
						// 填充序号
						res.data.rows.forEach((item, index) => {
							item.no = (res.data.pageNo - 1) * res.data.pageSize + index + 1;
              //作品公布日
              let publish = []
              publish = item.publishDate.split(" ")
              item.publishDate = publish[0].substring(0, publish[0].lastIndexOf("-"));
						});
						// 返回page
						return res.data;
					})
				},
				// 操作者数据
				operator: {
					userId: localStorage.getItem("userId"),
				},

			}
		},

		// created钩子函数
		created() {
			this.initRelatedParam();
		},

		// 计算属性
		computed: {
			// 预览图全路径
			previewSrc() {
				return (val) => {
					return this.relatedParam.imgHttpPath + val;
				}
			},

			// 表格头部功能区高度
			operaHigh() {
				return {
					"--operaHigh": (this.queryRows.current * 56 + 268) + 'px',
				}
			},
		},

		// 方法函数
		methods: {

			// --- > 功能业务
			// 初始化关联参数
			initRelatedParam() {
				initRelatedParam({}).then((res) => {
					// 成功
					if (res.success) {
						// 关联参数取得
						this.relatedParam = res.data;
						// // 初始化子组件关联参数
						this.$refs.modal.initRelatedParam(res.data);
					} else {
						this.$error({
							okText: '确定',
							title: '提示信息',
							content: "请求关联参数失败，原因：" + res.message
						});
					}
				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '提示信息',
						content: "请求关联参数失败，原因：" + err.response.data.message,
					});
				})
			},

			// -> 基础功能
			// 切换page 与 组件
			visabledModal(record, see) {
				// 可见性改变
				this.visible = !this.visible;
				// 可见性为false时   展开组件
				if (!this.visible) {
					setTimeout(() => {
						this.$refs.modal.operate(record, see);
					}, 100)
				}
			},

			// 高级搜索切换
			toggleAdvanced() {
				this.advanced = !this.advanced;
				this.queryRows.current = this.advanced ? this.queryRows.count : 1;
			},

			// 子组件ok回执
			handleOk() {
				this.visible = !this.visible;
				this.$refs.table.refresh();
			},

			// 表格行变色 selectRowKey
			selectRow(record) {
				return {
					props: {},
					on: {
						click: () => {
							this.selectRowKey = record.activityId;
						},
					}
				}
			},

			// 表格行变色 class
			rowClassName(record) {
				return record.activityId === this.selectRowKey ? 'selectRow' : '';
			},

		}
	}
</script>

<style scoped lang="less">

</style>
