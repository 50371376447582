import {
	axios
} from '@/utils/request'

/**
 * 初始化相关参数
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function initRelatedParam(data) {
	return axios({
		url: '/vWechatUser/initRelatedParam',
		method: 'post',
		data: data
	})
}

/**
 * 分页查询的数据
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vWechatUserPage(data) {
	return axios({
		url: '/vWechatUser/page',
		method: 'post',
		data: data
	})
}

/**
 * 添加
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vWechatUserAdd(data) {
	return axios({
		url: '/vWechatUser/save_data',
		method: 'post',
		data: data
	})
}

/**
 * 编辑
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vWechatUserEdit(data) {
	return axios({
		url: '/vWechatUser/edit_data',
		method: 'post',
		data: data
	})
}

/**
 * 删除
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vWechatUserDelete(data) {
	return axios({
		url: '/vWechatUser/delete',
		method: 'post',
		data: data
	})
}
