<template>
	<a-modal title="编辑用户" :width="900" :visible="visible" :maskClosable="false" :confirmLoading="confirmLoading" okText="修改" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">

				<a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol">
					<a-input v-decorator="['userId']" />
				</a-form-item>

				<a-form-item label="登录账号" :labelCol="labelCol" :wrapperCol="wrapperCol">
					<a-input :disabled="true" :max-length="50" placeholder="请输入登录账号" v-decorator="['account']" />
				</a-form-item>

				<a-form-item label="用户角色" :labelCol="labelCol" :wrapperCol="wrapperCol">
					<a-input :disabled="true" :max-length="50" v-decorator="['roleName']" />
				</a-form-item>
				<a-form-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
					<a-input :max-length="20" placeholder="请输入姓名"
						v-decorator="['userName', {rules: [{required: true, message: '请输入姓名！'}]}]" />
				</a-form-item>

				<a-form-item label="性别" :labelCol="labelCol" :wrapperCol="wrapperCol">
					<a-radio-group v-decorator="['sex',{rules: [{ required: true, message: '请选择性别！' }]}]"
						style="width: 100%;">
						<!-- :defaultValue="sex" -->
						<a-radio :value="1">男</a-radio>
						<a-radio :value="2">女</a-radio>
					</a-radio-group>

				</a-form-item>

				<a-form-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol">
					<a-input :max-length="11" placeholder="请输入手机号" style="width: 100%;" v-decorator="['phone']" />
				</a-form-item>

				<a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol">
					<a-input :max-length="50" placeholder="请输入邮箱" style="width: 100%;" v-decorator="['email']" />
				</a-form-item>
			</a-form>

		</a-spin>
	</a-modal>
</template>

<script>
	import {
		sysAccountEdit
	} from '@/api/modular/system/accountManage'

	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},
				roles: [],
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this)
			}
		},

		methods: {
			// 初始化方法
			edit(record) {
				this.confirmLoading = true
				this.visible = true
				setTimeout(() => {
					this.form.setFieldsValue({
						userId: record.userId,
						roleId: record.roleId,
						account: record.account,
						userName: record.userName,
						sex: record.sex,
						phone: record.phone,
						email: record.email,
						roleName: record.roleName
					})
					this.confirmLoading = false
				}, 500)
			},

			//模板类型搜索
			filterOption(input, option) {
				return (
					option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0);
			},

			handleSubmit() {
				var that = this
				const {
					form: {
						validateFields
					}
				} = that;
				that.confirmLoading = true
				validateFields((errors, values) => {
					if (!errors) {

						if (values.phone) {
							let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
							if (!myreg.test(values.phone)) {
								const arr = [{
									message: '手机号码格式不正确!',
									field: 'phone'
								}]
								that.form.setFields({
									phone: {
										value: '',
										errors: arr
									}
								})
								that.confirmLoading = false
								return;
							}
						}

						if (values.email) {
							let reg = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
							if (!reg.test(values.email)) {
								const arr = [{
									message: '邮箱格式不正确!',
									field: 'email'
								}]
								that.form.setFields({
									email: {
										value: '',
										errors: arr
									}
								})
								that.confirmLoading = false
								return;
							}
						}

						sysAccountEdit(values).then((res) => {
							if (res.success) {
								that.$message.success('编辑成功')
								that.visible = false
								that.confirmLoading = false
								that.$emit('ok', values)
								that.form.resetFields()
							} else {
								that.$message.error('编辑失败：' + res.message)
								that.confirmLoading = false
							}
						}).finally(() => {
							that.confirmLoading = false
						})
					} else {
						that.confirmLoading = false
					}
				})
				this.confirmLoading = false;
			},

			// 取消、关闭
			handleCancel() {
				this.visible = false
				this.form.resetFields()
			}
		}
	}
</script>
