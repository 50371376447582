<template>
  <a-modal title="投票结果明细" width="70%" :visible="visible" :confirmLoading="confirmLoading" :maskClosable="false"
    okText="确定" @ok="handleSubmit" cancelText="关闭" @cancel="handleCancel" :okButtonProps="{style:{display:'none'}}">
    <a-card :bordered="false">
      <div class="table-operator">
        <a-row :gutter="16">
          <a-col :sm="24" :md="27">
            <span class="table-page-search-submitButtons" style="font-size: 16px;font-weight: 700;">
              <span style="color: red;">*</span>共{{votesNum}}票
            </span>
          </a-col>
        </a-row>
      </div>

      <s-table bordered ref="table" size="default" :columns="columns" :data="loadData" :alert="false" :scroll="scroll"
        rowKey="userId" :customRow="selectRow" :rowClassName="rowClassName" :style="operaHigh">
      </s-table>

    </a-card>
  </a-modal>
</template>

<script>
  import {
    STable
  } from '@/components'
  import {
    vVoteInfoPage,
  } from '@/api/modular/business/vVoteInfoManage'

  export default {
    components: {
      STable,
    },

    data() {
      return {
        visible: false,
        confirmLoading: false,
        // 高级搜索  false: 收取   true: 展开
        advanced: false,
        //行选中key
        selectRowKey: '',
        //关联参数
        relatedParam: {},
        // 查询参数
        queryParam: {},
        // 检索参数区行数
        queryRows: {
          count: 2,
          current: 1
        },
        // 票数
        votesNum: 0,
        //表格scroll
        scroll: {
          x: true,
          y: 397
        },
        // 表头
        columns: [{
            title: '序号',
            dataIndex: 'no',
            width: 95,
            align: 'center'
          },
          {
            title: '投票人昵称',
            dataIndex: 'nickName',
            align: 'center',
            width: 150,
          },
          {
            title: '省',
            dataIndex: 'province',
            align: 'center',
            width: 150,
          },
          {
            title: '市',
            dataIndex: 'city',
            align: 'center',
            width: 150,
          },
          {
            title: '投票日期',
            dataIndex: 'createdDatetime',
            align: 'center',
            width: 150,
          }, {
            title: '投票数',
            dataIndex: 'num',
            align: 'center',
            width: 150,
          }
        ],
        // 加载数据方法 必须为 Promise 对象
        loadData: parameter => {
          return vVoteInfoPage(Object.assign(parameter, this.queryParam)).then((res) => {
            res.data.rows.forEach((item, index) => {
              item.no = (res.data.pageNo - 1) * res.data.pageSize + index + 1;
              //作品公布日
              let publish = []
              publish = item.createdDatetime.split(" ")
              item.createdDatetime = publish[0]
            });
            return res.data;
          })
        },
        // 操作者数据
        operator: {
          // userId: localStorage.getItem("userId"),
        },

        // item
        // 表格选中数据
        selectedRowKeys: [],
        selectedRows: [],
      }
    },

    // created钩子函数
    created() {
      // this.initRelatedParam();
    },

    // 计算属性
    computed: {
      // 表格头部功能区高度
      operaHigh() {
        return {
          "--operaHigh": (this.queryRows.current * 56 + 468) + 'px',
        }
      },
    },

    // 方法函数
    methods: {
      // 初始化
      vote(record) {
        this.votesNum = record.votesNum
        this.queryParam.enrollId = record.enrollId
        this.visible = true;
        this.$refs.table.refresh(true);
      },

      //ok方法  提交
      handleSubmit() {
        let res = {
          keys: this.selectedRowKeys,
          rows: this.selectedRows,
        }
        this.$emit('ok', res);
        // 关闭
        this.handleCancel();
      },

      // -> 基础功能
      //表格复选框
      onSelectChange(selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys;
        this.selectedRows = selectedRows;
      },

      // 取消、关闭Modal
      handleCancel() {
        this.visible = false;
        this.selectedRowKeys = [];
        this.selectedRows = [];
        this.votesNum = 0
        this.queryParam.enrollId = ''
      },

      // 表格行变色 selectRowKey
      selectRow(record) {
        return {
          props: {},
          on: {
            click: () => {
              this.selectRowKey = record.supId
            },
          }
        }
      },

      // 表格行变色 class
      rowClassName(record) {
        return record.supId === this.selectRowKey ? 'selectRow' : '';
      }
    }
  }
</script>

<style scoped lang="less">

</style>
