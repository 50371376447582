<template>
	<a-card :bordered="false">

		<transition name="trans">
			<div v-show="!visible">
				<modal ref="modal" @ok="handleOk" @no="visabledModal" />
			</div>
		</transition>

		<transition name="trans">
			<div v-show="visible">
				<div class="table-page-search-wrapper">
					<a-form layout="inline">
						<a-row :gutter="48">
							<a-col :sm="24" :md="8">
								<a-form-item label="用户姓名">
									<a-input v-model="queryParam.userName" placeholder="请输入用户姓名" tabindex="2"
										maxlength="50" allow-clear />
								</a-form-item>
							</a-col>
							<a-col :sm="24" :md="8">
								<a-form-item label="昵称">
									<a-input v-model="queryParam.nickName" placeholder="请输入昵称" tabindex="3"
										maxlength="50" allow-clear />
								</a-form-item>
							</a-col>
<!-- 							<a-col :sm="24" :md="8">
								<a-form-item label="手机号码">
									<a-input v-model="queryParam.phone" placeholder="请输入手机号码" tabindex="5"
										maxlength="20" allow-clear />
								</a-form-item>
							</a-col> -->
						</a-row>
					</a-form>
				</div>

				<div class="table-operator">
					<a-row :gutter="16">
						<a-col :sm="24" :md="24" style="text-align: right;">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
								<a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
							</span>
						</a-col>
					</a-row>
				</div>

				<s-table ref="table" bordered size="default" :columns="columns" :data="loadData" :alert="false"
					rowKey="openId" :scroll="scroll" :customRow="selectRow" :rowClassName="rowClassName"
					:style="operaHigh">

					<!-- 头像 -->
					<span slot="headImage" slot-scope="text">
						<a :href="text" target="_blank" v-if="text">
							<img :src="text" alt="" style="width: 50px;height: 50px;" />
						</a>
					</span>

					<!-- 头像 -->
					<span slot="sex" slot-scope="text">
						<span v-if="text">
							{{ sexName(text) }}
						</span>
					</span>

					<!-- 操作列 -->
					<span slot="action" slot-scope="text, record">
						<!-- <a @click="visabledModal('operate', record, false)">编辑</a>
						<a-divider type="vertical" /> -->
						<a @click="visabledModal('operate', record, true)">查看</a>
					</span>
				</s-table>
			</div>
		</transition>

	</a-card>
</template>

<script>
	import {
		STable
	} from '@/components'
	import {
		initRelatedParam,
		vWechatUserPage,
		vWechatUserDelete,
	} from '@/api/modular/business/wechatUserManage'
	import modal from './modal'

	export default {
		components: {
			STable,
			modal,
		},

		data() {
			return {
				// 主页可视化
				visible: true,
				// 高级搜索  false: 收取   true: 展开
				advanced: false,
				//行选中key
				selectRowKey: '',
				//关联参数
				relatedParam: {},
				// 查询参数
				queryParam: {},
				// 检索参数区行数
				queryRows: {
					count: 2,
					current: 1
				},
				//表格scroll
				scroll: {
					x: true,
					y: 397
				},
				// 表头
				columns: [{
						title: '序号',
						dataIndex: 'no',
						width: 95,
						align: 'center'
					},
					{
						dataIndex: 'headImage',
						title: '头像',
						align: 'center',
						width: 95,
						scopedSlots: {
							customRender: 'headImage'
						}
					},
					{
						dataIndex: 'userName',
						title: '用户姓名',
						width: 200,
					},
					{
						dataIndex: 'nickName',
						title: '昵称',
						width: 200,
						scopedSlots: {
							customRender: 'titleTooltip'
						}
					},
					{
						dataIndex: 'sex',
						title: '性别',
						align: 'center',
						width: 70,
						scopedSlots: {
							customRender: 'sex'
						}
					},
					// {
					// 	dataIndex: 'phone',
					// 	title: '手机号码',
					// 	align: 'center',
					// 	width: 150,
					// },

					{
						dataIndex: 'email',
						title: '邮箱',
						width: 150,
					},
					{
						dataIndex: 'createdDatetime',
						title: '注册时间',
						align: 'center',
						width: 165,
					},
					{
						title: '操作',
						width: 110,
						align: 'center',
						dataIndex: 'action',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					// 接口
					return vWechatUserPage(Object.assign(parameter, this.queryParam)).then((res) => {
						// 填充序号
						res.data.rows.forEach((item, index) => {
							item.no = (res.data.pageNo - 1) * res.data.pageSize + index + 1;
						});

						// 返回page
						return res.data;
					})
				},
				// 操作者数据
				operator: {
					userId: localStorage.getItem("userId"),
				},

			}
		},

		// created钩子函数
		created() {
			this.initRelatedParam();
		},

		// 计算属性
		computed: {
			// 表格头部功能区高度
			operaHigh() {
				return {
					"--operaHigh": (this.queryRows.current * 56 + 268) + 'px',
				}
			},

			// 表格头部功能区高度
			sexName() {
				return (val) => {
					let sexDict = this.relatedParam.sexs.find((item) => {
						return item.dictCode = val;
					});
					return sexDict ? sexDict.dictValue : '';
				}
			},
		},

		// 方法函数
		methods: {

			// -> 功能业务
			// 初始化关联参数
			initRelatedParam() {
				initRelatedParam({}).then((res) => {
					// 成功
					if (res.success) {
						// 关联参数取得
						this.relatedParam = res.data;
						// 初始化子组件关联参数
						this.$refs.modal.initRelatedParam(res.data);
					} else {
						this.$error({
							okText: '确定',
							title: '提示信息',
							content: "请求关联参数失败，原因：" + res.message
						});
					}
				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '提示信息',
						content: "请求关联参数失败，原因：" + err.response.data.message,
					});
				})
			},

			// 删除
			delsysNotice(record) {
				// 询问框
				this.$confirm({
					title: '删除确认',
					content: '您是否要删除吗？',
					okText: '确定',
					cancelText: '取消',
					onOk: () => {
						this.$message.success('删除成功');

						// 调用接口
						vWechatUserDelete(record).then((res) => {
							// 返回成功
							if (res.success) {
								this.$message.success('删除成功');
								this.$refs.table.refresh();
								// 返回失败
							} else {
								//TODO + res.message
								this.$warning({
									okText: '确定',
									title: '提示信息',
									content: "删除失败，原因：" + res.message
								});
							}
							// 异常
						}).catch((err) => {
							this.$error({
								okText: '确定',
								title: '提示信息',
								content: "删除失败，原因：" + err.response.data.message
							});
						})
					},
					// 取消按钮
					onCancel() {}
				})
			},



			// -> 基础功能
			// 切换page 与 组件
			visabledModal(operation, record, see) {
				// 可见性改变
				this.visible = !this.visible;
				// 可见性为false时   展开组件
				if (!this.visible && operation) {
					setTimeout(() => {
						if (operation == 'add') {
							this.$refs.modal.add();
						} else {
							this.$refs.modal.operate(record, see);
						}
					}, 100)
				}
			},

			// 高级搜索切换
			toggleAdvanced() {
				this.advanced = !this.advanced;
				this.queryRows.current = this.advanced ? this.queryRows.count : 1;
			},

			// 子组件ok回执
			handleOk() {
				this.visible = !this.visible;
				this.$refs.table.refresh();
			},

			// 表格行变色 selectRowKey
			selectRow(record) {
				return {
					props: {},
					on: {
						click: () => {
							this.selectRowKey = record.openId
						},
					}
				}
			},

			// 表格行变色 class
			rowClassName(record) {
				return record.openId === this.selectRowKey ? 'selectRow' : '';
			},

		}
	}
</script>

<style scoped lang="less">

</style>
