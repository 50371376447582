import axios from 'axios'
import Vue from 'vue'
import {
	ACCESS_TOKEN
} from '@/store/mutation-types'
import {
	message,
	Modal,
	notification
} from 'ant-design-vue'
import store from '@/store'

// 创建 axios 实例
const service = axios.create({
	baseURL: '/vote', // api base_url
	timeout: 180000 // 请求超时时间
});

// 添加请求拦截，在请求头中加token
service.interceptors.request.use(
	config => {
		if (Vue.ls.get(ACCESS_TOKEN)) {
			config.headers.Authorization = Vue.ls.get(ACCESS_TOKEN)
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
);


const err = (error) => {
	if (error.response) {
		const data = error.response.data
		const token = Vue.ls.get(ACCESS_TOKEN)

		if (error.response.status === 403) {
			console.log('服务器403啦，要重新登录！');
			notification.error({
				message: 'Forbidden',
				description: data.message
			})
		}
		if (error.response.status === 500) {
			if (data.message.length > 0) {
				message.error(data.message)
			}
		}
		if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
			notification.error({
				message: 'Unauthorized',
				description: 'Authorization verification failed'
			});
			if (token) {
				store.dispatch('Logout').then(() => {
					setTimeout(() => {
						window.location.reload()
					}, 1500)
				})
			}
		}
	}
	return Promise.reject(error)
};

/**
 * response interceptor
 * 所有请求统一返回
 */
service.interceptors.response.use((response) => {
	if (response.request.responseType === 'blob') {
		return response
	}
	const code = response.data.code;
	if (code === 1011006 || code === 1011007 || code === 1011008 || code === 1011009 || code === '1011006') {
		Modal.error({
			title: '提示：',
			content: response.data.data,
			okText: '重新登录',
			onOk: () => {
				Vue.ls.remove(ACCESS_TOKEN);
				window.location.reload()
			}
		})
	} else if (code === 1013002 || code === 1016002 || code === 1015002) {
		message.error(response.data.message);
		return response.data
	} else {
		return response.data
	}
}, err);

export {
	service as axios
}
