<template>
	<a-layout class="layout">
		<a-layout-sider :collapsed="collapsed" :width="siderWidth" :trigger="null" :class="['sider',navTheme]"
			:style="siderFixed">

			<logo :show-title="!collapsed" />

			<a-menu :theme="navTheme" mode="inline" :default-open-keys="openKeys" :open-keys="openKeys"
				:default-selected-keys="[$route.path]" :selected-keys="[$route.path]" @openChange="onOpenChange">
				<a-sub-menu v-for="(item) in menus" :key="item.menuCode" :value="item.menuCode">
					<span slot="title">
						<a-icon :type="item.icon" /><span>{{item.name}}</span>
					</span>
					<a-menu-item v-for="(itemSub) in item.children" :key="itemSub.url" :value="itemSub.menuCode">
						<router-link :to="itemSub.url">
							{{itemSub.name}}
						</router-link>
					</a-menu-item>
				</a-sub-menu>
			</a-menu>
		</a-layout-sider>

		<a-layout :style="layoutStyle">
			<a-layout-header :class="['top-nav-header-index']" :style="{'backgroundColor':''+headerColor}">
				<a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
					@click="() => (collapsed = !collapsed)" title="滑动" />
				<a-icon type="reload" title="刷新" :spin="spaned" class="trigger" @click="refreshView" />
				<user-menu class="header-index-right"></user-menu>
			</a-layout-header>

			<a-layout-content :style="{ padding: '24px', background: '#fff' }">
				<router-view @updateOpenKeys="onOpenChange"></router-view>
			</a-layout-content>
		</a-layout>

	</a-layout>
</template>
<script>
	import Logo from '../components/tools/Logo'
	import UserMenu from '../components/tools/UserMenu'
	import {
		mixin
	} from '@/utils/mixin'
	import {
		getMenuByRoleId,
	} from '@/api/modular/system/accountManage.js'

	export default {
		mixins: [mixin],
		components: {
			UserMenu,
			Logo
		},

		data() {
			return {
				// 刷新按钮旋转动画
				spaned: false,
				// 侧边栏控制
				collapsed: false,
				// 侧边栏宽度
				siderWidth: '256px',
				// 根节点集合
				rootSubmenuKeys: [],
				// 展开key
				openKeys: [],
				// 权限菜单
				menus: [],
				// 固定侧边栏
				siderFixed: {
					'overflow': 'auto',
					'height': '100vh',
					'position': 'fixed',
					'left': 0,
				},
			};
		},

		// created钩子
		created() {
			// 生成菜单
			this.generateMenu();
		},

		// 计算属性
		computed: {
			// layout内容区动态左边距
			layoutStyle() {
				return {
					"margin-left": this.collapsed ? '80px' : this.siderWidth,
					"transition": 'all 0.2s',
				}
			},
		},

		// 方法函数
		methods: {
			// 菜单简洁模式
			onOpenChange(openKeys) {
				let latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
				if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
					this.openKeys = openKeys;
				} else {
					this.openKeys = latestOpenKey ? [latestOpenKey] : [];
				}
			},

			// 更新默认展开根节点
			handleDefaultOpenKey(menus) {
				// 当前页面
				let path = this.$route.path;
				// 非home页
				if (path == '/main/home') {
					return;
				}
				// 当前页对应菜单项
				let menu = menus.find(item => {
					return item.level == 2 && item.url == path;
				})
				// 更新默认展开根节点
				if (menu) {
					this.openKeys.push(menu.parentMenuCode);
				}
			},

			// 生成菜单
			generateMenu() {
				//参数
				let param = {
					roleId: localStorage.getItem("roleId")
				}
				// 调用接口
				getMenuByRoleId(param).then(res => {
					//返回成功
					if (res.success) {
						// 权限拥有菜单
						let auths = res.data.auths;
						// 所有菜单
						let menus = res.data.menus;

						// 提取权限菜单
						let authMenus = menus.filter((menu) => {
							if (auths.findIndex((item) => item.menuCode == menu.menuCode) != -1) {
								return menu;
							}
						})

						// 提取权限菜单根节点
						let rootMenus = authMenus.filter((item) => {
							return item.level == 1;
						})
						// 提取根节点code
						let rootKeys = rootMenus.map((item) => {
							return item.menuCode;
						})
						// 根节点code
						this.rootSubmenuKeys = rootKeys;

						// 根节点菜单补全children
						rootMenus.forEach((rootMenu) => {
							// 子节点数据
							rootMenu.children = authMenus.filter((menu) => {
								return menu.parentMenuCode == rootMenu.menuCode;
							})
						})

						// 添加至data
						this.menus = rootMenus;

						// 更新默认展开根节点
						this.handleDefaultOpenKey(menus)
					}
				})
			},

			// 刷新
			refreshView() {
				this.spaned = true;
				location.reload()
			},
		}
	};
</script>

<style lang="less">
	.ant-layout-header {
		padding: 0 0.6rem !important;
	}

	// chrome 和Safari
	.ant-layout-sider-children::-webkit-scrollbar {
		display: none;
		width: 0;
	}

	// 兼容Firefox
	.ant-layout-sider-children {
		overflow: -moz-scrollbars-none;
	}

	// 兼容IE10+
	.ant-layout-sider-children {
		-ms-overflow-style: none;
	}
</style>
