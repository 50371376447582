<template>
	<a-modal title="菜单权限配置" :width="350" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" okText="保存" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">

		<div style="margin-top: -10px;">
			<span>
				角色名称：{{roleName}}
			</span>
		</div>

		<a-spin :spinning="confirmLoading">
			<a-form :form="form">

				<a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input v-decorator="['roleId']" />
				</a-form-item>

				<a-tree style="scroll:true" checkable :tree-data="authorityTree"
					:defaultCheckedKeys="defaultCheckedKeys" v-if="authorityTree.length" defaultExpandAll
					@check="treeCheck" :replaceFields="replaceFields">
				</a-tree>

			</a-form>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		setRoleId,
		sysRoleMenu
	} from '@/api/modular/system/roleManage'

	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this),
				// 树形数据
				authorityTree: [],
				// 自定义字段替换
				replaceFields: {
					key: 'menuCode',
					title: 'name'
				},
				// 所有节点不包含半勾选父节点     已选子节点（不包括半勾选根节点）
				checkedKeys: [],
				// 所有节点包含半勾选父节点     已勾选子节点以及半勾选状态的根节点
				allCheckedKeys: [],
				// 默认选中节点
				defaultCheckedKeys: [],
				// 权限名称
				roleName: '',
			}
		},

		methods: {

			// 初始化方法
			setRole(record) {
				this.visible = true
				this.roleName = record.name
				setTimeout(() => {
					this.form.setFieldsValue({
						roleId: record.roleId
					})
				}, 100)
				this.setRoleById(record.roleId);
			},

			//获取数据
			setRoleById(value) {
				setRoleId({
					roleId: value
				}).then((res) => {
					if (!res.success) {
						return
					}

					// 菜单数据
					let menus = JSON.parse(res.data.menus)
					//获取tree类型数据
					let menusTree = this.toTreeData(menus)
					// 树结构数据
					this.authorityTree = menusTree;

					// 所有选中节点包含半勾选父节点
					this.allCheckedKeys = JSON.parse(res.data.auths);

					// 权限菜单数据
					let auths = JSON.parse(res.data.auths);
					//处理选中数据
					menusTree.forEach((item) => {
						let index = auths.findIndex(e => {
							return e == item.menuCode
						});

						if (index != -1) {
							auths.splice(index, 1);
						}
					})
					//初始化选中数据
					this.defaultCheckedKeys = auths;
					this.checkedKeys = auths;
				})
			},

			//改变为tree类型数据
			toTreeData(lst) {
				let l = lst.filter(function(x) {
					return x.parentMenuCode == 0
				})
				l.forEach((y) => {
					y.children = lst.filter(function(x) {
						return x.parentMenuCode == y.menuCode;
					});

				});
				return l;
			},

			//树节点选择
			treeCheck(checkedKeys, e) {
				// 所有节点不包含半勾选父节点     已选子节点（不包括半勾选根节点）
				this.checkedKeys = checkedKeys;
				// 所有节点包含半勾选父节点     已勾选子节点以及半勾选状态的父节点
				this.allCheckedKeys = checkedKeys.concat(e.halfCheckedKeys);
			},

			//保存
			handleSubmit() {
				const {
					form: {
						validateFields
					}
				} = this
				this.confirmLoading = true
				validateFields((errors, values) => {
					if (!errors) {
						// 添加节点数据
						values.codeList = this.allCheckedKeys;
						// 调用接口
						sysRoleMenu(values).then((res) => {
							if (res.success) {
								this.$message.success('设置成功')
								// 重置窗口数据
								this.handleCancel();
								this.$emit('ok', values)
								this.form.resetFields()
							} else {
								this.$message.error('设置失败：' + res.message)
							}
						}).catch(() => {
							this.confirmLoading = false;
						}).finally(() => {
							this.confirmLoading = false;
						})
					} else {
						this.confirmLoading = false
					}
				})
			},

			//取消、关闭窗口
			handleCancel() {
				// 重置数据
				this.visible = false;
				this.confirmLoading = false;
				this.roleName = '';
				this.authorityTree = [];
				this.defaultCheckedKeys = [];
				this.checkedKeys = [];
				this.allCheckedKeys = [];
			},
		}
	}
</script>
