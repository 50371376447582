<template>
	<a-modal title="添加区域" :width="900" :visible="visible" :maskClosable="false" :confirmLoading="confirmLoading" okText="保存" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">

				<!-- 省添加 -->
				<template v-if="type == 1">
					<a-form-item label="省份名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="20" placeholder="请输入省份名称"
							v-decorator="['provinceName', {rules: [{required: true, message: '请输入省份名称！'}]}]" />
					</a-form-item>

					<a-form-item label="行政区划代码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="50" placeholder="请输入行政区划代码" v-decorator="['code']" />
					</a-form-item>

					<a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="6" placeholder="请输入排序" v-decorator="['sort']" />
					</a-form-item>

					<a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="50" type="textarea" placeholder="请输入备注" v-decorator="['remark']" />
					</a-form-item>
				</template>

				<!-- 市添加 -->
				<template v-if="type == 2">
					<a-form-item label="省" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-select v-decorator="['provinceId',{ rules: [{ required: true, message: '请选择省！' }] },]"
							placeholder="请选择省" disabled>
							<a-select-option v-for="(item,index) in provinceBoxs" :key="'provinceId' + index"
								:value="item.provinceId">
								{{item.provinceName}}
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="市名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="20" placeholder="请输入市名称"
							v-decorator="['cityName', {rules: [{required: true, message: '请输入市名称！'}]}]" />
					</a-form-item>

					<a-form-item label="行政区划代码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="50" placeholder="请输入行政区划代码" v-decorator="['code']" />
					</a-form-item>

					<a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="6" placeholder="请输入排序" v-decorator="['sort']" />
					</a-form-item>
				</template>

				<!-- 区添加 -->
				<template v-if="type == 3">
					<a-form-item label="省" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-select v-decorator="['provinceId',{ rules: [{ required: true, message: '请选择省！' }] },]"
							placeholder="请选择省" @change="changeCityBox" disabled>
							<a-select-option v-for="(item,index) in provinceBoxs" :key="'provinceId' + index"
								:value="item.provinceId">
								{{item.provinceName}}
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="市" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-select v-decorator="['cityId',{ rules: [{ required: true, message: '请选择市！' }] },]"
							placeholder="请选择市" disabled>
							<a-select-option v-for="(item,index) in cityBoxs" :key="'cityId' + index"
								:value="item.cityId">
								{{item.cityName}}
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="区名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="20" placeholder="请输入区名称"
							v-decorator="['districtName', {rules: [{required: true, message: '请输入区名称！'}]}]" />
					</a-form-item>

					<a-form-item label="行政区划代码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="50" placeholder="请输入行政区划代码" v-decorator="['code']" />
					</a-form-item>

					<a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
						<a-input :max-length="6" placeholder="请输入排序" v-decorator="['sort']" />
					</a-form-item>
				</template>

			</a-form>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		initProvinceBoxs,
		changeCityBox,
		saveProvince,
		saveCity,
		saveDistrict
	} from '@/api/modular/system/areaManage'
	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},

				type: '',
				provinceBoxs: [],
				cityBoxs: [],
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this)
			}
		},

		created() {
			this.initProvinceBoxs();
		},

		methods: {
			// 初始化方法
			add(record) {
				this.visible = true;
				let type = Number(record.type) + 1;
				this.type = type;

				//初始化分支添加
				//市添加
				if (type == 2) {
					setTimeout(() => {
						this.form.setFieldsValue({
							provinceId: record.id,
						})
					}, 100)

					//区添加
				} else if (type == 3) {
					setTimeout(() => {
						this.form.setFieldsValue({
							provinceId: record.parentId,
						})
					}, 100)

					//查询下级市列表
					changeCityBox({
						id: record.parentId
					}).then(res => {
						if (res.success) {
							this.cityBoxs = res.data;
							setTimeout(() => {
								this.form.setFieldsValue({
									cityId: record.id
								})
							}, 50);
						}
					})
				}
			},

			// 初始化省级下拉菜单
			initProvinceBoxs() {
				initProvinceBoxs().then(res => {
					if (res.success) {
						this.provinceBoxs = res.data;
					} else {
						this.$message.error('获取省列表失败：' + res.message);
					}
				})
			},

			//联动查询省级下市列表
			changeCityBox(value) {
				let param = {
					id: value
				}
				//查询下级市列表
				changeCityBox(param).then(res => {
					if (res.success) {
						this.cityBoxs = res.data;
						setTimeout(() => {
							this.form.setFieldsValue({
								cityId: res.data[0].cityId,
							})
						}, 100)
					}
				})
			},

			//省新增
			submitProvince(values) {
				//新增
				saveProvince(values).then((res) => {
					//成功
					if (res.success) {
						this.axiosOk(values);
					} else {
						this.$message.error('新增失败：' + res.message);
						this.confirmLoading = false;
					}
				}).error(() => {
					console.log("error")
					this.confirmLoading = false
				}).finally(() => {
					this.confirmLoading = false
				})
			},

			//市新增
			submitCity(values) {
				//新增
				saveCity(values).then((res) => {
					//成功
					if (res.success) {
						this.axiosOk(values);
					} else {
						this.$message.error('新增失败：' + res.message);
						this.confirmLoading = false;
					}
				}).error(() => {
					console.log("error")
					this.confirmLoading = false
				}).finally(() => {
					this.confirmLoading = false
				})
			},

			//区新增
			submitDistrict(values) {
				//新增
				saveDistrict(values).then((res) => {
					//成功
					if (res.success) {
						this.axiosOk(values);
					} else {
						this.$message.error('新增失败：' + res.message);
						this.confirmLoading = false;
					}
				}).error(() => {
					console.log("error")
					this.confirmLoading = false;
				}).finally(() => {
					this.confirmLoading = false;
				})
			},

			//提交
			handleSubmit() {
				const {
					form: {
						validateFields
					}
				} = this;
				this.confirmLoading = true;
				//表单验证
				validateFields((errors, values) => {
					if (!errors) {
						//提交分支
						if (this.type == 1) {
							this.submitProvince(values);
						} else if (this.type == 2) {
							this.submitCity(values);
						} else if (this.type == 3) {
							this.submitDistrict(values);
						}
					} else {
						this.confirmLoading = false
					}
				})
				this.confirmLoading = false
			},

			//取消  关闭 
			handleCancel() {
				this.form.resetFields();
				this.visible = false;
				this.type = '';
				// this.provinceBoxs = [];
				this.cityBoxs = [];
			},

			//提交成功
			axiosOk(values) {
				this.$message.success('新增成功');
				this.visible = false;
				this.confirmLoading = false;
				this.$emit('ok', values);
				this.form.resetFields();
				this.type = '';
				// this.provinceBoxs = [];
				this.cityBoxs = [];
			}
		}
	}
</script>
