<template>
  <div class="setting-drawer">
    <a-drawer
      width="300"
      placement="right"
      @close="onClose"
      :closable="false"
      :visible="visible"
    >
      <div class="setting-drawer-index-content">

        <div :style="{ marginBottom: '24px' }">
          <h3 class="setting-drawer-index-title">整体风格设置</h3>

          <div class="setting-drawer-index-blockChecbox">
            <a-tooltip>
              <template slot="title">
                暗色菜单风格
              </template>
              <div class="setting-drawer-index-item" @click="handleMenuTheme('dark')">
                <img src="@/assets/common/module/img/img_theme_dark.svg" alt="dark">
                <div class="setting-drawer-index-selectIcon" v-if="navTheme === 'dark'">
                  <a-icon type="check"/>
                </div>
              </div>
            </a-tooltip>

            <a-tooltip>
              <template slot="title">
                亮色菜单风格
              </template>
              <div class="setting-drawer-index-item" @click="handleMenuTheme('light')">
                <img src="@/assets/common/module/img/img_theme_light.svg" alt="light">
                <div class="setting-drawer-index-selectIcon" v-if="navTheme !== 'dark'">
                  <a-icon type="check"/>
                </div>
              </div>
            </a-tooltip>
          </div>
          <div class="setting-drawer-index-blockChecbox" style="padding-top: 20px">
            <a-tooltip>
              <template slot="title">
                亮色顶栏
              </template>
              <div class="setting-drawer-index-item" @click="handleHeader('white')">
                <img src="@/assets/common/module/img/img_header_white.svg" alt="white">
                <div class="setting-drawer-index-selectIcon" v-if="headerColor === 'white'">
                  <a-icon type="check"/>
                </div>
              </div>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
               暗色顶栏
              </template>
              <div class="setting-drawer-index-item" @click="handleHeader('black')">
                <img src="@/assets/common/module/img/img_header_black.svg" alt="black">
                <div class="setting-drawer-index-selectIcon" v-if="headerColor === 'black'">
                  <a-icon type="check"/>
                </div>
              </div>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                主色顶栏
              </template>
              <div class="setting-drawer-index-item" @click="handleHeader('pink')">
                <img src="@/assets/common/module/img/img_header_pink.svg" alt="pink">
                <div class="setting-drawer-index-selectIcon" v-if="headerColor === 'pink'">
                  <a-icon type="check"/>
                </div>
              </div>
            </a-tooltip>
          </div>
        </div>

        <div :style="{ marginBottom: '24px' }">
          <h3 class="setting-drawer-index-title">主题色</h3>

          <div style="height: 20px">
            <a-tooltip class="setting-drawer-theme-color-colorBlock" v-for="(item, index) in colorList" :key="index">
              <template slot="title">
                {{ item.key }}
              </template>
              <a-tag :color="item.color" @click="changeColor(item.color)">
                <a-icon type="check" v-if="item.color === primaryColor"></a-icon>
              </a-tag>
            </a-tooltip>

          </div>
        </div>
        <a-divider />
      </div>
<!--      <div class="setting-drawer-index-handle" @click="toggle" slot="handle">-->
<!--        <a-icon type="setting" v-if="!visible"/>-->
<!--        <a-icon type="close" v-else/>-->
<!--      </div>-->
    </a-drawer>
  </div>
</template>

<script>

// import config from '@/config/defaultSettings'
import { updateTheme, colorList } from './settingConfig'
import { mixin } from '@/utils/mixin'
import store from '@/store'

export default {
  components: {

  },
  mixins: [mixin],
  data () {
    return {
      visible: false,
      colorList
    }
  },
  watch: {

  },
  mounted () {
    updateTheme(this.primaryColor)
    // if (this.colorWeak !== config.colorWeak) {
    //   updateColorWeak(this.colorWeak)
    // }
  },
  methods: {

    onClose () {
      this.visible = false
    },
    toggle () {
      this.visible = !this.visible
    },
    handleMenuTheme (theme) {
      // document.querySelector('header').removeAttribute('style');
      store.dispatch('ToggleTheme', theme)
    },
    handleHeader(color) {
      // document.querySelector('header').removeAttribute('style');
      store.dispatch('ToggleHeader', color)
    },
    changeColor (color) {
      if (this.primaryColor !== color) {
        // document.querySelector('header').setAttribute('style', 'background:'+color);
        store.dispatch('ToggleColor', color)
        updateTheme(color)
      }
    },
  }
}
</script>

<style lang="less" scoped>

  .setting-drawer-index-content {

    .setting-drawer-index-blockChecbox {
      display: flex;
      .setting-drawer-index-item {
        margin-right: 16px;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
        img {
          width: 48px;
        }
        .setting-drawer-index-selectIcon {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          padding-top: 15px;
          padding-left: 24px;
          height: 100%;
          color: #1890ff;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
    .setting-drawer-theme-color-colorBlock {
      width: 20px;
      height: 20px;
      border-radius: 2px;
      float: left;
      cursor: pointer;
      margin-right: 8px;
      padding-left: 0px;
      padding-right: 0px;
      text-align: center;
      color: #fff;
      font-weight: 700;

      i {
        font-size: 14px;
      }
    }
  }

  .setting-drawer-index-handle {
    position: absolute;
    top: 80%;
    background: #1890ff;
    width: 48px;
    height: 48px;
    right: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: auto;
    z-index: 1001;
    text-align: center;
    font-size: 16px;
    border-radius: 4px 0 0 4px;

    i {
      color: rgb(255, 255, 255);
      font-size: 20px;
    }
  }
</style>
