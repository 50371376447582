var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.confirmLoading}},[_c('a-anchor',{attrs:{"affix":true,"showInkInFixed":false},on:{"click":_vm.handleAnchorClick}},[_c('div',{staticClass:"anchor-card"},[_c('a-row',{attrs:{"gutter":24,"type":"flex","justify":"center"}},[_c('a-col',{attrs:{"span":3}},[_c('a-anchor-link',{attrs:{"href":"Anchor_Basic","title":"基础信息"}})],1),_c('a-col',{attrs:{"span":3}},[_c('a-anchor-link',{attrs:{"href":"Anchor_activity","title":"小程序活动信息"}})],1),_c('a-col',{attrs:{"span":3}},[_c('a-anchor-link',{attrs:{"href":"Anchor_activity_xhs","title":"小红书活动信息"}})],1),_c('a-col',{attrs:{"span":3}},[_c('a-anchor-link',{attrs:{"href":"Anchor_matter","title":"事项说明"}})],1)],1)],1)]),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-card',{attrs:{"id":"Anchor_Basic"}},[_c('div',{staticClass:"card-slot-title",attrs:{"slot":"title"},slot:"title"},[_c('a-divider',{staticClass:"divider-tag",staticStyle:{"background-color":"#0077dd"},attrs:{"type":"vertical"}}),_c('span',{staticClass:"card-title"},[_vm._v("基础信息")])],1),(_vm.isOperate)?_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['activityId']),expression:"['activityId']"}]})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"预览图","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('image-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'previewUrl',
            {
              rules: [{ required: true, message: '请上传预览图！' }],
              initialValue: '',
            } ]),expression:"[\n            'previewUrl',\n            {\n              rules: [{ required: true, message: '请上传预览图！' }],\n              initialValue: '',\n            },\n          ]"}],attrs:{"imgHttpPath":_vm.relatedParam.imgHttpPath,"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"标题","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'title',
            { rules: [{ required: true, message: '请输入标题！' }] } ]),expression:"[\n            'title',\n            { rules: [{ required: true, message: '请输入标题！' }] },\n          ]"}],attrs:{"placeholder":"请输入标题","max-length":255,"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"主办方","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sponsor',
            { rules: [{ required: true, message: '请输入主办方！' }] } ]),expression:"[\n            'sponsor',\n            { rules: [{ required: true, message: '请输入主办方！' }] },\n          ]"}],attrs:{"placeholder":"请输入主办方","max-length":255,"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"主办方联系方式","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phone',
            {
              rules: [{ required: true, message: '请输入主办方联系方式！' }],
            } ]),expression:"[\n            'phone',\n            {\n              rules: [{ required: true, message: '请输入主办方联系方式！' }],\n            },\n          ]"}],attrs:{"placeholder":"请输入主办方联系方式","max-length":255,"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"作品征集时间","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'signDate',
            {
              rules: [
                {
                  type: 'array',
                  required: true,
                  message: '请选择作品征集时间!',
                } ],
            } ]),expression:"[\n            'signDate',\n            {\n              rules: [\n                {\n                  type: 'array',\n                  required: true,\n                  message: '请选择作品征集时间!',\n                },\n              ],\n            },\n          ]"}],attrs:{"show-time":"","format":_vm.dateFormat,"valueFormat":_vm.dateFormat,"disabled":_vm.isSee,"allowClear":false}})],1),_c('a-form-item',{attrs:{"label":"作品评选时间","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'voteDate',
            {
              rules: [
                {
                  type: 'array',
                  required: true,
                  message: '请选择作品评选时间!',
                } ],
            } ]),expression:"[\n            'voteDate',\n            {\n              rules: [\n                {\n                  type: 'array',\n                  required: true,\n                  message: '请选择作品评选时间!',\n                },\n              ],\n            },\n          ]"}],attrs:{"show-time":"","format":_vm.dateFormat,"valueFormat":_vm.dateFormat,"disabled":_vm.isSee,"allowClear":false}})],1),_c('a-form-item',{attrs:{"label":"获奖公布日","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'publishDate',
            { rules: [{ required: true, message: '请输入获奖公布日！' }] } ]),expression:"[\n            'publishDate',\n            { rules: [{ required: true, message: '请输入获奖公布日！' }] },\n          ]"}],attrs:{"show-time":"","format":_vm.dateFormat,"valueFormat":_vm.dateFormat,"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"活动对象","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'target',
            { rules: [{ required: true, message: '请输入活动对象！' }] } ]),expression:"[\n            'target',\n            { rules: [{ required: true, message: '请输入活动对象！' }] },\n          ]"}],attrs:{"placeholder":"请输入活动对象","max-length":2000,"disabled":_vm.isSee,"auto-size":{ minRows: 2, maxRows: 5 }}})],1)],1),_c('a-card',{staticStyle:{"margin-top":"1.25rem"},attrs:{"id":"Anchor_activity"}},[_c('div',{staticClass:"card-slot-title",attrs:{"slot":"title"},slot:"title"},[_c('a-divider',{staticClass:"divider-tag",staticStyle:{"background-color":"#ffaa00"},attrs:{"type":"vertical"}}),_c('span',{staticClass:"card-title"},[_vm._v("活动介绍 小程序")])],1),_c('a-form-item',{attrs:{"label":"个性标题","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'selfTitle',
            { rules: [{ required: true, message: '请输入标题！' }] } ]),expression:"[\n            'selfTitle',\n            { rules: [{ required: true, message: '请输入标题！' }] },\n          ]"}],attrs:{"placeholder":"请输入个性标题","max-length":255,"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"参与方式","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'participate',
            {
              rules: [{ required: true, message: '请输入参与方式！' }],
              initialValue: '',
            } ]),expression:"[\n            'participate',\n            {\n              rules: [{ required: true, message: '请输入参与方式！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"participate",attrs:{"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"作品要求","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'worksRule',
            {
              rules: [{ required: true, message: '请输入作品要求！' }],
              initialValue: '',
            } ]),expression:"[\n            'worksRule',\n            {\n              rules: [{ required: true, message: '请输入作品要求！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"worksRule",attrs:{"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"奖项设定","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'reward',
            {
              rules: [{ required: true, message: '请输入奖项设定！' }],
              initialValue: '',
            } ]),expression:"[\n            'reward',\n            {\n              rules: [{ required: true, message: '请输入奖项设定！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"reward",attrs:{"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"评选方式","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'selection',
            {
              rules: [{ required: true, message: '请输入评选方式！' }],
              initialValue: '',
            } ]),expression:"[\n            'selection',\n            {\n              rules: [{ required: true, message: '请输入评选方式！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"selection",attrs:{"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"补充说明","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'moreInfo',
            {
              rules: [{ required: true, message: '请输入补充说明！' }],
              initialValue: '',
            } ]),expression:"[\n            'moreInfo',\n            {\n              rules: [{ required: true, message: '请输入补充说明！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"moreInfo",attrs:{"disabled":_vm.isSee}})],1)],1),_c('a-card',{staticStyle:{"margin-top":"1.25rem"},attrs:{"id":"Anchor_activity_xhs"}},[_c('div',{staticClass:"card-slot-title",attrs:{"slot":"title"},slot:"title"},[_c('a-divider',{staticClass:"divider-tag",staticStyle:{"background-color":"#ffaa00"},attrs:{"type":"vertical"}}),_c('span',{staticClass:"card-title"},[_vm._v("活动介绍 小红书")])],1),(_vm.isOperate)?_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['xhs_extendId']),expression:"['xhs_extendId']"}]})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"个性标题","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'xhs_selfTitle',
            { rules: [{ required: true, message: '请输入标题！' }] } ]),expression:"[\n            'xhs_selfTitle',\n            { rules: [{ required: true, message: '请输入标题！' }] },\n          ]"}],attrs:{"placeholder":"请输入个性标题","max-length":255,"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"参与方式","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'xhs_participate',
            {
              rules: [{ required: true, message: '请输入参与方式！' }],
              initialValue: '',
            } ]),expression:"[\n            'xhs_participate',\n            {\n              rules: [{ required: true, message: '请输入参与方式！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"xhs_participate",attrs:{"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"作品要求","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'xhs_worksRule',
            {
              rules: [{ required: true, message: '请输入作品要求！' }],
              initialValue: '',
            } ]),expression:"[\n            'xhs_worksRule',\n            {\n              rules: [{ required: true, message: '请输入作品要求！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"xhs_worksRule",attrs:{"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"奖项设定","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'xhs_reward',
            {
              rules: [{ required: true, message: '请输入奖项设定！' }],
              initialValue: '',
            } ]),expression:"[\n            'xhs_reward',\n            {\n              rules: [{ required: true, message: '请输入奖项设定！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"xhs_reward",attrs:{"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"评选方式","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'xhs_selection',
            {
              rules: [{ required: true, message: '请输入评选方式！' }],
              initialValue: '',
            } ]),expression:"[\n            'xhs_selection',\n            {\n              rules: [{ required: true, message: '请输入评选方式！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"xhs_selection",attrs:{"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"补充说明","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'xhs_moreInfo',
            {
              rules: [{ required: true, message: '请输入补充说明！' }],
              initialValue: '',
            } ]),expression:"[\n            'xhs_moreInfo',\n            {\n              rules: [{ required: true, message: '请输入补充说明！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"xhs_moreInfo",attrs:{"disabled":_vm.isSee}})],1)],1),_c('a-card',{staticStyle:{"margin-top":"1.25rem"},attrs:{"id":"Anchor_matter"}},[_c('div',{staticClass:"card-slot-title",attrs:{"slot":"title"},slot:"title"},[_c('a-divider',{staticClass:"divider-tag",staticStyle:{"background-color":"#840000"},attrs:{"type":"vertical"}}),_c('span',{staticClass:"card-title"},[_vm._v("事项说明")])],1),_c('a-form-item',{attrs:{"label":"注意事项","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'matter',
            {
              rules: [{ required: true, message: '请输入注意事项！' }],
              initialValue: '',
            } ]),expression:"[\n            'matter',\n            {\n              rules: [{ required: true, message: '请输入注意事项！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"matter",attrs:{"disabled":_vm.isSee}})],1),_c('a-form-item',{attrs:{"label":"其他事项","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('x-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'otherMatter',
            {
              rules: [{ required: false, message: '请输入其他事项！' }],
              initialValue: '',
            } ]),expression:"[\n            'otherMatter',\n            {\n              rules: [{ required: false, message: '请输入其他事项！' }],\n              initialValue: '',\n            },\n          ]"}],ref:"otherMatter",attrs:{"disabled":_vm.isSee}})],1)],1)],1),_c('div',{staticStyle:{"height":"60px"}},[_c('div',{staticClass:"r"},[(_vm.isSee)?_c('div',[_c('a-button',{on:{"click":_vm.handleCancel}},[_vm._v(" 关闭")])],1):_c('div',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" 保存")]),_c('a-button',{staticStyle:{"margin-left":"40px"},on:{"click":_vm.handleCancel}},[_vm._v(" 关闭 ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }