<template>
  <a-card :bordered="false">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item v-if="dataSign" style="display: none;">
          <a-input v-decorator="['id']" />
        </a-form-item>

        <!-- <a-form-item label="首页头图" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <image-upload :imgHttpPath="relatedParam.imgHttpPath" style="width: 50%;"
            v-decorator="['headImgUrl',{rules: [{required: true, message: '请上传预览图！'}], initialValue: ''}]" />
        </a-form-item> -->

        <!-- 				<a-form-item label="基本颜色" :labelCol="labelCol" :wrapperCol="wrapperCol">
					<a-input placeholder="请输入基本颜色" :max-length="50"
						v-decorator="['appColor', {rules: [{required: true, message: '请输入基本颜色！'}]}]" />
				</a-form-item> -->

        <a-form-item label="隐私内容" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <x-editor ref="privacyContent"
            v-decorator="['privacyContent', {rules: [{required: true, message: '请输入隐私内容！'}], initialValue: ''}]" />
        </a-form-item>

      </a-form>

      <div style="height: 60px;">
        <div class="r">
          <a-button type="primary" @click="handleSubmit">
            保存
          </a-button>
        </div>
      </div>

    </a-spin>
  </a-card>
</template>

<script>
  import {
    initRelatedParam,
    loadData,
    saveOrUpdateData
  } from '@/api/modular/system/settingManage';
  // import imageUpload from '@/components/FileUpload/ImageUpload';
  import xEditor from '@/components/XEditor';

  export default {
    components: {
      // imageUpload,
      xEditor
    },

    data() {
      return {
        // 表单layout
        labelCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 3
          }
        },
        wrapperCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 18
          }
        },
        // 表单
        form: this.$form.createForm(this),
        // loading
        confirmLoading: true,
        //关联参数
        relatedParam: {},

        // 存在数据标识
        dataSign: true,

        // 操作者数据
        operator: {
          // userId: localStorage.getItem("userId"),
        },
      }
    },

    // created钩子函数
    created() {
      this.initRelatedParam();
      this.loadData();
    },

    // 计算属性
    computed: {},

    // 方法函数
    methods: {

      // -> 功能业务
      // 初始化关联参数
      initRelatedParam() {
        initRelatedParam({}).then((res) => {
          // 成功
          if (res.success) {
            // 关联参数取得
            this.relatedParam = res.data;
          } else {
            this.$error({
              okText: '确定',
              title: '提示信息',
              content: "请求关联参数失败，原因：" + res.message
            });
          }
        }).catch((err) => {
          this.$error({
            okText: '确定',
            title: '提示信息',
            content: "请求关联参数失败，原因：" + err.response.data.message,
          });
        })
      },

      // 加载数据
      loadData() {
        loadData({}).then((res) => {
          // 成功
          if (res.success) {
            // 存在数据
            if (res.data) {
              // 数据标志
              this.dataSign = true;

              // 延时赋值
              setTimeout(() => {
                this.form.setFieldsValue({
                  id: res.data.id,
                  headImgUrl: res.data.headImgUrl,
                  appColor: res.data.appColor,
                  privacyContent: res.data.privacyContent,
                })
              }, 100);
            } else {
              // 数据标志
              this.dataSign = false;
            }
          } else {
            this.$error({
              okText: '确定',
              title: '提示信息',
              content: "请求数据失败，原因：" + res.message
            });
          }
        }).catch((err) => {
          this.$error({
            okText: '确定',
            title: '提示信息',
            content: "请求数据失败，原因：" + err.response.data.message,
          });
          // finally
        }).finally(() => {
          this.confirmLoading = false;
        })
      },

      //ok方法  编辑提交
      handleSubmit() {
        const {
          form: {
            validateFields
          }
        } = this;
        this.confirmLoading = true;
        // 校验
        validateFields((errors, values) => {
          // 校验通过
          if (!errors) {
            // 调用接口
            saveOrUpdateData(values).then((res) => {
              // 返回成功
              if (res.success) {
                this.$message.success('操作成功');
                this.form.resetFields();
                this.loadData();
                // 返回失败
              } else {
                this.$warning({
                  okText: '确定',
                  title: '提示信息',
                  content: "操作失败，原因：" + res.message
                });
              }
              // 异常
            }).catch((err) => {
              this.$error({
                okText: '确定',
                title: '提示信息',
                content: "操作失败，原因：" + err.response.data.message
              });
              // finally
            }).finally(() => {
              this.confirmLoading = false;
            })
            // 校验不通过
          } else {
            this.confirmLoading = false;
          }
        })
      },

    }
  }
</script>

<style scoped lang="less">
/deep/.r {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 70px;
    background-color: #ededed;
    left: 125px;
    text-align: center;
    padding: 20px 0;
    z-index: 99;
  }

  /deep/.bottom {
    width: 30%;
    height: 37px;
    font-size: 16px;
    font-weight: 700;
    position: fixed;
    bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    left: 40%;
    z-index: 99;
  }
</style>
