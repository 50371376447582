<template>
	<a-modal title="密码修改" :width="900" :visible="visible" :confirmLoading="confirmLoading" okText="保存" cancelText="关闭"
		@ok="onSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">
				<a-form-item label="旧密码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入旧密码" type="password"
						v-decorator="['password', {rules: [{required: true, message: '请输入旧密码！'}]}]" />
				</a-form-item>

				<a-form-item label="新密码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input-password placeholder="请输入6位或6位以上的半角英文和数字。" type="password"
						v-decorator="['newPassword', {rules: [{required: true, message: '请输入新密码！'}]}]" />
				</a-form-item>

				<a-form-item label="确认密码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input-password placeholder="请输入6位或位以上的半角英文和数字。" type="password" style="width: 100%;"
						v-decorator="['passwordConfirm', {rules: [{required: true, message: '请输入确认密码！'}]}]" />
				</a-form-item>

			</a-form>

		</a-spin>
	</a-modal>
</template>

<script>
	import {
		modifyPwd
	} from '@/api/modular/system/userManage'
	import Vue from 'vue'
	import {
		ACCESS_TOKEN
	} from '@/store/mutation-types'
	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this),
			}
		},
		methods: {
			// 初始化方法
			add() {
				this.visible = true
			},

			onSubmit() {
				const {
					form: {
						validateFields
					}
				} = this
				this.confirmLoading = true
				validateFields((errors, values) => {
					if (values.password != '') {
						let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/;
						if (!reg.test(values.password)) {
							this.$message.error('旧密码请输入6位或6位以上的半角英数字。')
							this.confirmLoading = false
							return
						}
					}
					if (values.newPassword != '') {
						let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/;
						if (!reg.test(values.newPassword)) {
							this.$message.error('新密码请输入6位或6位以上的半角英数字。')
							this.confirmLoading = false
							return
						}
					}
					if (values.passwordConfirm != '') {
						let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/;
						if (!reg.test(values.passwordConfirm)) {
							this.$message.error('确认密码请输入6位或6位以上的半角英数字。')
							this.confirmLoading = false
							return
						}
					}
					if (values.newPassword != values.passwordConfirm) {
						this.$message.error('新密码和确认密码输不一致。')
						this.confirmLoading = false
						return
					}
					if (!errors) {
						modifyPwd(values).then((res) => {
							console.log(res.messageId)
							if (res.success) {
								this.$message.success('密码修改成功')
								Vue.ls.remove(ACCESS_TOKEN);
								this.$router.push({
									path: '/'
								})
							} else {
								if (res.messageId == 'BW_24') {
									this.$message.error('密码修改失败：旧密码错误')
									this.confirmLoading = false
								} else {
									this.$message.error('密码修改失败：' + res.message)
									this.confirmLoading = false
								}
							}
						}).error(() => {
							console.log("error")
							this.confirmLoading = false
						}).finally(() => {
							console.log("finally")
							this.confirmLoading = false
						})
					} else {
						console.log("error")
						this.confirmLoading = false
					}
				}).error(() => {
					this.confirmLoading = false
				}).finally(() => {
					this.confirmLoading = false
				})
			},
			handleCancel() {
				this.form.resetFields()
				this.visible = false
			}
		}
	}
</script>
