<template>
  <a-spin :spinning="confirmLoading">
    <a-anchor :affix="true" @click="handleAnchorClick" :showInkInFixed="false">
      <div class="anchor-card">
        <a-row :gutter="24" type="flex" justify="center">
          <a-col :span="3">
            <a-anchor-link href="Anchor_activity" title="活动信息" />
          </a-col>
          <a-col :span="3">
            <a-anchor-link href="Anchor_Basic" title="排名设定" />
          </a-col>
        </a-row>
      </div>
    </a-anchor>

    <a-form :form="form">
      <a-card id="Anchor_activity">

        <div slot="title" style="margin-left: 1.5rem;">
          <a-divider type="vertical" class="divider-tag" style="background-color:#0077DD;" />
          <span class="card-title">活动信息</span>
        </div>

        <a-form-item style="display: none;">
          <a-input v-decorator="['activityId']" />
        </a-form-item>

       <!-- <a-form-item label="预览图" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <img v-if="activity.previewUrl" :src="previewSrc(activity.previewUrl)" alt=""
            style="width: 30%;height: 30%;" />
        </a-form-item> -->

        <a-form-item label="活动标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
          {{ activity.title }}
        </a-form-item>

        <a-form-item label="获奖公布" :labelCol="labelCol" :wrapperCol="wrapperCol">
          {{ activity.publishDate }}
        </a-form-item>

      </a-card>

      <a-card id="Anchor_Basic" style="margin-top: 1.25rem;">

        <div slot="title" style="margin-left: 1.5rem;">
          <a-divider type="vertical" class="divider-tag" style="background-color:#ffaa00;" />
          <span style="font-size: 19px;font-weight: 900;">排名设定</span>
        </div>

        <a-row v-for=" (r,index) in ranks" :key="r.rewardRank">
          <a-col :span="23" :offset="2">
            <span style="font-size: 17px;font-weight: 600;height: 20px;line-height: 20px;">
              {{r.rewardRankName}}
            </span>
            <span v-if="!isSee" style="margin-left: 8px ;">
              <!-- 							<template v-if="r.rewardRank == 0 || r.rewardRank == 1 || r.rewardRank == 2 ">
								<a v-if="r.enrolls.length == 0" @click="searchEnroll(r,index)">
									设定
								</a>
							</template>
							<template v-else>
								<a @click="searchEnroll(r,index)">
									设定
								</a>
							</template> -->
              <a @click="searchEnroll(r,index)">
                设定
              </a>
            </span>
          </a-col>
          <a-col :span="20" :offset="2">
            <div style="padding: 5px 0;">
              <a-collapse accordion v-if="r.enrolls.length != 0">
                <a-collapse-panel v-for="(en, eni) in r.enrolls" :key="en.enrollId" :header="en.name">

                  <a-icon v-if="!isSee" slot="extra" type="delete" @click="removeEnroll(r, eni)" />

                  <a-descriptions title="报名信息" style="margin-top:20px;">

                    <a-descriptions-item label="参赛用户">
                      {{en.name}}
                    </a-descriptions-item>
                    <a-descriptions-item label="性别">
                      {{en.sexName}}
                    </a-descriptions-item>
                    <a-descriptions-item label="年龄">
                      {{en.ageName}}
                    </a-descriptions-item>

                    <a-descriptions-item label="手机">
                      {{en.phone}}
                    </a-descriptions-item>
                    <a-descriptions-item label="邮箱">
                      {{en.email}}
                    </a-descriptions-item>

                    <a-descriptions-item label="所在城市">
                      {{en.provinceName + '-' + en.cityName}}
                    </a-descriptions-item>

                    <a-descriptions-item label="票数" :span="3">
                      {{en.votesNum}}票
                    </a-descriptions-item>
                  </a-descriptions>

                  <a-descriptions title="作品" style="margin-top:10px;">
                    <a-descriptions-item :span="3">
                      <div class="clearfix">
                        <a-upload list-type="picture-card" :file-list="en.files"
                          :showUploadList="{showRemoveIcon:false}">
                        </a-upload>
                      </div>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </a-col>
          <a-divider style="margin-top: 5px;" />
        </a-row>

      </a-card>
    </a-form>

    <search-enroll ref="searchEnroll" @ok="chooseOk" />

    <div style="height: 60px;">
      <div class="r">
        <div v-if="isSee">
          <a-button @click="handleCancel">
            关闭
          </a-button>
        </div>
        <div v-else>
          <a-button type="primary" @click="handleSubmit">
            保存
          </a-button>

          <a-button @click="handleCancel" style="margin-left: 40px;">
            关闭
          </a-button>
        </div>
      </div>
    </div>

  </a-spin>
</template>

<script>
  import moment from 'moment'
  import {
    getRankByActivity,
    vRankInfoAdd,
    vRankInfoEdit
  } from '@/api/modular/business/rankManage'
  import {
    uploadFile
  } from '@/api/modular/system/fileManage'
  import searchEnroll from './searchEnroll'

  export default {
    components: {
      searchEnroll,
    },

    data() {
      return {
        // 表单layout
        labelCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 3
          }
        },
        wrapperCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 18
          }
        },
        // loading
        confirmLoading: false,
        // 关联参数
        relatedParam: {},
        // 表单
        form: this.$form.createForm(this),
        // 操作区分   true：编辑   false：新增
        isOperate: false,
        // 是否查看
        isSee: false,
        // 时间格式化
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        // 活动数据
        activity: {},
        // 奖励原型
        ranksPrototype: '',
        // 奖项设定集合
        ranks: [],

        // 操作记录
        operateRecord: {
          rank: {},
          index: '',
        },
      }
    },

    // 计算属性
    computed: {
      // 预览图全路径
      previewSrc() {
        return (val) => {
          return this.relatedParam.imgHttpPath + val;
        }
      },
    },

    methods: {
      // -> 功能业务
      // 编辑 || 查看 初始化方法
      operate(record, look) {
        // 是否查看
        this.isSee = look;
        this.activity = record;
        // //作品公布日
        // let publish = []
        // publish = record.publishDate.split(" ")
        // var str = publish[0];
        // var index = str.lastIndexOf("-");
        // str = str.substring(0, index);
        // record.publishDate = str
        this.ranks = JSON.parse(this.ranksPrototype);
        this.getRankByActivity();
        // 延时赋初值
        setTimeout(() => {
          this.form.setFieldsValue({
            activityId: record.activityId,
          })
        }, 100);
      },

      // 查看活动排名奖励列表
      getRankByActivity() {
        // 调用接口
        getRankByActivity({
          activityId: this.activity.activityId
        }).then((res) => {
          // 成功
          if (res.success) {

            this.ranks.forEach((r) => {
              // 提取作品
              let enrolls = res.data.filter((d) => {
                return d.rewardRank == r.rewardRank;
              })

              enrolls.forEach(en => {
                en.files = this.prepareDoneFile(en.name, en
                  .matchImgUrl)
              });

              // 赋值
              r.enrolls.push(...enrolls);
            });

            // 是否为编辑
            if (res.data.length != 0) {
              this.isOperate = true;
            } else {
              this.isOperate = false;
            }

          } else {
            this.$error({
              okText: '确定',
              title: '提示信息',
              content: "请求排名数据失败，原因：" + res.message
            });
          }
        }).catch((err) => {
          this.$error({
            okText: '确定',
            title: '提示信息',
            content: "请求排名数据失败，原因：" + err.response.data.message,
          });
        })
      },

      //ok方法  提交
      handleSubmit() {
        const {
          form: {
            validateFields
          }
        } = this;
        this.confirmLoading = true;

        // check奖项是否存在未设定
        let check = this.ranks.filter((r) => {
          return r.enrolls.length == 0;
        });

        // 存在未设定
        if (check.length) {
          this.$error({
            okText: '确定',
            title: '提示信息',
            content: "存在未设定的排名奖项，请检查确认！",
          });
          this.confirmLoading = false;
          return;
        }

        // 校验
        validateFields((errors, values) => {
          // 校验通过
          if (!errors) {
            // 数据处理
            let data = this.processData(values);

            // 编辑
            if (this.isOperate) {
              // 调用编辑接口 
              vRankInfoEdit({
                activityId: this.activity.activityId,
                rankEnrolls: data,
              }).then((res) => {

                // 返回成功
                if (res.success) {
                  this.$message.success('编辑成功');
                  this.$emit('ok', data);
                  // 重置数据
                  this.reset();

                  // 返回失败
                } else {
                  this.$warning({
                    okText: '确定',
                    title: '提示信息',
                    content: "编辑失败，原因：" + res.message
                  });
                }
                // 异常
              }).catch((err) => {
                this.$error({
                  okText: '确定',
                  title: '提示信息',
                  content: "编辑失败，原因：" + err.response.data.message
                });
                // finally
              }).finally(() => {
                this.confirmLoading = false;
              })

              // 设定
            } else {
              // 调用新增接口
              vRankInfoAdd({
                rankEnrolls: data,
              }).then((res) => {

                // 返回成功
                if (res.success) {
                  this.$message.success('设定成功');
                  this.$emit('ok', data);
                  // 重置数据
                  this.reset();
                  // 返回失败
                } else {
                  this.$warning({
                    okText: '确定',
                    title: '提示信息',
                    content: "设定失败，原因：" + res.message
                  });
                }
                // 异常
              }).catch((err) => {
                this.$error({
                  okText: '确定',
                  title: '提示信息',
                  content: "设定失败，原因：" + err.response.data.message
                });
                // finally
              }).finally(() => {
                this.confirmLoading = false;
              })
            }

            // 校验不通过
          } else {
            this.confirmLoading = false;
          }
        })
      },

      // 处理数据
      processData(values) {
        // 声明  时间为必填项  无需特殊判断
        let data = [];

        // 遍历处理
        this.ranks.forEach((r) => {
          r.enrolls.forEach(en => {
            data.push({
              ...values,
              ...en,
              ...r,
            })
          })
        })

        // 返回
        return data;
      },

      // --->作品相关
      // 选择作品
      searchEnroll(rank, index) {
        // 操作记录
        this.operateRecord = {
          rank: rank,
          index: index,
        }
        // 展开窗口
        this.$refs['searchEnroll'].show(this.activity, rank, this.relatedParam.imgHttpPath)
      },

      // 完成选择
      chooseOk(receipt) {
        // 查重
        let check = this.operateRecord.rank.enrolls.find((item) => {
          return item.enrollId == receipt.enrollId;
        });

        // 已存在
        if (check) {
          this.$message.info('当前奖项中已存在该作品！');
        } else {
          this.operateRecord.rank.enrolls.push(receipt);
        }
      },

      // 移除作品
      removeEnroll(rank, enrollIndex) {
        rank.enrolls.splice(enrollIndex, 1);
      },

      /**
       * 准备渲染上传文件
       * @param  attribute 文件列表(files) 的属性名
       * @param  fileSrc  渲染数据的src
       */
      prepareDoneFile(attribute, fileSrc) {
        // 结果值
        let res = [];

        // JSON转换
        let fileNameStr = JSON.parse(JSON.stringify(fileSrc));
        // 拆分
        let fileNames = fileNameStr.split(";");

        // 遍历处理
        fileNames.forEach((item) => {
          // 默认名称
          let name = JSON.parse(JSON.stringify(item));
          // 是否存在文件夹
          let index = name.lastIndexOf("/")
          // 存在
          if (index != -1) {
            name = name.substring(index + 1);
          }

          // 添加完成文件
          res.push({
            uid: name,
            name: name,
            status: 'done',
            url: this.relatedParam.imgHttpPath + item,
            other: attribute
          })
        })

        // 返回
        return res;
      },

      // ---> 基础功能
      // 关联参数初始化
      initRelatedParam(relatedParam) {
        this.relatedParam = relatedParam;
        this.confirmLoading = false;

        // 奖项设定集合
        let ranksPrototype = [];
        // 遍历生成奖项设定集合
        relatedParam.rewardRanks.forEach((item) => {
          ranksPrototype.push({
            'rewardRank': item.dictCode,
            'rewardRankName': item.dictValue,
            'rankIcon': item.memo,
            'enrolls': [],
          })
        });

        // 保存原型
        this.ranksPrototype = JSON.stringify(ranksPrototype);
      },

      // 取消、关闭Modal
      handleCancel() {
        this.$emit('no', {});
        this.confirmLoading = false;
        this.reset();
      },

      // 重置数据
      reset() {
        // 表单
        this.form.resetFields();
        // 活动
        this.activity = {};
        this.ranks = [];
        // 操作记录
        this.operateRecord = {
          rank: {},
          index: '',
        };
        // 标志
        this.isOperate = false;
        this.isSee = false;
      },

      // 快速跳转
      handleAnchorClick(e, link) {
        // 阻止点击的默认事件修改路由
        e.preventDefault();
        var srcolls = document.getElementById(link.href);
        srcolls.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      },

    }
  }
</script>

<style scoped lang="less">
  /deep/.r {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 70px;
    background-color: #ededed;
    left: 125px;
    text-align: center;
    padding: 20px 0;
    z-index: 99;
  }

  /deep/.bottom {
    width: 30%;
    height: 37px;
    font-size: 16px;
    font-weight: 700;
    position: fixed;
    bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    left: 40%;
    z-index: 99;
  }

  /deep/.ant-card-head {
    border-bottom: none;
  }

  /deep/.divider-tag {
    width: 0.3rem;
    height: 1rem;
  }

  /deep/.ant-anchor-link-title {
    font-size: 17px;
    font-weight: 900;
  }

  /deep/.card-title {
    font-size: 19px;
    font-weight: 900;
  }

  /deep/.ant-card-body {
    .ant-table-body {
      min-height: 0.0625rem;
    }

    .ant-table-placeholder {
      margin-top: 0;
      border: 1px solid #e8e8e8
    }
  }

  /deep/.ant-anchor-wrapper {
    padding: 0.2rem;

    .anchor-card {
      text-align: center;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      padding: 0.625rem;
      font-size: 1rem;
    }

    .ant-anchor-ink {
      height: 0;
    }

    .ant-anchor-ink-ball.visible {
      display: none;
    }
  }
</style>
