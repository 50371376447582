import {
	axios
} from '@/utils/request'

/**
 * 上传文件
 *
 * @author ymsc
 * @date 2021/10/18 17:00
 */
export function uploadFile(data) {
	return axios({
		url: '/file/uploadFile',
		method: 'post',
		data: data
	})
}

/**
 * 图片下载
 *
 * @author ymsc
 * @date 2020/7/02 01:46
 */
export function downloadZip(parameter) {
	return axios({
		url: '/file/downloadZip',
		method: 'post',
		params: parameter,
    responseType:'blob'
	})
}
