<template>
	<div>
		<h1>登录错误：{{msg}} </h1>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				msg: '用户名或密码错误'
			}
		}
	}
</script>
