<template>
  <a-modal title="选择作品" :width="900" :visible="visible" :confirmLoading="confirmLoading" :maskClosable="false"
    okText="选择" @ok="handleSubmit" cancelText="关闭" @cancel="handleCancel">
    <a-spin :spinning="confirmLoading">

      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :sm="24" :md="8">
              <a-form-item label="姓名">
                <a-input v-model="queryParam.enrollName" placeholder="请输入姓名" :max-length="50" allow-clear />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item label="手机号">
                <a-input v-model="queryParam.phone" placeholder="请输入手机号" :max-length="50" allow-clear />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8" style="text-align: right;">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="searchEnroll">查询</a-button>
                <a-button style="margin-left: 8px" @click="() => queryParam = {'activityId': activity.activityId}">
                  重置
                </a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div v-if="enrolls.length != 0">
        <span>查看作品：</span>
        <span>
          <a-select placeholder="请选择作品" v-model="selectEnrollId" @change="selectEnroll" style="min-width: 12.5rem">
            <a-select-option v-for="(item) in enrolls" :key="item.enrollId" :value="item.enrollId">
              {{item.name}}
            </a-select-option>
          </a-select>
        </span>
      </div>

      <template v-if="showEnroll">
        <a-descriptions title="报名信息" style="margin-top:20px;">

          <a-descriptions-item label="参赛用户">
            {{data.name}}
          </a-descriptions-item>
          <a-descriptions-item label="性别">
            {{data.sexName}}
          </a-descriptions-item>
          <a-descriptions-item label="年龄">
            {{data.ageName}}
          </a-descriptions-item>

          <a-descriptions-item label="手机">
            {{data.phone}}
          </a-descriptions-item>
          <a-descriptions-item label="邮箱">
            {{data.email}}
          </a-descriptions-item>

          <a-descriptions-item label="所在城市">
            {{data.provinceName + '-' + data.cityName}}
          </a-descriptions-item>


          <a-descriptions-item label="票数" :span="3">
            {{data.votesNum}}票
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions title="作品" style="margin-top:10px;">
          <a-descriptions-item :span="3">
            <div class="clearfix">
              <a-upload list-type="picture-card" :file-list="data.files" @preview="handlePreview"
                :showUploadList="{showRemoveIcon:false}">
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCanceImg">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </a-descriptions-item>
        </a-descriptions>
      </template>
    </a-spin>
  </a-modal>
</template>

<script>
  import {
    searchEnroll
  } from '@/api/modular/business/rankManage';

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  export default {
    data() {
      return {
        visible: false,
        confirmLoading: false,
        advanced: false,

        activity: {},
        // 查询参数
        queryParam: {
          'activityId': '',
        },

        // 选中作品
        selectEnrollId: undefined,
        // 展示作品
        showEnroll: false,
        // 预览图展示
        previewVisible: false,
        // 预览图
        previewImage: '',

        // 图片请求地址
        imgHttpPath: '',
        // 排名
        rank: {},
        // 符合条件作品集合
        enrolls: [],
        // 结果数据
        data: {},
      }
    },

    methods: {
      // -> 功能业务
      // 初始化方法
      show(activity, rank, imgHttpPath) {
        this.visible = true;
        this.queryParam = { activityId: activity.activityId };
        this.activity = activity;
        this.rank = rank;
        this.imgHttpPath = imgHttpPath;
      },

      // 查询作品
      searchEnroll() {
        // 查找作品
        searchEnroll(this.queryParam).then((res) => {
          // 返回成功
          if (res.success) {
            this.selectEnrollId = undefined;
            this.data = {};
            this.showEnroll = false;
            // 作品
            this.enrolls = res.data;

            // 返回失败
          } else {
            this.$warning({
              okText: '确定',
              title: '提示信息',
              content: "查找失败，原因：" + res.message
            });
          }
          // 异常
        }).catch((err) => {
          this.$error({
            okText: '确定',
            title: '提示信息',
            content: "查找失败，原因：" + err.response.data.message
          });
          // finally
        }).finally(() => {
          this.confirmLoading = false;
        })
      },

      //ok方法  提交
      handleSubmit() {
        if (!this.data.enrollId || !this.showEnroll) {
          console.log(this.data.enrollId);
          this.$info({
            title: '提示',
            content: "请选择设定作品",
          });
          return;
        }

        this.$emit('ok', this.data);
        this.handleCancel()
      },

      // 查看作品
      selectEnroll(key) {
        this.data = this.enrolls.find((item) => {
          return item.enrollId == key
        });

        this.selectEnrollId = this.data.enrollId;
        this.data.files = this.prepareDoneFile(this.data.name, this.data.matchImgUrl)
        this.showEnroll = true;
      },


      // 预览
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }

        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      },

      //关闭图片
      handleCanceImg() {
        this.previewVisible = false;
      },


      // -> 基础功能
      // 取消、关闭Modal
      handleCancel() {
        this.visible = false;
        this.confirmLoading = false;
        this.reset();
      },

      // 重置搜索
      resetSearch(){
        this.queryParam = {
          'activityId': '',
        };
      },

      // 重置
      reset() {
        this.queryParam = {
          'activityId': '',
        };
        this.activity = {};
        this.rank = {};
        this.imgHttpPath = {};
        this.showEnroll = false;
        this.previewVisible = false;
        this.previewImage = '';
        this.enrolls = [];
        this.data = {};
      },

      /**
       * 准备渲染上传文件
       * @param  attribute 文件列表(files) 的属性名
       * @param  fileSrc  渲染数据的src
       */
      prepareDoneFile(attribute, fileSrc) {
        // 结果值
        let res = [];

        // JSON转换
        let fileNameStr = JSON.parse(JSON.stringify(fileSrc));
        // 拆分
        let fileNames = fileNameStr.split(";");

        // 遍历处理
        fileNames.forEach((item) => {
          // 默认名称
          let name = JSON.parse(JSON.stringify(item));
          // 是否存在文件夹
          let index = name.lastIndexOf("/")
          // 存在
          if (index != -1) {
            name = name.substring(index + 1);
          }

          // 添加完成文件
          res.push({
            uid: name,
            name: name,
            status: 'done',
            url: this.imgHttpPath + item,
            other: attribute
          })
        })

        // 返回
        return res;
      },

    }
  }
</script>

<style scoped lang="less">
  /deep/.ant-modal-footer {
    text-align: center;
  }
</style>
