<template>
	<a-card :bordered="false">
		<div class="table-page-search-wrapper">
			<a-form layout="inline">
				<a-row :gutter="48">
					<a-col :md="8" :sm="24">
						<a-form-item label="菜单编码">
							<a-input v-model="queryParam.menuCode" allow-clear placeholder="请输入菜单编码" />
						</a-form-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-item label="菜单名称">
							<a-input v-model="queryParam.name" allow-clear placeholder="请输入菜单名称" />
						</a-form-item>
					</a-col>
				</a-row>
			</a-form>
		</div>

		<div class="table-operator">
			<a-row>
				<a-col :md="24" :sm="24" style="text-align: right;">
					<span class="table-page-search-submitButtons"
						:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
						<a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
						<a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
					</span>
				</a-col>
			</a-row>
		</div>

		<s-table ref="table" bordered size="default" :columns="columns" :data="loadData" :alert="false" :scroll="scroll"
			rowKey="menuCode" :customRow="selectRow" :rowClassName="rowClassName" :style="operaHigh">

			<span slot="action" slot-scope="text, record">
				<a @click="$refs.editForm.edit(record)">编辑</a>
			</span>

		</s-table>

		<edit-form ref="editForm" @ok="handleOk" />
	</a-card>
</template>

<script>
	import {
		STable
	} from '@/components'
	import editForm from './editForm'
	import {
		getMenuPage
	} from '@/api/modular/system/menuManage.js'

	export default {
		components: {
			STable,
			editForm,
		},

		data() {
			return {
				// // 高级搜索 展开/关闭
				advanced: true,
				//行选中Id
				selectRowId: '',
				// 查询参数
				queryParam: {},
				// 检索参数区相关
				queryRows: {
					count: 2,
					current: 1
				},
				//表格scroll
				scroll: {
					x: true,
					y: 397,
				},

				// 表头
				columns: [{
						title: '序号',
						dataIndex: 'no',
						width: 95,
						align: 'center'
					},
					{
						title: '菜单名',
						dataIndex: 'name',
						align: 'center',
						width: 110,
					},
					{
						title: '菜单编码',
						dataIndex: 'menuCode',
						width: 150,
					},
					{
						title: '父菜单编码',
						dataIndex: 'parentMenuCode',
						width: 150,
					},
					{
						title: '级别',
						dataIndex: 'level',
						align: 'center',
						width: 75,
					},
					{
						title: '路径',
						dataIndex: 'path',
						width: 150,
					},
					{
						title: '菜单地址',
						dataIndex: 'url',
						width: 150,
					},
					{
						title: '描述',
						dataIndex: 'memo',
						align: 'center',
						width: 100,
					},
					{
						title: '排序',
						dataIndex: 'sort',
						align: 'center',
						width: 90,
					},
					{
						title: '操作',
						width: 95,
						dataIndex: 'action',
						align: 'center',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],

				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					let that = this;
					return getMenuPage(Object.assign(parameter, this.queryParam)).then((res) => {
						let pageNo = res.data.pageNo;
						let pageSize = res.data.pageSize;
						res.data.rows.forEach((item, index) => {
							item.no = (pageNo - 1) * pageSize + index + 1;
						});
						return res.data
					})
				},
			}
		},

		created() {},

		// 计算属性
		computed: {
			// 表格头部功能区高度
			operaHigh() {
				return {
					"--operaHigh": (this.queryRows.current * 56 + 268) + 'px',
				}
			},
		},

		methods: {

			handleClick(e) {
				console.log(e.toString())
				this.$refs.table.refresh(true)
			},

			handleOk() {
				this.$refs.table.refresh()
			},

			reloadTable() {
				this.$refs.table.refresh(true);
			},

			selectRow(record) {
				return {
					props: {},
					on: {
						click: () => {
							this.selectRowId = record.menuCode
						},
					}
				}
			},

			// 点击行变色 class
			rowClassName(record) {
				return record.menuCode === this.selectRowId ? 'selectRow' : '';
			},
		}
	}
</script>

<style scoped lang="less">
</style>
