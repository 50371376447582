<template>
  <a-spin :spinning="spinning">
    <a-card :bordered="false" :confirmLoading="confirmLoading">

      <transition name="trans">
        <div v-show="!visible">
          <detailed-form ref="detailedForm" @ok="handleOk" @no="detailedClick" />
        </div>
      </transition>

      <transition name="trans">
        <div v-show="visible">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :sm="24" :md="6">
                  <a-form-item label="活动标题">
                    <a-select v-model="queryParam.activityId" style="width:100%;" allow-clear placeholder="请选择活动标题"
                      default-value="">
                      <a-select-option v-for="(item) in relatedParam.titleList" :key="item.activityId"
                        :value="item.activityId">
                        {{item.title}}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :sm="24" :md="6">
                  <a-form-item label="参赛用户">
                    <a-input :max-length="11" v-model="queryParam.name" allow-clear placeholder="请输入参赛用户" />
                  </a-form-item>
                </a-col>
                <a-col :sm="24" :md="6">
                  <a-form-item label="审核状态">
                    <a-select v-model="queryParam.verifyState" style="width:100%;" allow-clear placeholder="请选择审核状态"
                      default-value="">
                      <a-select-option v-for="(item) in relatedParam.verifyStateList" :key="item.dictCode"
                        :value="item.dictCode">
                        {{item.dictValue}}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :sm="24" :md="6">
                  <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                    <a-button type="primary" @click="onSearch">查询</a-button>
                    <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 按钮 -->
          <div class="table-operator">
            <a-row>
              <a-col :md="17" :sm="24">
                <a-button type="primary" icon="download" @click="Excel()">报名信息导出</a-button>
                <a-button icon="download" @click="downloadAll()">作品批量下载</a-button>
                <a :href="zipUrl" v-show="showZipUrl" target="_blank">下载ZIP</a>
              </a-col>
            </a-row>
          </div>
          <!-- 表格 -->
          <s-table bordered ref="table" size="default" :columns="columns" :data="loadData" :alert="false"
            :scroll="scroll" rowKey="enrollId" :customRow="selectRow" :rowClassName="rowClassName" :style="operaHigh">

            <!-- 头像 -->
            <span slot="headImage" slot-scope="text">
              <a :href="text" target="_blank" v-if="text">
                <img :src="text" alt="" style="width: 50px;height: 50px;" />
              </a>
            </span>

            <span slot="action" slot-scope="text, record">
              <a @click="detailedClick(record)">详细</a>
              <a-divider type="vertical" />
              <a @click="download(record)">作品下载</a>
              <!-- <a-divider type="vertical" />
              <a @click="$refs.voteForm.vote(record)">投票查看</a>
              <a-divider type="vertical" />
              <a @click="voteExcel(record)">投票导出</a> -->
            </span>
            <span slot="signDate" slot-scope="text, record">
              <span>{{record.signStartDate}}</span>
              <span> ~ </span>
              <span>{{record.signEndDate}}</span>
            </span>

          </s-table>
        </div>
      </transition>
      <vote-form ref="voteForm" @ok="handleOk" />
    </a-card>
  </a-spin>
</template>

<script>
  import {
    STable
  } from '@/components'
  import {
    initRelatedParam,
    vEnrollInfoPage,
    vEnrollInfoDelete,
    downloadZip,
    exportExcel,
    downloadImages,
    exportVoteExcel
  } from '@/api/modular/business/vEnrollInfoManage'

  import voteForm from './voteForm'
  import detailedForm from './detailedForm'
  import table2excel from 'js-table2excel'
  export default {
    components: {
      STable,
      voteForm,
      detailedForm
    },

    data() {
      return {
        spinning: false,
        visible: true,
        // 高级搜索 false: 默认收取 true: 默认关闭
        advanced: true,
        //行选中Id
        selectRowId: '',
        //关联参数
        relatedParam: {},
        // 查询参数
        queryParam: {},
        // 检索参数区相关
        queryRows: {
          count: 2,
          current: 1
        },

        // 时间格式化
        dateFormat: 'YYYY-MM-DD',

        //表格scroll
        scroll: {
          x: true,
          y: 397,
        },
        // 表头
        columns: [{
          title: '序号',
          dataIndex: 'no',
          align: 'center',
          width: 60,
        },
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          align: 'center',
          width: 150,
        },
        {
          dataIndex: 'headImage',
          title: '头像',
          align: 'center',
          width: 95,
          scopedSlots: {
            customRender: 'headImage'
          }
        },
        {
          title: '微信登录省',
          dataIndex: 'wechatProvince',
          align: 'center',
          width: 150,
        },
        {
          title: '微信登录市',
          dataIndex: 'wechatCity',
          align: 'center',
          width: 150,
        },
        {
          title: '活动标题',
          dataIndex: 'title',
          align: 'center',
          width: 250,
        },
        {
          title: '参赛用户',
          dataIndex: 'name',
          align: 'center',
          width: 140,
        },
        {
          title: '电话号',
          dataIndex: 'phone',
          align: 'center',
          width: 140,
        },
        {
          title: '所在城市',
          dataIndex: 'cityName',
          align: 'center',
          width: 140,
        },
        {
          title: '报名时间',
          dataIndex: 'createdDatetime',
          align: 'center',
          width: 140,
        },
        {
          title: '审核状态',
          dataIndex: 'verifyStateName',
          align: 'center',
          width: 140,
        },
        {
          title: '投票数',
          dataIndex: 'votesNum',
          align: 'center',
          width: 140,
        },
        {
          title: '操作',
          width: 240,
          dataIndex: 'action',
          align: 'center',
          scopedSlots: {
            customRender: 'action'
          }
        }
        ],
        // 加载数据方法 必须为 Promise 对象
        loadData: parameter => {
          return vEnrollInfoPage(Object.assign(parameter, this.queryParam)).then((res) => {
            res.data.rows.forEach((item, index) => {
              item.no = (res.data.pageNo - 1) * res.data.pageSize + index + 1;
            });
            return res.data;
          })
        },
        selectedRowKeys: [],
        selectedRows: [],
        confirmLoading: false,
        showZipUrl: false,
        zipUrl: '',
      }
    },

    created() {
      this.initRelatedParam();
    },

    // 计算属性
    computed: {
      // 表格头部功能区高度
      operaHigh() {
        return {
          "--operaHigh": (this.queryRows.current * 56 + 328) + 'px',
        }
      },
    },

    methods: {

      // -> 功能业务
      // 初始化关联参数
      initRelatedParam() {
        initRelatedParam({}).then((res) => {
          // 成功
          if (res.success) {
            // 关联参数取得
            this.relatedParam = res.data;
            // 初始化子组件关联参数
            this.$refs.addForm.initRelatedParam(res.data);
            this.$refs.editForm.initRelatedParam(res.data);
          } else {
            this.$error({
              okText: '确定',
              title: '提示信息',
              content: "请求关联参数失败，原因：" + res.message
            });
          }
        }).catch((err) => {
          this.$error({
            okText: '确定',
            title: '提示信息',
            content: "请求关联参数失败，原因：" + err.response.data.message,
          });
        })
      },

      // 查询
      onSearch() {

        // 征集时间
        if (this.queryParam['signDate']) {
          this.queryParam.signStartDate = this.queryParam['signDate'].at(0);
          this.queryParam.signEndDate = this.queryParam['signDate'].at(1);
        }

        // 查询
        this.$refs.table.refresh(true);
      },

      // 点击行变色 rowKey
      selectRow(record) {
        return {
          props: {},
          on: {
            click: () => {
              this.selectRowId = record.enrollId
            },
          }
        }
      },

      //详细跳转
      detailedClick(record) {
        this.visible = !this.visible;
        if (!this.visible) {
          setTimeout(() => {
            this.$refs.detailedForm.detailed(record, this.relatedParam.imgPath);
          }, 100)
        }
      },

      //报名时间
      onChange() {

      },

      //Excel导出
      Excel(record) {
        this.spinning = true;
        // exportExcel(this.queryParam).then((res) => {
        //   let excelName = '赛事报名信息'
        //   table2excel(this.excelColumn, res.data, excelName)
        //   this.spinning = false;
        // })
        exportExcel(this.queryParam).then((res) => {
          let link = document.createElement('a');

          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          let url = window.URL || window.webkitURL || window.moxURL;

          link.href = url.createObjectURL(blob);
          link.style.dispaly = 'none';

          //导出名称
          link.setAttribute('download', "赛事报名信息.xlsx");

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          url.revokeObjectURL
          url.revokeObjectURL(link.href);
          this.spinning = false;
        })
      },

      //Excel导出
      voteExcel(record) {
        this.spinning = true;
        let param = {}
        param.enrollId = record.enrollId
        exportVoteExcel(param).then((res) => {
          let link = document.createElement('a');

          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          let url = window.URL || window.webkitURL || window.moxURL;

          link.href = url.createObjectURL(blob);
          link.style.dispaly = 'none';

          //导出名称
          link.setAttribute('download', record.name + "的投票结果导出.xlsx");

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          url.revokeObjectURL
          url.revokeObjectURL(link.href);
          this.spinning = false;
        })
      },

      //图片下载
      download(record) {
        this.spinning = true;
        downloadZip({
          enrollId: record.enrollId
        }).then((res) => {
          // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content], {
            type: "application/zip"
          });
          const fileName = record.name + ".zip";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
          this.spinning = false;
        })
      },

      //批量图片下载
      downloadAll(record) {
        this.spinning = true;
        let param = {}
        downloadImages(param).then((res) => {
          this.spinning = false;
          if (res.success) {
            this.zipUrl = res.data;
            this.showZipUrl = true;

            this.$message.success('批量处理压缩图片完成，请您点击【下载ZIP】链接进行下载。');
          } else {
            this.showZipUrl = false;
            this.$message.success('批量处理压缩图片失败。');
          }
        })

      },

      // 点击行变色 class
      rowClassName(record) {
        return record.id === this.selectRowId ? 'selectRow' : '';
      },

      //回执
      handleOk() {
        this.visible = !this.visible;
        this.$refs.table.refresh();
      },

    }
  }
</script>

<style scoped lang="less">
</style>