<template>
	<div class="login-wrapper">
		<div style="height: 20%;">
			<div class="font-size-38 blue-font pd-tp-50">
				骊住管理端
			</div>
			<div class="font-size-16 blue-font pd-tp-10" style="padding: 10px 0 10px 100px;">

			</div><br />
			<div id="login" class="imageClass" style="width: 100%; margin: 0px auto;padding: 48px;">
				<div id="login" style="width: 300px; margin: 0px auto;margin-right: 5%;">

					<a-form :form="form" id="formLogin" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }"
						ref="formLogin" @submit="handleSubmit" style="background-color: #fff;border-radius: 10px;">

						<div class="layui-card-header blue-font"
							style="font-size: 20px; margin-top: 20px; font-weight: bold;">
							用户登录
						</div>
						<a-alert v-if="isLoginError" type="error" message="用户名或密码错误"></a-alert>

						<a-form-item label="用户名" style="padding-top: 20px;">
							<a-input ref="inputAccount" type="text" placeholder="请输入用户名" v-model="account" />
							<a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
						</a-form-item>
						<a-form-item label="密码">
							<a-input type="password" placeholder="请输入密码" v-model="password" />
							<a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
						</a-form-item>
						<a-form-item :wrapper-col="{ span: 12, offset: 1 }" style="width: 92%;padding-bottom: 5%;">
							<a-button type="primary" html-type="submit" class="login-button"
								style="width: 277px;background-color: 1253b5;">登录</a-button>
						</a-form-item>

					</a-form>
				</div>
			</div>
			<div style="text-align: center;padding: 20px;">Copyright ©2021 Inc. All 公司 版权所有</div>
		</div>
	</div>
</template>
<script>
	import {
		login
	} from '@/api/login'
	import Vue from 'vue'
	import {
		ACCESS_TOKEN
	} from '@/store/mutation-types'

	export default {
		name: 'login',
		data() {
			return {
				form: this.$form.createForm(this),
				isLoginError: false,
				account: '',
				password: ''
			}
		},
		mounted() {
			this.$refs.inputAccount.focus()
		},

		methods: {
			handleSubmit(e) {
				e.preventDefault()
				this.form.validateFields((err) => {
					if (!err) {
						Vue.ls.remove(ACCESS_TOKEN)
						login({
							account: this.account,
							password: this.password
						}).then((res) => {
							if (res.success === true) {
								const token = res.data.token;
								Vue.ls.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000);

								let user = res.data.user;
								localStorage.setItem("userId", user.userId);
								localStorage.setItem("roleId", user.roleId);
								localStorage.setItem("userName", user.userName);
								localStorage.setItem("token", token);
								// 路由跳转主页
								this.$router.push({
									path: 'main/home'
								})
							} else {
								this.$message.error(res.data)
							}
						}).catch(() => {
							this.$message.error('用户名或密码错误')
						})
					} else {
						this.$message.success('表单验证不成功')
					}
				})
			}
		}
	}
</script>
<style lang="less">
	.imageClass {
		height: 60%;
		background: url('../img/login.png');
		background-position: center;
	}

	.layui-card-header {
		font-weight: 700;
		font-size: 15px;
		padding: 10px 15px;
		margin-bottom: 0px;
		border-bottom: #8DA6C5 2px solid;
	}

	.font-size-38 {
		font-size: 38px;
		font-weight: bold;
	}

	.blue-font {
		color: #1253b5;
		font-family: 'Microsoft YaHei UI';
	}

	.pd-tp-10 {
		padding: 10px 0 30px 100px;
	}

	@media screen and (min-width: 1900px) {
		.pd-tp-50 {
			padding: 50px 0 0 100px;
		}
	}

	@media screen and (max-width: 1890px) {
		.pd-tp-50 {
			padding: 20px 0 0 100px;
		}

		.login-img {
			width: 100%;
			height: 576px;
		}
	}
</style>
