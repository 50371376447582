<template>
	<a-modal title="编辑字典" :width="900" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" okText="修改" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">

				<a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input v-decorator="['id']" />
				</a-form-item>

				<a-form-item label="字典类型" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :max-length="50" placeholder="请输入字典类型" disabled
						v-decorator="['dictType', {rules: [{required: true, message: '请输入字典类型！'}]}]" />
				</a-form-item>

				<a-form-item label="字典编码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :max-length="50" placeholder="请输入字典编码" disabled
						v-decorator="['dictCode', {rules: [{required: true, message: '请输入字典编码！'}]}]" />
				</a-form-item>

				<a-form-item label="字典值" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :max-length="200" placeholder="请输入字典值" style="width: 100%;"
						v-decorator="['dictValue', {rules: [{required: true, message: '请输入字典值！'}]}]" />
				</a-form-item>

				<a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-textarea :max-length="500" :rows="4" placeholder="请输入备注" v-decorator="['memo']"></a-textarea>
				</a-form-item>

			</a-form>

		</a-spin>
	</a-modal>
</template>

<script>
	import {
		sysDictTypeEdit
	} from '@/api/modular/system/dictManage'

	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this)
			}
		},

		methods: {
			// 初始化方法
			edit(record) {
				this.visible = true
				setTimeout(() => {
					this.form.setFieldsValue({
						id: record.id,
						dictType: record.dictType,
						dictCode: record.dictCode,
						dictValue: record.dictValue,
						memo: record.memo
					})
				}, 100)
			},

			// 提交
			handleSubmit() {
				const {
					form: {
						validateFields
					}
				} = this
				this.confirmLoading = true
				validateFields((errors, values) => {
					//判定万元四舍五入保留位数
					if (values.dictType == "RESERVED_DIGITS" && values.dictCode == "DIGIT") {
						let r = /^[1-9]+[0-9]*]*$/;
						//是否为整型数据
						if (!r.test(values.dictValue)) {
							this.confirmLoading = false;
							this.$warning({
								title: '提示',
								content: '只能填写正整数！',
								okText: '确定',
							});
							return;
						}
					}
					//校验通过
					if (!errors) {
						sysDictTypeEdit(values).then((res) => {
							if (res.success) {
								this.$message.success('编辑成功')
								this.visible = false
								this.confirmLoading = false
								this.$emit('ok', values)
								this.form.resetFields()
							} else {
								this.$message.error('编辑失败：' + res.message)
								this.confirmLoading = false
							}
						}).error(() => {
							this.confirmLoading = false
						}).finally(() => {
							this.confirmLoading = false
						})
					} else {
						this.confirmLoading = false
					}
				})
			},

			// 取消、关闭窗口
			handleCancel() {
				this.form.resetFields()
				this.visible = false;
				this.confirmLoading = false
			}
		}
	}
</script>
