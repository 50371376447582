export default {

	formatAmount(money, n) {
		money = money.toString().trim();
		if (money === "") {
			return "";
		}
		if (isNaN(money)) {
			return "";
		}
		n = n >= 0 && n <= 20 ? n : 2;
		money = parseFloat((money + "").replace(/[^\d.-]/g, "")).toFixed(n) + "";
		let l = money.split(".")[0].split("").reverse(),
			r = money.split(".")[1];
		let t = "";
		for (let i = 0; i < l.length; i++) {
			t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
		}
		if (n === 0) {
			return t.split("").reverse().join("").replace("-,", "-");
		} else {
			return (t.split("").reverse().join("") + "." + r).replace("-,", "-");
		}
	},

	replaceComma(value) {
		return value.toString().replace(/,/g, '');
	},

	add(a, b) {
		let c, d, e;
		try {
			c = a.toString().split(".")[1].length; // 获取a的小数位长度
		} catch (f) {
			c = 0;
		}
		try {
			d = b.toString().split(".")[1].length; // 获取b的小数位长度
		} catch (f) {
			d = 0;
		}
		//先求e，把a、b 同时乘以e转换成整数相加，再除以e还原
		return e = Math.pow(10, Math.max(c, d)), (this.mul(a, e) + this.mul(b, e)) / e;
	},

	// mul(a, b) {
	// 	let c = 0,
	// 		d = a.toString(),         // 转换为字符串
	// 		e = b.toString();         // ...
	// 	try {
	// 		c += d.split(".")[1].length;      // c 累加a的小数位长度
	// 	} catch (f) {}
	// 	try {
	// 		c += e.split(".")[1].length;      // c 累加b的小数位长度
	// 	} catch (f) {}
	// 	// 转换为整数相乘，再除以10^c ,移动小数点，还原，利用整数相乘不会丢失精度
	// 	return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
	// }

}
