<template>
	<div class="user-wrapper">
		<div class="content-box">
			<a-dropdown>
				<span class="action ant-dropdown-link user-dropdown-menu trigger">
					<span :style="{'color':''+headerColor=='black'?'#ffffffA6':'black'}">{{ userName }}</span>
				</span>
				<a-menu slot="overlay" class="user-dropdown-menu-wrapper">
					<!-- <a-menu-item key="0">
						<router-link :to="{ name: 'center' }">
							<a-icon type="user" />
							<span>个人中心</span>
						</router-link>
					</a-menu-item> -->
					<!-- 	<a-menu-item key="1">
						<router-link :to="{ name: 'settings' }">
							<a-icon type="setting" />
							<span>账户设置</span>
						</router-link>
					</a-menu-item> -->
					<a-menu-item key="2">
						<a href="javascript:;" @click="$refs.addForm.add()">
							<a-icon type="pass" />
							<span>密码修改</span>
						</a>
					</a-menu-item>
					<a-menu-divider />
					<a-menu-item key="3">
						<a href="javascript:;" @click="handleLogout">
							<a-icon type="logout" />
							<span>退出登录</span>
						</a>
					</a-menu-item>
				</a-menu>
			</a-dropdown>
			<a-icon type="setting" class="trigger" title="设置" @click="$refs.settingDrawer.toggle()"></a-icon>
			<setting-drawer ref="settingDrawer" class="header-index-right"></setting-drawer>
		</div>

		<add-form ref="addForm" />
	</div>
</template>

<script>
	import {
		mixin
	} from '@/utils/mixin'
	import {
		logout
	} from '@/api/main'
	import Vue from 'vue'
	import {
		ACCESS_TOKEN
	} from '@/store/mutation-types'
	import addForm from './addForm'
	import settingDrawer from '../SettingDrawer'

	export default {
		mixins: [mixin],
		name: 'UserMenu',
		props: {
			mode: {
				type: String,
				// sidemenu, topmenu
				default: 'sidemenu'
			}
		},
		data() {
			return {
				nickname: "超級管理員",
				userName: "",
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 16
					}
				},
				visible: false,
				confirmLoading: false,
				form1: this.$form.createForm(this),
				defApp: [],
			}
		},
		components: {
			addForm,
			settingDrawer,
		},
		computed: {},
		created() {
			var level = localStorage.getItem("level")
			this.userName = localStorage.getItem("userName")
			if (level == 0) {
				this.nickname = '系统管理员'
			} else if (level == 1) {
				this.nickname = '商户'
			} else if (level == 2) {
				this.nickname = '综合体'
			} else if (level == 3) {
				this.nickname = '区管理'
			} else if (level == 4) {
				this.nickname = '市管理'
			}
		},
		methods: {
			handleLogout() {
				this.$confirm({
					title: '提示',
					content: '真的要注销登录吗 ?',
					okText: '确定',
					cancelText: '取消',
					onOk: () => {
						return logout({}).then(() => {
							setTimeout(() => {
								Vue.ls.remove(ACCESS_TOKEN);
								localStorage.clear();
								this.$router.push({
									path: '/'
								})
							}, 16)
						}).catch(err => {
							this.$message.error({
								title: '错误',
								description: err.message
							})
						})
					},
					onCancel() {}
				})
			},

			handleCancel() {
				this.form1.resetFields()
				this.visible = false
			}
		}
	}
</script>

<style lang="less" scoped>
	.user-wrapper {
		float: right;
		margin-right: 20px;
	}

	.user-wrapper:hover {
		cursor: pointer
	}

	.appRedio {
		border: 1px solid #91d5ff;
		padding: 10px 20px;
		background: #e6f7ff;
		border-radius: 2px;
		margin-bottom: 10px;
		color: #91d5ff;
		/*display: inline;
    margin-bottom:10px;*/
	}
</style>
