/**
 * 项目默认配置项
 * primaryColor - 默认主题色, 如果修改颜色不生效，请清理 localStorage
 * navTheme - sidebar theme ['dark', 'light'] 两种主题
 * colorWeak - 色盲模式
 * layout - 整体布局方式 ['sidemenu', 'topmenu'] 两种布局
 * fixedHeader - 固定 Header : boolean
 * fixSiderbar - 固定左侧菜单栏 ： boolean
 * autoHideHeader - 向下滚动时，隐藏 Header : boolean
 * contentWidth - 内容区布局： 流式 |  固定
 *
 * storageOptions: {} - Vue-ls 插件配置项 (localStorage/sessionStorage)
 * production: 变量暂先设定为 false，目的是各种环境都正常显示设置抽屉，真实环境请放开注释
 *
 *
 */

export default {
	primaryColor: '#1890ff', // primary color of ant design
	navTheme: 'dark', // theme for nav menu
	headerColor: 'white',
	layout: 'sidemenu', // nav menu position: sidemenu or topmenu
	contentWidth: 'Fixed', // layout of content: Fluid or Fixed, only works when layout is topmenu
	fixedHeader: false, // sticky header
	fixSiderbar: false, // sticky siderbar
	autoHideHeader: false, //  auto hide header
	colorWeak: false,
	multiTab: false,
	production: false,
	// vue-ls options
	storageOptions: {
		namespace: 'pro__', // key prefix
		name: 'ls', // name variable Vue.[ls] or this.[$ls],
		storage: 'local' // storage name session, local, memory
	}
}
