import {
	axios
} from '@/utils/request'

/**
 * 初始化相关参数
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function initRelatedParam(data) {
	return axios({
		url: '/sysSetting/initRelatedParam',
		method: 'post',
		data: data
	})
}

/**
 * 分页查询的数据
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function sysSettingPage(data) {
	return axios({
		url: '/sysSetting/page',
		method: 'post',
		data: data
	})
}

/**
 * 添加
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function sysSettingAdd(data) {
	return axios({
		url: '/sysSetting/save_data',
		method: 'post',
		data: data
	})
}

/**
 * 编辑
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function sysSettingEdit(data) {
	return axios({
		url: '/sysSetting/edit_data',
		method: 'post',
		data: data
	})
}

/**
 * 删除
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function sysSettingDelete(data) {
	return axios({
		url: '/sysSetting/delete',
		method: 'post',
		data: data
	})
}

/**
 * 加载数据
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function loadData(data) {
	return axios({
		url: '/sysSetting/loadData',
		method: 'post',
		data: data
	})
}

/**
 * 加载数据
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function saveOrUpdateData(data) {
	return axios({
		url: '/sysSetting/saveOrUpdateData',
		method: 'post',
		data: data
	})
}
