<template>
  <a-spin :spinning="spinning">
    <a-card :bordered="false" :confirmLoading="confirmLoading">
      <div v-show="visible">
        <div class="table-page-search-wrapper">
          <div>
            <a-tabs default-active-key="1" @change="callback">
              <a-tab-pane key="1" tab="活动" force-render>
                <a-form layout="inline">
                  <a-row :gutter="48">
                    <a-col :sm="24" :md="6">
                      <a-form-item label="统计类型">
                        <a-select style="width:100%;" v-model="queryParam.appletMode" placeholder="请选择统计类型">
                          <a-select-option :value="3">
                            浏览量
                          </a-select-option>
                          <a-select-option :value="4">
                            转发量
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :sm="24" :md="6">
                      <a-form-item label="活动名称">
                        <a-select style="width:100%;" v-model="queryParam.activityId" placeholder="请选择活动名称" allow-clear>
                          <a-select-option v-for="(item,index) in activityList" :key="'activity'+index"
                            :value="item.activityId">
                            {{item.title}}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :sm="24" :md="6">
                      <a-form-item label="日期期间">
                        <a-range-picker :format="dateFormat" v-model="queryParam.signDate"></a-range-picker>
                      </a-form-item>
                    </a-col>
                    <a-col :sm="24" :md="6">
                      <a-form-item label="用户昵称">
                        <a-input :max-length="20" v-model="queryParam.name" allow-clear placeholder="请输入用户姓名" />
                      </a-form-item>
                    </a-col>
                    <a-col :sm="24" :md="24">
                      <span class="table-page-search-submitButtons"
                        :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                        <a-button type="primary" @click="checkButton">查询</a-button>
                        <a-button style="margin-left: 8px" type="primary" @click="Excel()">导出</a-button>
                      </span>
                    </a-col>
                  </a-row>
                </a-form>

                <!-- 活动表格 -->
                <div v-if="queryParam.appletMode == 3">
                  <s-table bordered ref="tableAL" size="default" :columns="columnActivityl" :data="loadData"
                    :alert="false" :scroll="scroll" rowKey="browseId" :customRow="selectRow"
                    :rowClassName="rowClassName" :style="operaHigh">
                    <span slot="mode">
                      <span>浏览量</span>
                    </span>
                    
                    <!-- 头像 -->
                    <span slot="headImage" slot-scope="text">
                      <a :href="text" target="_blank" v-if="text">
                        <img :src="text" alt="" style="width: 50px;height: 50px;" />
                      </a>
                    </span>
                    
                    <span slot="signDate" slot-scope="text, record">
                      <span>{{record.signStartDate}}</span>
                      <span> ~ </span>
                      <span>{{record.signEndDate}}</span>
                    </span>
                  </s-table>
                </div>
                <div v-if="queryParam.appletMode == 4">
                  <s-table bordered ref="tableAZ" size="default" :columns="columnActivityz" :data="loadData"
                    :alert="false" :scroll="scroll" rowKey="forwardId" :customRow="selectRow"
                    :rowClassName="rowClassName" :style="operaHigh">
                    <span slot="mode">
                      <span>转发量</span>
                    </span>
                    
                    <!-- 头像 -->
                    <span slot="headImage" slot-scope="text">
                      <a :href="text" target="_blank" v-if="text">
                        <img :src="text" alt="" style="width: 50px;height: 50px;" />
                      </a>
                    </span>
                    
                    <span slot="signDate" slot-scope="text, record">
                      <span>{{record.signStartDate}}</span>
                      <span> ~ </span>
                      <span>{{record.signEndDate}}</span>
                    </span>
                  </s-table>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="城市">
                <a-form ref="cityForm" layout="inline">
                  <a-row :gutter="48">
                    <a-col :sm="24" :md="6">
                      <a-form-item label="统计类型">
                        <a-select @change="appletChange" style="width:100%;" v-model="queryParam.appletMode"
                          placeholder="请选择统计类型">
                          <a-select-option :value="5">
                            浏览量
                          </a-select-option>
                          <a-select-option :value="6">
                            转发量
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :sm="24" :md="6">
                      <a-form-item label="活动名称">
                        <a-select style="width:100%;" v-model="queryParam.activityId" placeholder="请选择活动名称" allow-clear>
                          <a-select-option v-for="(item,index) in activityList" :key="'activity'+index"
                            :value="item.activityId">
                            {{item.title}}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :sm="24" :md="6">
                      <a-form-item label="城市">
                        <a-select placeholder="请选择省" allow-clear
                          v-decorator="['provinceId', {rules: [{required: true, message: '请选择省名称！'}]}]"
                          @change="provincesChange" style="width: 50%;">
                          <a-select-option v-for="(item,index) in provinces" :key="'province'+index"
                            :value="item.provinceId">
                            {{item.provinceName}}
                          </a-select-option>
                        </a-select>
                        <a-select placeholder="请选择市" allow-clear
                          v-decorator="['cityId', {rules: [{required: true, message: '请选择市名称！'}]}]"
                          @change="citysChange" style="width: 50%;">
                          <a-select-option v-for="(item,index) in citys" :key="'city'+index" :value="item.cityId">
                            {{item.cityName}}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :sm="24" :md="6">
                      <span class="table-page-search-submitButtons"
                        :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                        <a-button type="primary" @click="checkButton">查询</a-button>
                      </span>
                    </a-col>
                  </a-row>
                </a-form>
                <!-- 城市表格 -->
                <div v-if="queryParam.appletMode == 5">
                  <s-table bordered ref="tableCL" size="default" :columns="columnsCityl" :data="loadData" :alert="false"
                    :scroll="scroll" rowKey="browseId" :customRow="selectRow" :rowClassName="rowClassName"
                    :style="operaHigh">
                    <span slot="mode">
                      <span>浏览量</span>
                    </span>
                    <span slot="city" slot-scope="text, record">
                      <span>{{record.provinceName}} {{record.cityName}}</span>
                    </span>
                  </s-table>
                </div>
                <div v-if="queryParam.appletMode == 6">
                  <s-table bordered ref="tableCZ" size="default" :columns="columnsCityz" :data="loadData" :alert="false"
                    :scroll="scroll" rowKey="forwardId" :customRow="selectRow" :rowClassName="rowClassName"
                    :style="operaHigh">
                    <span slot="mode">
                      <span>转发量</span>
                    </span>
                    <span slot="city" slot-scope="text, record">
                      <span>{{record.provinceName}} {{record.cityName}}</span>
                    </span>
                  </s-table>
                </div>
              </a-tab-pane>
              <a-tab-pane key="3" tab="作品">
                <a-form layout="inline">
                  <a-row :gutter="48">
                    <a-col :sm="24" :md="8">
                      <a-form-item label="用户姓名">
                        <a-input :max-length="20" v-model="queryParam.name" allow-clear placeholder="请输入用户姓名" />
                      </a-form-item>
                    </a-col>
                    <a-col :sm="24" :md="16">
                      <span class="table-page-search-submitButtons"
                        :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                        <a-button type="primary" @click="checkButton">查询</a-button>
                      </span>
                    </a-col>
                  </a-row>
                </a-form>
                <!-- 表格 -->
                <s-table bordered ref="tableWorks" size="default" :columns="columnsWorks" :data="loadData"
                  :alert="false" :scroll="scroll" rowKey="id" :customRow="selectRow" :rowClassName="rowClassName"
                  :style="operaHigh">


                  <span slot="action" slot-scope="text, record">
                    <a @click="$refs.browseForm.browse(record)">浏览量详细</a>
                    <a-divider type="vertical" />
                    <a @click="$refs.detailedForm.detailed(record)">转发量详细</a>
                  </span>
                </s-table>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
      </div>
      <detailedForm ref="detailedForm" @ok="handleOk" />
      <browseForm ref="browseForm" @ok="handleOk" />
    </a-card>
  </a-spin>
</template>

<script>
  import {
    STable
  } from '@/components'
  import {
    initRelatedParam,
    vBrowseInfoPage,
    vBrowseInfoDelete,
    exportExcel
  } from '@/api/modular/business/vBrowseInfoManage'
  import {
    initProvinceBoxs,
    changeCityBox,
  } from '@/api/modular/system/areaManage.js'
  import detailedForm from './detailedForm'
  import browseForm from './browseForm'

  export default {
    components: {
      STable,
      detailedForm,
      browseForm
    },

    data() {
      return {
        spinning: false,
        visible: true,
        // 高级搜索 false: 默认收取 true: 默认关闭
        advanced: true,
        //行选中Id
        selectRowId: '',
        // 查询参数
        queryParam: {
          activityId: '',
          type: 2,
          appletMode: 3,
        },
        provinces: [],
        provinceId: '',
        cityId: '',
        citys: [],
        activityList: [],
        // 时间格式化
        dateFormat: 'YYYY-MM-DD',
        // 检索参数区相关
        queryRows: {
          count: 2,
          current: 1
        },
        //表格scroll
        scroll: {
          x: true,
          y: 397,
        },
        mode: 1,
        // 表头
        columnActivityl: [{
            title: '序号',
            dataIndex: 'no',
            width: 60,
            align: 'center'
          },
          {
            title: '用户昵称',
            dataIndex: 'nickName',
            align: 'center',
            width: 150,
          },
          {
            dataIndex: 'headImage',
            title: '头像',
            align: 'center',
            width: 95,
            scopedSlots: {
              customRender: 'headImage'
            }
          },
          {
            title: '微信登录省',
            dataIndex: 'province',
            align: 'center',
            width: 150,
          },
          {
            title: '微信登录市',
            dataIndex: 'city',
            align: 'center',
            width: 150,
          },
          {
            title: '活动名称',
            dataIndex: 'title',
            align: 'center',
            width: 200,
          },
          {
            title: '次数',
            dataIndex: 'num',
            align: 'center',
            width: 100
          }
        ],

        // 表头
        columnActivityz: [{
            title: '序号',
            dataIndex: 'no',
            width: 60,
            align: 'center'
          },
          {
            title: '用户昵称',
            dataIndex: 'nickName',
            align: 'center',
            width: 150,
          },
          {
            dataIndex: 'headImage',
            title: '头像',
            align: 'center',
            width: 95,
            scopedSlots: {
              customRender: 'headImage'
            }
          },
          {
            title: '微信登录省',
            dataIndex: 'province',
            align: 'center',
            width: 150,
          },
          {
            title: '微信登录市',
            dataIndex: 'city',
            align: 'center',
            width: 150,
          },
          {
            title: '活动名称',
            dataIndex: 'title',
            align: 'center',
            width: 200,
          },
          {
            title: '次数',
            dataIndex: 'num',
            align: 'center',
            width: 100
          }
        ],

        // 城市表头
        columnsCityl: [{
            title: '序号',
            dataIndex: 'no',
            width: 55,
            align: 'center'
          },
          {
            title: '活动名称',
            dataIndex: 'title',
            align: 'center',
            width: 200,
          },
          {
            title: '城市',
            dataIndex: 'city',
            align: 'center',
            width: 150,
            scopedSlots: {
              customRender: 'city'
            }
          },
          {
            title: '次数',
            dataIndex: 'num',
            align: 'center',
            width: 100
          }
        ],

        // 城市表头z
        columnsCityz: [{
            title: '序号',
            dataIndex: 'no',
            width: 55,
            align: 'center'
          },
          {
            title: '活动名称',
            dataIndex: 'title',
            align: 'center',
            width: 200,
          },
          {
            title: '城市',
            dataIndex: 'city',
            align: 'center',
            width: 150,
            scopedSlots: {
              customRender: 'city'
            }
          },
          {
            title: '次数',
            dataIndex: 'num',
            align: 'center',
            width: 100
          }
        ],

        // 作品表头
        columnsWorks: [{
            title: '序号',
            dataIndex: 'no',
            width: 95,
            align: 'center'
          },
          {
            title: '用户姓名',
            dataIndex: 'name',
            align: 'center',
            width: 120,
          },
          {
            title: '性别',
            dataIndex: 'sexName',
            align: 'center',
            width: 70,
          },
          {
            title: '年龄',
            dataIndex: 'ageName',
            align: 'center',
            width: 70,
          },
          {
            title: '手机号',
            dataIndex: 'phone',
            align: 'center',
            width: 100,
          },
          {
            title: '票数',
            dataIndex: 'votesNum',
            align: 'center',
            width: 140,
          },
          {
            title: '排行',
            dataIndex: 'ranking',
            align: 'center',
            width: 100,
          },
          {
            title: '操作',
            width: 200,
            dataIndex: 'action',
            align: 'center',
            scopedSlots: {
              customRender: 'action'
            }
          }
        ],

        // 加载数据方法 必须为 Promise 对象
        loadData: parameter => {
          let that = this;
          return vBrowseInfoPage(Object.assign(parameter, this.queryParam)).then((res) => {
            res.data.rows.forEach((item, index) => {
              item.no = (res.data.pageNo - 1) * res.data.pageSize + index + 1;
            });
            return res.data
          }).catch(() => {
            this.$message.error('系统异常，请重新登陆或者联系管理员。');
          })
        },
        selectedRowKeys: [],
        selectedRows: [],
        confirmLoading: false,
		
		// 活动表格额外减少高度
		activityDeHeigh: 55,
      }
    },

    created() {
      this.getProvinces();
      this.getActivityList();
    },

    // 计算属性
    computed: {
      // 表格头部功能区高度
      operaHigh() {
        return {
          "--operaHigh": (this.queryRows.current * 56 + 288 + this.activityDeHeigh) + 'px',
        }
      },
    },

    methods: {
      callback(key) {
        // 查询参数
        this.queryParam.activityId = ''
        this.queryParam.type = 2
        this.queryParam.appletMode = 3
        this.queryParam['signDate'] = ''
        this.queryParam.provinceId = ''
        this.queryParam.cityId = ''
        if (key == 1) {
			this.activityDeHeigh = 55;
          //活动
          this.queryParam.type = 2
          this.queryParam.appletMode = 3
          this.$refs.tableAL.refresh(true);
        }
        if (key == 2) {
			this.activityDeHeigh = 0;
          //城市
          this.queryParam.type = 3
          this.queryParam.appletMode = 5
        }
        if (key == 3) {
			this.activityDeHeigh = 0;
          //作品
          this.queryParam.type = 1
        }
      },

      // 方式切换
      appletChange(value) {
        // this.$nextTick(() => {
        if (value == 3) { //活动--浏览量
          this.appletMode = 3
        } else if (value == 4) { //活动--转发量
          this.appletMode = 4
        } else if (value == 5) { //城市--浏览量
          this.appletMode = 5
        } else if (value == 6) { //城市--转发量
          this.appletMode = 6
        }
        // })
        this.checkButton()
      },

      //查询
      checkButton() {
        // this.$nextTick(() => {
        if (this.queryParam.appletMode == 3 && this.queryParam.type == 2) { //活动浏览量查询
          // 征集时间
          if (this.queryParam['signDate']) {
            this.queryParam.signStartDate = this.queryParam['signDate'].at(0);
            this.queryParam.signEndDate = this.queryParam['signDate'].at(1);
          }
          this.$refs.tableAL.refresh(true);
        } else if (this.queryParam.appletMode == 4 && this.queryParam.type == 2) { //活动转发量查询
          // 征集时间
          if (this.queryParam['signDate']) {
            this.queryParam.signStartDate = this.queryParam['signDate'].at(0);
            this.queryParam.signEndDate = this.queryParam['signDate'].at(1);
          }
          this.$refs.tableAZ.refresh(true);
        } else if (this.queryParam.appletMode == 5 && this.queryParam.type == 3) { //城市浏览量查询
          this.queryParam.provinceId = this.provinceId
          this.queryParam.cityId = this.cityId
          this.$refs.tableCL.refresh(true);
        } else if (this.queryParam.appletMode == 6 && this.queryParam.type == 3) { //城市转发量查询
          this.queryParam.provinceId = this.provinceId
          this.queryParam.cityId = this.cityId
          this.$refs.tableCZ.refresh(true);
        } else { // 作品排名
          this.$refs.tableWorks.refresh(true);
        }
        // })
      },

      //获取活动下拉框
      getActivityList() {
        var that = this
        initRelatedParam().then(res => {
          if (res.success) {
            that.activityList = res.data.list;
          }
        })
      },

      //权限省下拉选择
      provincesChange(value) {
        this.provinceId = value
        this.citys = [];

        setTimeout(() => {
          this.form.setFieldsValue({
            province: value,
            city: [],
            cityId: ''
          })
          this.confirmLoading = false
        }, 500)
        this.getCitys();
      },

      //市下拉选择
      citysChange(value) {
        this.cityId = value
      },

      //获取市数据
      getProvinces() {
        var that = this
        initProvinceBoxs().then(res => {
          if (res.success) {
            that.provinces = res.data;
          }
        })
      },

      getCitys() {
        //联动查询省级下市列表
        let param = {
          id: this.provinceId
        }
        //查询下级市列表
        changeCityBox(param).then(res => {
          if (res.success) {
            this.citys = res.data;
          }
        })
      },

      // 点击行变色 rowKey
      selectRow(record) {
        return {
          props: {},
          on: {
            click: () => {
              this.selectRowId = record.id
            },
          }
        }
      },

      //更换审核状态
      handleChange() {

      },

      //报名时间
      onChange() {

      },

      //Excel导出
      Excel() {
        this.spinning = true
        exportExcel(this.queryParam).then((res) => {
          let link = document.createElement('a');

          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          let url = window.URL || window.webkitURL || window.moxURL;

          link.href = url.createObjectURL(blob);
          link.style.dispaly = 'none';

          if (this.queryParam.appletMode == 3 && this.queryParam.type == 2) {
            //导出名称
            link.setAttribute('download', "活动浏览量统计.xlsx");
          } else if (this.queryParam.appletMode == 4 && this.queryParam.type == 2) {
            //导出名称
            link.setAttribute('download', "活动转发量统计.xlsx");
          }
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          url.revokeObjectURL
          url.revokeObjectURL(link.href);
          this.spinning = false
        })
      },

      //图片下载
      download() {

      },

      // 点击行变色 class
      rowClassName(record) {
        return record.id === this.selectRowId ? 'selectRow' : '';
      },

      handleOk() {
        this.$refs.table.refresh()
      },

    }
  }
</script>

<style scoped lang="less">
</style>
