<template>
	<div class="logo">
		<!--    <router-link :to="{name:'Console'}">-->
		<!-- <img src="@/assets/logo.png" @click="toHome" style="cursor: pointer" /> -->
		<h1 v-if="showTitle" @click="toHome" style="cursor: pointer">{{ this.titles }}</h1>
		<!--    </router-link>-->
	</div>
</template>

<script>
	import {
		mixin
	} from '@/utils/mixin'

	export default {
		name: 'Logo',
		components: {
			// LogoSvg
		},
		mixins: [mixin],
		props: {
			showTitle: {
				type: Boolean,
				default: true,
				required: false
			}
		},

		data() {
			return {
				titles: '用户大赛管理系统'
			}
		},

		methods: {
			// 跳转主页home
			toHome() {
				this.$router.push({
					path: '/main/home'
				})
			}
		}
	}
</script>

<style lang="less">
</style>
