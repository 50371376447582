<template>
	<a-card :bordered="false" :confirmLoading="confirmLoading">
		<a-spin :spinning="confirmLoading">

			<div class="home-block" id="home-menu">
				<a-row>
					<a-col :span="12">
						<a-card hoverable>
							<div>
								当日总浏览量
							</div>
							<span style="font-size: 40px;">
								{{ dataVolume.browseVolume }}
							</span>
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card hoverable>
							<div>
								当日总转发量
							</div>
							<span style="font-size: 40px;">
								{{ dataVolume.forwardVolume }}
							</span>
						</a-card>
					</a-col>
				</a-row>
			</div>

			<a-row :gutter="48">
				<a-col :md="12" :sm="6">
					<div class="home-block">
						<div class="table-page-search-wrapper" style="height: 3.5rem;">
							<a-form layout="inline">
								<a-row :gutter="48">
									<a-col :span="16">
										<a-form-item label="排名分组">
											<a-select v-model="queryParam.offset" placeholder="请选择排名分组"
												@change="getRankVolumes" style="width:100%;">
												<a-select-option :value="0">
													1~10名
												</a-select-option>
												<a-select-option :value="10">
													11~20名
												</a-select-option>
												<a-select-option :value="20">
													21~30名
												</a-select-option>
												<a-select-option :value="30">
													31~40名
												</a-select-option>
												<a-select-option :value="40">
													41~50名
												</a-select-option>
												<a-select-option :value="50">
													50名以上
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>
									<a-col :span="8">
										<a style="text-align: right;" @click="toPath()">
											<span class="table-page-search-submitButtons">
												查看更多
											</span>
										</a>
									</a-col>
								</a-row>
							</a-form>
						</div>
						<div class="Echarts">
							<div ref="rank" style="width: 100%;height: 60vh;"></div>
						</div>
					</div>
				</a-col>

				<a-col :md="12" :sm="6">
					<div class="home-block">
						<div class="table-page-search-wrapper" style="height: 3.5rem;">
							<a-form layout="inline">
								<a-row :gutter="48">
									<a-col :span="24">
										<a style="text-align: right;" @click="toPath()">
											<span class="table-page-search-submitButtons">
												查看更多
											</span>
										</a>
									</a-col>
								</a-row>
							</a-form>
						</div>
						<a-row>
							<a-col :span="12">
								<div class="Echarts" style="padding: 0px 24px">
									<div ref="cityBrowse" style="width: 100%;height: 60vh;"></div>
								</div>
							</a-col>
							<a-col :span="12">
								<div class="Echarts" style="padding: 0px 24px">
									<div ref="cityForward" style="width: 100%;height: 60vh;"></div>
								</div>
							</a-col>
						</a-row>
					</div>
				</a-col>
			</a-row>

			<a-row :gutter="48">
				<a-col :span="24">
					<div class="home-block">
						<div class="table-page-search-wrapper" style="height: 3.5rem;">
							<a-form layout="inline">
								<a-row :gutter="48">
									<a-col :span="16">
										<a-form-item label="日期">
											<a-month-picker v-model="queryParam.activitySearchDate" placeholder="请选择年月"
												@change="getActivityVolumes" style="width: 100%;" />
										</a-form-item>
									</a-col>
									<a-col :span="8">
										<a style="text-align: right;" @click="toPath()">
											<span class="table-page-search-submitButtons">
												查看更多
											</span>
										</a>
									</a-col>
								</a-row>
							</a-form>
						</div>
						<div class="Echarts">
							<div ref="activity" style="width: 100%;height: 40vh;margin: 0 auto">
							</div>
						</div>
					</div>
				</a-col>
			</a-row>

			<a-row :gutter="48">
				<a-col :span="24">
					<div class="home-block">
						<div class="table-page-search-wrapper">
							<a-form layout="inline">
								<a-row :gutter="48">
									<a-col :span="24">
										<a style="text-align: right;" @click="toPath()">
											<span class="table-page-search-submitButtons">
												查看更多
											</span>
										</a>
									</a-col>
								</a-row>
							</a-form>
						</div>
						<div class="Echarts">
							<div ref="enroll" style="width: 100%;height: 40vh;">
							</div>
						</div>
					</div>
				</a-col>
			</a-row>

		</a-spin>
	</a-card>
</template>

<script>
	import {
		initRelatedParam,
		appletVolumes,
		activityVolumes,
		cityVolumes,
		rankVolumes,
		enrollVolumes
	} from '@/api/modular/business/homeEchartManage'

	export default {
		name: 'Echarts',
		data() {
			return {
				confirmLoading: false,
				// 每行菜单链接卡片个数 需能与24整除
				menuCount: 2,
				// 查询参数
				queryParam: {
					offset: 0,
					appletSearchDate: null,
					activitySearchDate: null,
				},
				myChartApplet: null,
				// 主页快速菜单面板 例
				homeMenus: [{
						path: 'dict',
						rootKey: 'SYS',
						num: 222,
						text: '当日总浏览量'
					},
					{
						path: 'account',
						rootKey: 'SYS',
						num: 333,
						text: '当日总转发量'
					}
				],


				// 当日数据量
				dataVolume: {},
				// 小程序图表数据
				appletVolumes: {},
				// 活动图表数据
				activityVolumes: {},
				// 城市图表数据
				cityVolumes: {},
				// 排名图表数据
				rankVolumes: {},
				// 作品图表数据
				enrollVolumes: {},

				// Echart图表对象
				appletChart: null,
				activityChart: null,
				cityBrowseChart: null,
				cityForwardChart: null,
				rankChart: null,
				enrollChart: null,
			}
		},


		// created钩子函数
		created() {
			this.initRelatedParam();
		},

		mounted() {
			this.initECharts();
		},

		// 销毁
		beforeDestroy() {
			// 销毁
			this.disposeECharts();
		},

		methods: {
			// 主页快速菜单跳转
			toPath() {
				// 路由跳转
				this.$router.push({
					path: '/main/statistics',
				});
			},


			// --- > 功能业务
			// 初始化关联参数 今日数据量
			initRelatedParam() {
				initRelatedParam({}).then((res) => {
					// 成功
					if (res.success) {
						// 今日数据量取得
						this.dataVolume = res.data;
					} else {
						this.$error({
							okText: '确定',
							title: '提示信息',
							content: "请求关联参数失败，原因：" + res.message
						});
					}
				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '提示信息',
						content: "请求关联参数失败，原因：" + err.response.data.message,
					});
				})
			},

			// 初始化图表
			initECharts() {
				// 初始化
				//活动浏览量和转发量
				this.activityChart = this.$echarts.init(this.$refs['activity']);
				//城市浏览量
				this.cityBrowseChart = this.$echarts.init(this.$refs['cityBrowse']);
				//城市转发量
				this.cityForwardChart = this.$echarts.init(this.$refs['cityForward']);
				// 排名
				this.rankChart = this.$echarts.init(this.$refs['rank']);
				// 作品
				this.enrollChart = this.$echarts.init(this.$refs['enroll']);

				setTimeout(() => {
					this.getActivityVolumes();
					this.getCityVolumes();
					this.getRankVolumes();
					this.getEnrollVolumes();
				}, 100);
			},

			// 小程序数据量图表
			getApletVolumes() {
				appletVolumes({
					'searchDate': this.queryParam['appletSearchDate']
				}).then((res) => {
					// 成功
					if (res.success) {
						// 值
						this.appletVolumes = res.data;
						// 生成图表
						this.reloadAppletChart();
					} else {
						this.$error({
							okText: '确定',
							title: '提示信息',
							content: "请求小程序数据失败，原因：" + res.message
						});
					}
				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '提示信息',
						content: "请求小程序数据失败，原因：" + err.response.data.message,
					});
				})
			},

			// 小程序图表
			reloadAppletChart() {

				//小程序浏览量配置图表
				let option = {
					title: {
						text: '小程序'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					legend: {
						data: ['浏览量', '转发量']
					},
					grid: {
						left: '5%',
						right: '5%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						data: this.appletVolumes.dates
					}],
					yAxis: [{
						type: 'value',
						minInterval: 1
					}],
					series: [{
							name: '浏览量',
							type: 'line',
							areaStyle: {},
							emphasis: {
								focus: 'series'
							},
							data: this.appletVolumes.browses
						},
						{
							name: '转发量',
							type: 'line',
							areaStyle: {},
							emphasis: {
								focus: 'series'
							},
							data: this.appletVolumes.forwards
						},
					]
				};

				//小程序排名和转发量
				this.appletChart.setOption(option);
			},

			// 获取活动图表数据
			getActivityVolumes() {
				activityVolumes({
					'searchDate': this.queryParam['activitySearchDate']
				}).then((res) => {
					// 成功
					if (res.success) {
						// 值
						this.activityVolumes = res.data;
						// 生成图表
						this.reloadActivityChart();
					} else {
						this.$error({
							okText: '确定',
							title: '提示信息',
							content: "请求活动数据失败，原因：" + res.message
						});
					}
				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '提示信息',
						content: "请求活动失败，原因：" + err.response.data.message,
					});
				})
			},

			// 活动图表
			reloadActivityChart() {

				//活动浏览量配置图表
				let option = {
					title: {
						text: '活动期间'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					legend: {
						data: ['浏览量', '转发量']
					},
					color: ['#62d1de', '#ffa361'],
					grid: {
						left: '5%',
						right: '5%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						data: this.activityVolumes.dates
					}],
					yAxis: [{
						type: 'value',
						minInterval: 1
					}],
					series: [{
							name: '浏览量',
							type: 'line',
							areaStyle: {},
							emphasis: {
								focus: 'series'
							},
							data: this.activityVolumes.browses
						},
						{
							name: '转发量',
							type: 'line',
							areaStyle: {},
							emphasis: {
								focus: 'series'
							},
							data: this.activityVolumes.forwards
						},
					]
				};

				//活动浏览量和转发量
				this.activityChart.setOption(option);
			},

			// 获取城市图表数据
			getCityVolumes() {
				cityVolumes({
					'searchDate': this.queryParam['citySearchDate']
				}).then((res) => {
					// 成功
					if (res.success) {
						// 值
						this.cityVolumes = res.data;
						// 生成图表
						this.reloadCityCharts();
					} else {
						this.$error({
							okText: '确定',
							title: '提示信息',
							content: "请求城市数据失败，原因：" + res.message
						});
					}
				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '提示信息',
						content: "请求城市失败，原因：" + err.response.data.message,
					});
				})
			},

			// 城市图表
			reloadCityCharts() {

				//城市浏览量配置图表
				let optionCityBrowse = {
					title: {
						text: '城市浏览量',
						left: 'center'
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						type: 'scroll',
						top: 'bottom'
					},
					series: [{
						name: '浏览量',
						type: 'pie',
						radius: '55%',
						data: this.cityVolumes.browses,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				};

				//城市转发量配置图表
				let optionCityForward = {
					title: {
						text: '城市转发量',
						left: 'center'
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						type: 'scroll',
						top: 'bottom'
					},
					series: [{
						name: '转发量',
						type: 'pie',
						radius: '55%',
						data: this.cityVolumes.forwards,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				};

				//城市浏览量
				this.cityBrowseChart.setOption(optionCityBrowse);
				//城市转发量
				this.cityForwardChart.setOption(optionCityForward);
			},


			// 排名图表
			getRankVolumes() {
				rankVolumes({
					'offset': this.queryParam['offset']
				}).then((res) => {
					// 成功
					if (res.success) {
						// 值
						this.rankVolumes = res.data;
						// 生成图表
						this.reloadRankChart();
					} else {
						this.$error({
							okText: '确定',
							title: '提示信息',
							content: "请求排名数据失败，原因：" + res.message
						});
					}
				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '提示信息',
						content: "请求排名失败，原因：" + err.response.data.message,
					});
				})
			},

			// 排名图表
			reloadRankChart() {
				// 排名配置图表
				let option = {
					title: {
						text: '作品',
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {},
					grid: {
						left: '5%',
						right: '5%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						minInterval: 1,
						boundaryGap: [0, 0.01]
					},
					yAxis: {
						type: 'category',
						data: this.rankVolumes.enrollNames
					},
					series: [{
						name: '票数',
						type: 'bar',
						showBackground: true,
						itemStyle: {
							color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: '#83bff6'
								},
								{
									offset: 0.5,
									color: '#188df0'
								},
								{
									offset: 1,
									color: '#188df0'
								}
							])
						},
						data: this.rankVolumes.votes,
					}, ]
				};

				// 排名图表
				this.rankChart.setOption(option);
			},

			// 作品数据量
			getEnrollVolumes() {
				enrollVolumes({}).then((res) => {
					// 成功
					if (res.success) {
						// 值
						this.enrollVolumes = res.data;
						// 生成图表
						this.reloadEnrollChart();
					} else {
						this.$error({
							okText: '确定',
							title: '提示信息',
							content: "请求作品数据失败，原因：" + res.message
						});
					}
				}).catch((err) => {
					this.$error({
						okText: '确定',
						title: '提示信息',
						content: "请求作品失败，原因：" + err.response.data.message,
					});
				})
			},

			// 作品图表
			reloadEnrollChart() {
				// 配置
				let option = {
					title: {
						text: '热门作品',
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {},
					grid: {
						left: '5%',
						right: '5%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: this.enrollVolumes.names,
					}],
					yAxis: [{
						type: 'value',
						minInterval: 1,
					}],
					series: [{
							name: '浏览量',
							type: 'bar',
							emphasis: {
								focus: 'series'
							},
							data: this.enrollVolumes.browses
						},
						{
							name: '转发量',
							type: 'bar',
							emphasis: {
								focus: 'series'
							},
							data: this.enrollVolumes.forwards
						}
					]
				};

				// 作品图表
				this.enrollChart.setOption(option);
			},

			// 销毁图表实例
			disposeECharts() {
				// 小程序
				if (this.appletChart) {
					this.appletChart.dispose();
					this.appletChart = null;
				}
				//活动浏览量和转发量
				if (this.activityChart) {
					this.activityChart.dispose();
					this.activityChart = null;
				}
				//城市浏览量
				if (this.cityBrowseChart) {
					this.cityBrowseChart.dispose();
					this.cityBrowseChart = null;
				}
				//城市转发量
				if (this.cityForwardChart) {
					this.cityForwardChart.dispose();
					this.cityForwardChart = null;
				}
				// 排名
				if (this.rankChart) {
					this.rankChart.dispose();
					this.rankChart = null;
				}
				// 作品
				if (this.enrollChart) {
					this.enrollChart.dispose();
					this.enrollChart = null;
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/.home-block {
		text-align: center;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		padding: 0.625rem;
		margin-bottom: 1.5rem;
	}

	/deep/.welcome {
		border-radius: 0.625rem;
		text-align: left;
		font-size: 2rem;
		font-weight: 900;
		height: 6.5rem;
		line-height: 5rem;
		background-color: #eceef1;
	}

	/deep/ #home-menu {
		.ant-col {
			padding: 0.5rem 0.5rem;
		}

		a {
			padding: 0.75rem;
			text-align: center;
			display: block;
			color: inherit
		}

		span {
			// margin: 0.375rem 0 0.8rem 0;
		}

		div {
			font-size: 0.875rem !important;
			font-weight: 600;
		}
	}

	/deep/.topMame {
		width: 100%;
		height: 35px;
		background-color: #ededed;
	}

	/deep/.colorFont {
		float: left;
		width: 5px;
		height: 20px;
		background: #FF4500;
		margin: 8px 15px 5px 15px;
	}

	/deep/.ant-table-body {
		border-bottom: 1px solid #e8e8e8;
		border-right: 1px solid #e8e8e8;
	}

	/deep/.editable-row-operations a {
		margin-right: 8px;
	}

	/deep/.r {
		position: fixed;
		bottom: 0px;
		width: 100%;
		height: 70px;
		background-color: #ededed;
		left: 125px;
		text-align: center;
		padding: 20px 0;
		z-index: 99;
	}
</style>
