import {
	axios
} from '@/utils/request'
/**
 * 登录
 *
 * @author yubaoshan
 * @date 2020/5/26 19:06
 */
export function login(parameter) {
	return axios({
		url: '/login',
		method: 'post',
		data: parameter
	})
}

export function get() {
	return axios({
		url: '/get',
		method: 'get',
		params: null
	})
}
