import {axios} from '@/utils/request'

/**
 * 初始化相关参数
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function initRelatedParam(parameter) {
    return axios({
        url: '/vEnrollInfo/initRelatedParam',
        method: 'post',
        params: parameter
    })
}

/**
 * 分页查询的数据
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vEnrollInfoPage(data) {
    return axios({
        url: '/vEnrollInfo/page',
        method: 'post',
        data: data
    })
}

/**
 * 添加
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vEnrollInfoAdd(data) {
    return axios({
        url: '/vEnrollInfo/save_data',
        method: 'post',
        data: data
    })
}

/**
 * 编辑
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vEnrollInfoEdit(data) {
    return axios({
        url: '/vEnrollInfo/edit_data',
        method: 'post',
        data: data
    })
}

/**
 * 删除
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vEnrollInfoDelete(data) {
    return axios({
        url: '/vEnrollInfo/delete',
        method: 'post',
        data: data
    })
}

/**
 * 图片下载
 *
 * @author ymsc
 * @date 2020/7/02 01:46
 */
export function downloadZip(parameter) {
	return axios({
		url: '/vEnrollInfo/downloadZip',
		method: 'post',
		params: parameter,
    responseType:'blob'
	})
}

/**
 * 批量下载
 *
 * @author ymsc
 * @date 2020/7/02 01:46
 */
export function downloadImages(parameter) {
	return axios({
		url: '/vEnrollInfo/downloadImages',
		method: 'post',
		params: parameter
	})
}

/**
 * Excel导出
 *
 * @author ymsc
 * @date 2020/7/02 01:46
 */
export function exportExcel(parameter) {
	return axios({
		url: '/vEnrollInfo/exportExcel',
		method: 'post',
		params: parameter,
    responseType:'blob'
	})
}

// /**
//  * Excel导出
//  *
//  * @author ymsc
//  * @date 2020/7/02 01:46
//  */
// export function exportExcel(data) {
//     return axios({
//         url: '/vEnrollInfo/exportExcel',
//         method: 'post',
//         data: data
//     })
// }

/**
 * Excel导出
 *
 * @author ymsc
 * @date 2020/7/02 01:46
 */
export function exportVoteExcel(parameter) {
	return axios({
		url: '/vEnrollInfo/exportVoteExcel',
		method: 'post',
		params: parameter,
    responseType:'blob'
	})
}