import {axios} from '@/utils/request'

/**
 * 初始化相关参数
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function initRelatedParam(parameter) {
    return axios({
        url: '/vShopInfo/initRelatedParam',
        method: 'post',
        params: parameter
    })
}

/**
 * 分页查询的数据
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vShopInfoPage(parameter) {
    return axios({
        url: '/vShopInfo/page',
        method: 'post',
        params: parameter
    })
}

/**
 * 添加
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vShopInfoAdd(data) {
    return axios({
        url: '/vShopInfo/save_data',
        method: 'post',
        data: data
    })
}

/**
 * 编辑
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vShopInfoEdit(data) {
    return axios({
        url: '/vShopInfo/edit_data',
        method: 'post',
        data: data
    })
}

/**
 * 删除
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vShopInfoDelete(data) {
    return axios({
        url: '/vShopInfo/delete',
        method: 'post',
        data: data
    })
}
/**
 * 导入
 *
 * @author YMSC
 * @date 2023-08-08
 */
export function importExcel(data) {
    return axios({
        url: '/vShopInfo/importExcel',
        method: 'get',
        params: data
    })
}