import {
	axios
} from '@/utils/request'

/**
 * 初始化相关参数
 *
 * @author YMSC
 * @date 2022-05-12
 */
export function initRelatedParam(data) {
	return axios({
		url: '/vRankInfo/initRelatedParam',
		method: 'post',
		data: data
	})
}

/**
 * 分页查询的数据
 *
 * @author YMSC
 * @date 2022-05-12
 */
export function vRankInfoPage(data) {
	return axios({
		url: '/vRankInfo/page',
		method: 'post',
		data: data
	})
}

/**
 * 添加
 *
 * @author YMSC
 * @date 2022-05-12
 */
export function vRankInfoAdd(data) {
	return axios({
		url: '/vRankInfo/save_data',
		method: 'post',
		data: data
	})
}

/**
 * 编辑
 *
 * @author YMSC
 * @date 2022-05-12
 */
export function vRankInfoEdit(data) {
	return axios({
		url: '/vRankInfo/edit_data',
		method: 'post',
		data: data
	})
}

/**
 * 删除
 *
 * @author YMSC
 * @date 2022-05-12
 */
export function vRankInfoDelete(data) {
	return axios({
		url: '/vRankInfo/delete',
		method: 'post',
		data: data
	})
}

/**
 * 获取活动排名
 *
 * @author YMSC
 * @date 2022-05-12
 */
export function getRankByActivity(data) {
	return axios({
		url: '/vRankInfo/getRankByActivity',
		method: 'post',
		data: data
	})
}

/**
 * 查找作品
 *
 * @author YMSC
 * @date 2022-05-12
 */
export function searchEnroll(data) {
	return axios({
		url: '/vRankInfo/searchEnroll',
		method: 'post',
		data: data
	})
}
