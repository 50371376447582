import { axios } from '@/utils/request'

/**
 * 分页查询系统字典类型
 *
 * @author yubaoshan
 * @date 2020/5/17 01:46
 */
export function sysRolePage (parameter) {
  return axios({
    url: '/role/list',
    method: 'post',
    params: parameter
  })
}

/**
 * 添加系统字典类型
 *
 * @author yubaoshan
 * @date 2020/5/17 01:46
 */
export function sysRoleAdd (parameter) {
  return axios({
    url: '/role/save_data',
    method: 'post',
    data: parameter
  })
}

/**
 * 编辑系统字典类型
 *
 * @author yubaoshan
 * @date 2020/5/17 01:50
 */
export function sysRoleEdit (parameter) {
  return axios({
    url: '/role/update_data',
    method: 'post',
    data: parameter
  })
}

/**
 * 删除系统字典类型
 *
 * @author yubaoshan
 * @date 2020/5/17 01:50
 */
export function roleDelete (parameter) {
  return axios({
    url: '/role/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 获取数据
 *
 * @author yubaoshan
 * @date 2020/6/10 00:10
 */
export function setRoleId (parameter) {
  return axios({
    url: '/role/authority',
    method: 'post',
    data: parameter
  })
}


export function sysRoleMenu (parameter) {
  return axios({
    url: '/role/authoritySave',
    method: 'post',
    data: parameter
  })
}
