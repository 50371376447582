import {axios} from '@/utils/request'

/**
 * 初始化相关参数
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function initRelatedParam(parameter) {
    return axios({
        url: '/vBrowseInfo/initRelatedParam',
        method: 'post',
        params: parameter
    })
}

/**
 * 分页查询的数据
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function vBrowseInfoPage(data) {
    return axios({
        url: '/vBrowseInfo/page',
        method: 'post',
        data: data
    })
}

/**
 * 添加
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function vBrowseInfoAdd(data) {
    return axios({
        url: '/vBrowseInfo/save_data',
        method: 'post',
        data: data
    })
}

/**
 * 编辑
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function vBrowseInfoEdit(data) {
    return axios({
        url: '/vBrowseInfo/edit_data',
        method: 'post',
        data: data
    })
}

/**
 * 删除
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function vBrowseInfoDelete(data) {
    return axios({
        url: '/vBrowseInfo/delete',
        method: 'post',
        data: data
    })
}

/**
 * Excel导出
 *
 * @author YMSC
 * @date 2022-05-07
 */
export function exportExcel(parameter) {
	return axios({
		url: '/vBrowseInfo/exportExcel',
		method: 'post',
		params: parameter,
    responseType:'blob'
	})
}