import {
	axios
} from '@/utils/request'

/**
 * 初始化相关参数
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function initRelatedParam(data) {
	return axios({
		url: '/vActivityInfo/initRelatedParam',
		method: 'post',
		data: data
	})
}

/**
 * 分页查询的数据
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vActivityInfoPage(data) {
	return axios({
		url: '/vActivityInfo/page',
		method: 'post',
		data: data
	})
}

/**
 * 查询指定数据
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function getVActivityInfo(data) {
	return axios({
		url: '/vActivityInfo/info',
		method: 'get',
		params: data
	})
}

/**
 * 添加
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vActivityInfoAdd(data) {
	return axios({
		url: '/vActivityInfo/save_data',
		method: 'post',
		data: data
	})
}

/**
 * 编辑
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vActivityInfoEdit(data) {
	return axios({
		url: '/vActivityInfo/edit_data',
		method: 'post',
		data: data
	})
}

/**
 * 删除
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function vActivityInfoDelete(data) {
	return axios({
		url: '/vActivityInfo/delete',
		method: 'post',
		data: data
	})
}

/**
 * 变更赛事活动状态
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function updateActivityState(data) {
	return axios({
		url: '/vActivityInfo/updateActivityState',
		method: 'post',
		data: data
	})
}

/**
 * 更新赛事活动开启关闭状态
 *
 * @author YMSC
 * @date 2022-05-05
 */
export function updateActivityEnable(data) {
	return axios({
		url: '/vActivityInfo/updateActivityEnable',
		method: 'post',
		data: data
	})
}
