export const ACCESS_TOKEN = 'Authentication_Token'
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE'
export const ALL_APPS_MENU = 'ALL_APPS_MENU'
export const DEFAULT_THEME = 'DEFAULT_THEME'
export const DEFAULT_HEADER = 'DEFAULT_HEADER'
export const DEFAULT_LAYOUT_MODE = 'DEFAULT_LAYOUT_MODE'
export const DEFAULT_COLOR = 'DEFAULT_COLOR'
export const DEFAULT_COLOR_WEAK = 'DEFAULT_COLOR_WEAK'
export const DEFAULT_FIXED_HEADER = 'DEFAULT_FIXED_HEADER'
export const DEFAULT_FIXED_SIDEMENU = 'DEFAULT_FIXED_SIDEMENU'
export const DEFAULT_FIXED_HEADER_HIDDEN = 'DEFAULT_FIXED_HEADER_HIDDEN'
export const DEFAULT_CONTENT_WIDTH_TYPE = 'DEFAULT_CONTENT_WIDTH_TYPE'
export const DEFAULT_MULTI_TAB = 'DEFAULT_MULTI_TAB'
export const DICT_TYPE_TREE_DATA = 'DICT_TYPE_TREE_DATA'

export const CONTENT_WIDTH_TYPE = {
	Fluid: 'Fluid',
	Fixed: 'Fixed'
}
