<template>
  <a-spin :spinning="confirmLoading">
    <a-anchor :affix="true" @click="handleAnchorClick" :showInkInFixed="false">
      <div class="anchor-card">
        <a-row :gutter="24" type="flex" justify="center">
          <a-col :span="3">
            <a-anchor-link href="Anchor_Basic" title="基础信息"/>
          </a-col>
          <a-col :span="3">
            <a-anchor-link href="Anchor_activity" title="小程序活动信息"/>
          </a-col>
          <a-col :span="3">
            <a-anchor-link href="Anchor_activity_xhs" title="小红书活动信息"/>
          </a-col>
          <a-col :span="3">
            <a-anchor-link href="Anchor_matter" title="事项说明"/>
          </a-col>
        </a-row>
      </div>
    </a-anchor>

    <a-form :form="form">
      <a-card id="Anchor_Basic">
        <div slot="title" class="card-slot-title">
          <a-divider
              type="vertical"
              class="divider-tag"
              style="background-color: #0077dd"
          />
          <span class="card-title">基础信息</span>
        </div>

        <a-form-item v-if="isOperate" style="display: none">
          <a-input v-decorator="['activityId']"/>
        </a-form-item>

        <!-- 图片选择器 -->
        <a-form-item
            label="预览图"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <image-upload
              :imgHttpPath="relatedParam.imgHttpPath"
              :disabled="isSee"
              v-decorator="[
              'previewUrl',
              {
                rules: [{ required: true, message: '请上传预览图！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="标题"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            has-feedback
        >
          <a-input
              placeholder="请输入标题"
              :max-length="255"
              :disabled="isSee"
              v-decorator="[
              'title',
              { rules: [{ required: true, message: '请输入标题！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="主办方"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            has-feedback
        >
          <a-input
              placeholder="请输入主办方"
              :max-length="255"
              :disabled="isSee"
              v-decorator="[
              'sponsor',
              { rules: [{ required: true, message: '请输入主办方！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="主办方联系方式"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            has-feedback
        >
          <a-input
              placeholder="请输入主办方联系方式"
              :max-length="255"
              :disabled="isSee"
              v-decorator="[
              'phone',
              {
                rules: [{ required: true, message: '请输入主办方联系方式！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="作品征集时间"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            has-feedback
        >
          <a-range-picker
              show-time
              :format="dateFormat"
              :valueFormat="dateFormat"
              :disabled="isSee"
              :allowClear="false"
              v-decorator="[
              'signDate',
              {
                rules: [
                  {
                    type: 'array',
                    required: true,
                    message: '请选择作品征集时间!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="作品评选时间"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            has-feedback
        >
          <a-range-picker
              show-time
              :format="dateFormat"
              :valueFormat="dateFormat"
              :disabled="isSee"
              :allowClear="false"
              v-decorator="[
              'voteDate',
              {
                rules: [
                  {
                    type: 'array',
                    required: true,
                    message: '请选择作品评选时间!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="获奖公布日"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            has-feedback
        >
          <a-date-picker
              show-time
              :format="dateFormat"
              :valueFormat="dateFormat"
              :disabled="isSee"
              v-decorator="[
              'publishDate',
              { rules: [{ required: true, message: '请输入获奖公布日！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="活动对象"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            has-feedback
        >
          <a-textarea
              placeholder="请输入活动对象"
              :max-length="2000"
              :disabled="isSee"
              :auto-size="{ minRows: 2, maxRows: 5 }"
              v-decorator="[
              'target',
              { rules: [{ required: true, message: '请输入活动对象！' }] },
            ]"
          />
        </a-form-item>
      </a-card>

      <a-card style="margin-top: 1.25rem" id="Anchor_activity">
        <div slot="title" class="card-slot-title">
          <a-divider
              type="vertical"
              class="divider-tag"
              style="background-color: #ffaa00"
          />
          <span class="card-title">活动介绍 小程序</span>
        </div>

        <a-form-item
            label="个性标题"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            has-feedback
        >
          <a-input
              placeholder="请输入个性标题"
              :max-length="255"
              :disabled="isSee"
              v-decorator="[
              'selfTitle',
              { rules: [{ required: true, message: '请输入标题！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="参与方式"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="participate"
              :disabled="isSee"
              v-decorator="[
              'participate',
              {
                rules: [{ required: true, message: '请输入参与方式！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="作品要求"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="worksRule"
              :disabled="isSee"
              v-decorator="[
              'worksRule',
              {
                rules: [{ required: true, message: '请输入作品要求！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="奖项设定"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="reward"
              :disabled="isSee"
              v-decorator="[
              'reward',
              {
                rules: [{ required: true, message: '请输入奖项设定！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="评选方式"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="selection"
              :disabled="isSee"
              v-decorator="[
              'selection',
              {
                rules: [{ required: true, message: '请输入评选方式！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="补充说明"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="moreInfo"
              :disabled="isSee"
              v-decorator="[
              'moreInfo',
              {
                rules: [{ required: true, message: '请输入补充说明！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>
      </a-card>

      <a-card style="margin-top: 1.25rem" id="Anchor_activity_xhs">
        <div slot="title" class="card-slot-title">
          <a-divider
              type="vertical"
              class="divider-tag"
              style="background-color: #ffaa00"
          />
          <span class="card-title">活动介绍 小红书</span>
        </div>

        <a-form-item v-if="isOperate" style="display: none">
          <a-input v-decorator="['xhs_extendId']"/>
        </a-form-item>

        <a-form-item
            label="个性标题"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            has-feedback
        >
          <a-input
              placeholder="请输入个性标题"
              :max-length="255"
              :disabled="isSee"
              v-decorator="[
              'xhs_selfTitle',
              { rules: [{ required: true, message: '请输入标题！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="参与方式"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="xhs_participate"
              :disabled="isSee"
              v-decorator="[
              'xhs_participate',
              {
                rules: [{ required: true, message: '请输入参与方式！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="作品要求"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="xhs_worksRule"
              :disabled="isSee"
              v-decorator="[
              'xhs_worksRule',
              {
                rules: [{ required: true, message: '请输入作品要求！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="奖项设定"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="xhs_reward"
              :disabled="isSee"
              v-decorator="[
              'xhs_reward',
              {
                rules: [{ required: true, message: '请输入奖项设定！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="评选方式"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="xhs_selection"
              :disabled="isSee"
              v-decorator="[
              'xhs_selection',
              {
                rules: [{ required: true, message: '请输入评选方式！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="补充说明"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="xhs_moreInfo"
              :disabled="isSee"
              v-decorator="[
              'xhs_moreInfo',
              {
                rules: [{ required: true, message: '请输入补充说明！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>
      </a-card>

      <a-card style="margin-top: 1.25rem" id="Anchor_matter">
        <div slot="title" class="card-slot-title">
          <a-divider
              type="vertical"
              class="divider-tag"
              style="background-color: #840000"
          />
          <span class="card-title">事项说明</span>
        </div>

        <a-form-item
            label="注意事项"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="matter"
              :disabled="isSee"
              v-decorator="[
              'matter',
              {
                rules: [{ required: true, message: '请输入注意事项！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>

        <a-form-item
            label="其他事项"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
        >
          <x-editor
              ref="otherMatter"
              :disabled="isSee"
              v-decorator="[
              'otherMatter',
              {
                rules: [{ required: false, message: '请输入其他事项！' }],
                initialValue: '',
              },
            ]"
          />
        </a-form-item>
      </a-card>


    </a-form>

    <div style="height: 60px">
      <div class="r">
        <div v-if="isSee">
          <a-button @click="handleCancel"> 关闭</a-button>
        </div>
        <div v-else>
          <a-button type="primary" @click="handleSubmit"> 保存</a-button>

          <a-button @click="handleCancel" style="margin-left: 40px">
            关闭
          </a-button>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import moment from "moment";
import {getVActivityInfo, vActivityInfoAdd, vActivityInfoEdit} from "@/api/modular/business/activityManage";
import xEditor from "@/components/XEditor";
import ImageUpload from "@/components/FileUpload/ImageUpload";

export default {
  components: {
    xEditor,
    ImageUpload,
  },

  data() {
    return {
      // 表单layout
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 3,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 18,
        },
      },
      offsetWrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 18,
          offset: 3,
        },
      },
      tableWrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 18,
          offset: 3,
        },
      },
      // loading
      confirmLoading: false,
      // 关联参数
      relatedParam: {},
      // 表单
      form: this.$form.createForm(this),
      // 操作区分   true：编辑   false：新增
      isOperate: false,
      // 是否查看
      isSee: false,
      // 时间格式化
      dateFormat: "YYYY-MM-DD HH:mm:ss",

      // 扩展标识
      extendIdentifiers: ['xhs'],
      // 拓展属性字段
      extendFields: ['extendId', 'selfTitle', 'participate', 'worksRule', 'reward', 'selection', 'moreInfo']
    };
  },

  methods: {
    // -> 功能业务
    // 新增初始化方法
    add() {
      // 新增时为false
      this.isOperate = false;
      // 新增时查看固化为false
      this.isSee = false;
    },

    // 编辑 || 查看 初始化方法
    operate(record, look) {
      // 编辑时为true
      this.isOperate = true;
      // 是否查看
      this.isSee = look;

      // 获取赛事活动
      getVActivityInfo({activityId: record.activityId})
          .then((res) => {

            record = res.data;

            // 扩展属性
            let extendList = record?.extendList || [];
            let extendFields = this.extendFields

            let extendObj = {};

            extendList.forEach(item => {
              // 扩展标识
              let identifier = item.identifier;
              extendFields.forEach(field => {
                extendObj[`${identifier}_${field}`] = item[field];
              })
            })

            // 赋值
            this.form.setFieldsValue({
              activityId: record.activityId,
              previewUrl: record.previewUrl,
              title: record.title,
              sponsor: record.sponsor,
              phone: record.phone,
              signDate: [record.signStartDate, record.signEndDate],
              voteDate: [record.voteStartDate, record.voteEndDate],
              publishDate: record.publishDate,
              target: record.target,
              matter: record.matter ? record.matter : null,
              otherMatter: record.otherMatter ? record.otherMatter : null,

              selfTitle:  record.selfTitle ? record.selfTitle : null,
              participate: record.participate ? record.participate : null,
              worksRule: record.worksRule ? record.worksRule : null,
              reward: record.reward ? record.reward : null,
              selection: record.selection ? record.selection : null,
              moreInfo: record.moreInfo ? record.moreInfo : null,

              ...extendObj
            });
          })


      // // 延时赋初值
      // setTimeout(() => {
      //
      //   // 扩展属性
      //   let extendList = record?.extendList || [];
      //   let extendFields = this.extendFields
      //
      //   let extendObj = {};
      //
      //   extendList.forEach(item => {
      //     // 扩展标识
      //     let identifier = item.identifier;
      //     extendFields.forEach(field => {
      //       extendObj[`${identifier}_${field}`] = item[field];
      //     })
      //   })
      //
      //   this.form.setFieldsValue({
      //     activityId: record.activityId,
      //     previewUrl: record.previewUrl,
      //     title: record.title,
      //     sponsor: record.sponsor,
      //     phone: record.phone,
      //     signDate: [record.signStartDate, record.signEndDate],
      //     voteDate: [record.voteStartDate, record.voteEndDate],
      //     publishDate: record.publishDate,
      //     target: record.target,
      //
      //     participate: record.participate ? record.participate : null,
      //     worksRule: record.worksRule ? record.worksRule : null,
      //     matter: record.matter ? record.matter : null,
      //     reward: record.reward ? record.reward : null,
      //     selection: record.selection ? record.selection : null,
      //     moreInfo: record.moreInfo ? record.moreInfo : null,
      //
      //     ...extendObj
      //   });
      // }, 100);
    },

    //ok方法  提交
    handleSubmit() {
      const {
        form: {validateFields},
      } = this;
      this.confirmLoading = true;
      // 校验
      validateFields((errors, values) => {
        // 校验通过
        if (!errors) {
          // 数据处理
          let data = this.processData(values);

          // 编辑
          if (this.isOperate) {
            // 调用编辑接口
            vActivityInfoEdit(data)
                .then((res) => {
                  // 返回成功
                  if (res.success) {
                    this.$message.success("编辑成功");
                    this.$emit("ok", data);
                    // 重置数据
                    this.reset();

                    // 返回失败
                  } else {
                    this.$warning({
                      okText: "确定",
                      title: "提示信息",
                      content: "编辑失败，原因：" + res.message,
                    });
                  }
                  // 异常
                })
                .catch((err) => {
                  this.$error({
                    okText: "确定",
                    title: "提示信息",
                    content: "编辑失败，原因：" + err.response.data.message,
                  });
                  // finally
                })
                .finally(() => {
                  this.confirmLoading = false;
                });

            // 新增
          } else {
            // 调用新增接口
            vActivityInfoAdd(data)
                .then((res) => {
                  // 返回成功
                  if (res.success) {
                    this.$message.success("新增成功");
                    this.$emit("ok", data);
                    // 重置数据
                    this.reset();
                    // 返回失败
                  } else {
                    this.$warning({
                      okText: "确定",
                      title: "提示信息",
                      content: "新增失败，原因：" + res.message,
                    });
                  }
                  // 异常
                })
                .catch((err) => {
                  this.$error({
                    okText: "确定",
                    title: "提示信息",
                    content: "新增失败，原因：" + err.response.data.message,
                  });
                  // finally
                })
                .finally(() => {
                  this.confirmLoading = false;
                });
          }

          // 校验不通过
        } else {
          this.confirmLoading = false;
        }
      });
    },

    // 处理数据
    processData(values) {
      let extendList = [];
      let extendIdentifiers = this.extendIdentifiers;
      let extendFields = this.extendFields
      extendIdentifiers.forEach(identifier => {
        let extendObj = {};
        extendObj.identifier = identifier;
        extendFields.forEach(field => {
          extendObj[field] = values[`${identifier}_${field}`];
        })
        extendList.push(extendObj);
      })

      // 声明  时间为必填项  无需特殊判断
      let data = {
        ...values,
        // 作品征集时间
        signStartDate: values["signDate"].at(0),
        signEndDate: values["signDate"].at(1),
        // 作品评选时间
        voteStartDate: values["voteDate"].at(0),
        voteEndDate: values["voteDate"].at(1),

        extendList: extendList
      };

      // 返回
      return data;
    },

    // ---> 基础功能
    // 关联参数初始化
    initRelatedParam(relatedParam) {
      this.relatedParam = relatedParam;
      this.confirmLoading = false;
    },

    // 取消、关闭Modal
    handleCancel() {
      this.$emit("no", {});
      this.confirmLoading = false;
      this.reset();
    },

    // 重置数据
    reset() {
      // 表单
      this.form.resetFields();

      // 富文本清空
      this.$refs["matter"].clear();
      this.$refs["otherMatter"].clear();
      // 小程序
      this.$refs["participate"].clear();
      this.$refs["worksRule"].clear();
      this.$refs["reward"].clear();
      this.$refs["selection"].clear();
      this.$refs["moreInfo"].clear();
      // 小红书
      this.$refs["xhs_participate"].clear();
      this.$refs["xhs_worksRule"].clear();
      this.$refs["xhs_reward"].clear();
      this.$refs["xhs_selection"].clear();
      this.$refs["xhs_moreInfo"].clear();

      // 标志
      this.isOperate = false;
      this.isSee = false;
    },

    // 日期控件默认时分秒
    momentTime(time, format) {
      return moment(time, format);
    },

    // 日期禁用
    disabledDate(current) {
      // moment(this.relatedParam.today)
      return current < moment().subtract(1, "day");
    },

    // 快速跳转
    handleAnchorClick(e, link) {
      // 阻止点击的默认事件修改路由
      e.preventDefault();
      var srcolls = document.getElementById(link.href);
      srcolls.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .r {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 70px;
  background-color: #ededed;
  left: 125px;
  text-align: center;
  padding: 20px 0;
  z-index: 99;
}

/deep/ .bottom {
  width: 30%;
  height: 37px;
  font-size: 16px;
  font-weight: 700;
  position: fixed;
  bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  left: 40%;
  z-index: 99;
}

/deep/ .ant-card-head {
  border-bottom: none;
}

/deep/ .divider-tag {
  width: 0.3rem;
  height: 1rem;
}

/deep/ .ant-anchor-link-title {
  font-size: 17px;
  font-weight: 900;
}

/deep/ .card-title {
  font-size: 19px;
  font-weight: 900;
}

/deep/ .ant-card-body {
  .ant-table-body {
    min-height: 0.0625rem;
  }

  .ant-table-placeholder {
    margin-top: 0;
    border: 1px solid #e8e8e8;
  }
}

/deep/ .ant-anchor-wrapper {
  padding: 0.2rem;

  .anchor-card {
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 0.625rem;
    font-size: 1rem;
  }

  .ant-anchor-ink {
    height: 0;
  }

  .ant-anchor-ink-ball.visible {
    display: none;
  }
}

/deep/ .card-slot-title {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}
</style>
