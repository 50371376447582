import {
	axios
} from '@/utils/request'
/**
 *
 * mian
 *
 * @author YMSC
 * @date 2020/5/26 19:06
 */
export function main(parameter) {
	return axios({
		url: '/main',
		method: 'get',
		data: parameter
	})
}

/**
 * log out
 *
 * @author YMSC
 * @date 2021/01/27 16:06
 */
export function logout(parameter) {
	return axios({
		url: '/logout',
		method: 'get',
		data: parameter
	})

}
