import Vue from 'vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import Hello from '@/components/HelloWorld'
import Login from '@/views/login'
import Success from '@/views/main'
import Error from '@/views/error'
// home
import home from '@/views/home'

// system
import dict from '@/views/system/dict'
import log from '@/views/system/log'
import account from '@/views/system/account'
import area from '@/views/system/area'
import menu from '@/views/system/menu'
import role from '@/views/system/role'
import wechatsetting from '@/views/system/wechatSetting'
// business
import reports from '@/views/business/reports'
import competition from '@/views/business/competition'
import wxuser from '@/views/business/wxUser'
import statistics from '@/views/business/statistics'
import shops from '@/views/business/shops'
import rank from '@/views/business/rank'

Vue.use(Router)
Vue.use(Vuex)

//解决 Vue 重复点击相同路由问题
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}

export default new Router({
	// mode: 'history',
	routes: [{
			path: '/hello',
			name: 'hello',
			component: Hello
		},
		{
			path: '/',
			name: 'login',
			component: Login
		},
		{
			path: '/main',
			name: 'main',
			component: Success,
			meta: {
				requireAuth: true
			},
			children: [
				// home
				{
					path: 'home',
					component: home
				},
				// system
				{
					path: 'account',
					component: account
				},
				{
					path: 'role',
					component: role
				},
				{
					path: 'dict',
					component: dict
				},
				{
					path: 'menu',
					component: menu
				},
				{
					path: 'log',
					component: log
				},
				{
					path: 'area',
					component: area
				},
				{
					path: 'wechatsetting',
					component: wechatsetting
				},
				// business
				{
					path: 'reports',
					component: reports
				},
				{
					path: 'competition',
					component: competition
				},
				{
					path: 'wxuser',
					component: wxuser
				},
				{
					path: 'statistics',
					component: statistics
				},
				{
					path: 'shops',
					component: shops
				},
				{
					path: 'rank',
					component: rank
				},
			]
		},
		{
			path: '/error',
			name: 'error',
			component: Error
		}
	]
})
