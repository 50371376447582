<template>
	<a-modal title="编辑菜单" :width="900" :visible="visible" :maskClosable="false" :confirmLoading="confirmLoading" okText="修改" cancelText="关闭"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-form :form="form">

				<a-form-item label="菜单编码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :disabled='true' v-decorator="['menuCode', {rules: [{required: true, message: ''}]}]" />
				</a-form-item>

				<a-form-item label="菜单名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入菜单名称"
						v-decorator="['name', {rules: [{required: true, message: '请输入菜单名称！'}]}]" />
				</a-form-item>

				<a-form-item label="父菜单编码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :disabled='true' style="width: 100%;"
						v-decorator="['parentMenuCode', {rules: [{required: true, message: ''}]}]" />
				</a-form-item>

				<a-form-item label="地址" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :disabled='true' style="width: 100%;"
						v-decorator="['url', {rules: [{required: true, message: ''}]}]" />
				</a-form-item>
				<a-form-item label="级别" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :disabled='true' style="width: 100%;"
						v-decorator="['level', {rules: [{required: true, message: ''}]}]" />
				</a-form-item>
				<a-form-item label="路径" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :disabled='true' style="width: 100%;"
						v-decorator="['path', {rules: [{required: true, message: ''}]}]" />
				</a-form-item>
				<a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input placeholder="请输入排序数字"
						v-decorator="['sort', {rules: [{required: true, message: '请输入排序数字！'}]}]" />
				</a-form-item>
				<a-form-item label="图片" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-input :disabled='true' style="width: 100%;"
						v-decorator="['icon', {rules: [{required: false, message: ''}]}]" />
				</a-form-item>
				<a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-textarea :rows="4" placeholder="请输入备注" v-decorator="['memo']"></a-textarea>
				</a-form-item>

			</a-form>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		updateMenu
	} from '@/api/modular/system/menuManage.js'

	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this)
			}
		},

		methods: {
			// 初始化方法
			edit(record) {
				this.visible = true

				// 表单数据初始化
				setTimeout(() => {
					this.form.setFieldsValue({
						name: record.name,
						menuCode: record.menuCode,
						parentMenuCode: record.parentMenuCode,
						level: record.level,
						icon: record.icon,
						memo: record.memo,
						path: record.path,
						sort: record.sort,
						url: record.url,
						// updatedDateTime: record.updatedDateTime,
						// updatedUserId: record.updatedUserId,
						// updatedUserName: record.updatedUserName,
					})
				}, 100)
			},

			// 提交
			handleSubmit() {
				const {
					form: {
						validateFields
					}
				} = this
				this.confirmLoading = true
				validateFields((errors, values) => {

					if (!errors) {
						updateMenu(values).then((res) => {
							if (res.success) {
								this.$message.success('编辑成功')
								this.visible = false
								this.confirmLoading = false
								this.$emit('ok', values)
								this.form.resetFields()
							} else {
								this.$message.error('编辑失败：' + res.message)
								this.confirmLoading = false
							}
						}).error(() => {
							console.log("error")
							this.confirmLoading = false
						}).finally(() => {
							this.confirmLoading = false
						})
					} else {
						this.confirmLoading = false
					}
				})
				this.confirmLoading = false;
			},

			// 取消、关闭对话框
			handleCancel() {
				this.form.resetFields()
				this.visible = false
			},

		}
	}
</script>
