import {
	axios
} from '@/utils/request'

/**
 * 分页查询省市区列表
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function getAreaPage(parameter) {
	return axios({
		url: '/area/page',
		method: 'post',
		params: parameter
	})
}

/**
 * 初始化省级下拉菜单
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function initProvinceBoxs() {
	return axios({
		url: '/area/initProvinceBox',
		method: 'post'
	})
}

/**
 * 根据ID查询省级下拉菜单
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function initProvinceBoxsById(parameter) {
	return axios({
		url: '/area/initProvinceBoxById',
		method: 'post',
		params: parameter
	})
}

/**
 * 根据省Id获取市级列表
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function changeCityBox(parameter) {
	return axios({
		url: '/area/changeCityBox',
		method: 'post',
		params: parameter
	})
}

/**
 * 根据市Id获取区级列表
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function changeDistrictBox(parameter) {
	return axios({
		url: '/area/changeDistrictBox',
		method: 'post',
		params: parameter
	})
}

/**
 * 根据省市区Id获取综合体列表
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function changeComplexBox(parameter) {
	return axios({
		url: '/area/changeComplexBox',
		method: 'post',
		params: parameter
	})
}

/**
 * 获取市级列表
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function getManageArea(parameter) {
	return axios({
		url: '/area/getManageArea',
		method: 'post',
		params: parameter
	})
}


/**
 * 省份新增
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function saveProvince(parameter) {
	return axios({
		url: '/area/provinceSaveData',
		method: 'post',
		data: parameter
	})
}

/**
 * 市新增
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function saveCity(parameter) {
	return axios({
		url: '/area/citySaveData',
		method: 'post',
		data: parameter
	})
}

/**
 * 区新增
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function saveDistrict(parameter) {
	return axios({
		url: '/area/disSaveData',
		method: 'post',
		data: parameter
	})
}

/**
 * 获取编辑数据
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function initEditData(parameter) {
	return axios({
		url: '/area/initEditData',
		method: 'post',
		params: parameter
	})
}

/**
 * 省份编辑保存
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function updateProvince(parameter) {
	return axios({
		url: '/area/proUpdate_data',
		method: 'post',
		data: parameter
	})
}

/**
 * 市编辑保存
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function updateCity(parameter) {
	return axios({
		url: '/area/cityUpdate_data',
		method: 'post',
		data: parameter
	})
}

/**
 * 区编辑保存
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function updateDistrict(parameter) {
	return axios({
		url: '/area/disUpdate_data',
		method: 'post',
		data: parameter
	})
}

/**
 * 初始化树结构省级数据
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function initProvinceTree() {
	return axios({
		url: '/area/initProvinceTree',
		method: 'post',
	})
}

/**
 * 获取结构化市级数据
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function getCityTree(parameter) {
	return axios({
		url: '/area/getCityTree',
		method: 'post',
		params: parameter
	})
}

/**
 * 获取结构化区级数据
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function getDistrictTree(parameter) {
	return axios({
		url: '/area/getDistrictTree',
		method: 'post',
		params: parameter
	})
}

/**
 * 删除省市区
 *
 * @author ymsc
 * @date 2021/06/05 12:08
 */
export function deleteArea(parameter) {
	return axios({
		url: '/area/deleteArea',
		method: 'post',
		params: parameter
	})
}
