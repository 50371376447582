import { axios } from '@/utils/request'

/**
 * 分页查询模板一览
 *
 * @author ymsc
 * @date 2021/7/09
 */
export function getMenuPage(parameter) {
	return axios({
		url: '/menu/list',
		method: 'post',
		params: parameter
	})
}
/**
 * 编辑系统字典类型
 *
 * @author ymsc
 * @date 2021/7/09 
 */
export function updateMenu (parameter) {
  return axios({
    url: '/menu/update_data',
    method: 'post',
    data: parameter
  })
}



