<template>
	<a-card :bordered="false">
		<div class="table-operator">
			<a-button type="primary" icon="plus" @click="$refs.addForm.add()">增加角色</a-button>
		</div>

		<s-table bordered ref="table" size="default" :columns="columns" :data="loadData" :alert="false" :scroll="scroll"
			rowKey="roleId" :customRow="selectRow" :rowClassName="rowClassName">
			<span slot="status" slot-scope="text">
				{{ statusFilter(text) }}
			</span>

			<span slot="action" slot-scope="text, record">
				<a @click="$refs.editForm.edit(record)">编辑</a>
				<a-divider type="vertical" />
				<a @click="delRole(record)">删除</a>
				<a-divider type="vertical" />
				<a @click="$refs.authority.setRole(record)">权限配置</a>
			</span>

		</s-table>

		<add-form ref="addForm" @ok="handleOk" />
		<edit-form ref="editForm" @ok="handleOk" />
		<authority ref="authority" @ok="handleOk" />

	</a-card>
</template>

<script>
	import {
		STable
	} from '@/components'
	import {
		sysRolePage,
		roleDelete
	} from '@/api/modular/system/roleManage'
	import addForm from './addForm'
	import editForm from './editForm'
	import authority from './authority'

	export default {
		components: {
			STable,
			addForm,
			editForm,
			authority
		},

		data() {
			return {
				// 高级搜索 false: 默认收取 true: 默认关闭
				advanced: false,
				//行选中Id
				selectRowId: '',
				// 查询参数
				queryParam: {},
				//表格scroll
				scroll: {
					x: true,
					y: 397,
				},
				// 表头
				columns: [{
						title: '序号',
						dataIndex: 'no',
						width: 90,
						align: 'center'
					},
					{
						title: '角色名称',
						dataIndex: 'name',
						align: 'center',
						width: 220,
					},
					{
						title: '角色描述',
						dataIndex: 'memo',
					},
					{
						title: '操作',
						width: 200,
						align: 'center',
						dataIndex: 'action',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],

				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					return sysRolePage(Object.assign(parameter, this.queryParam)).then((res) => {
						let pageNo = res.data.pageNo;
						let pageSize = res.data.pageSize;
						res.data.rows.forEach((item, index) => {
							item.no = (pageNo - 1) * pageSize + index + 1;
						});
						return res.data
					})
				},
				statusDict: []
			}
		},
		created() {

		},

		methods: {

			statusFilter(status) {
				// eslint-disable-next-line eqeqeq
				const values = this.statusDict.filter(item => item.code == status)
				if (values.length > 0) {
					return values[0].value
				}
			},

			toggleAdvanced() {
				this.advanced = !this.advanced
			},

			handleOk() {
				this.$refs.table.refresh()
			},

			delRole(record) {
				this.$confirm({
					title: '删除确认',
					content: '您是否要删除吗？',
					okText: '确定',
					cancelText: '取消',
					onOk: () => {
						roleDelete(record).then((res) => {
							if (res.success) {
								this.$message.success('删除成功')
								this.$refs.table.refresh()
							} else {
								this.$message.error('删除失败：' + res.message)
							}
						}).catch((err) => {
							this.$message.error('删除错误：' + err.message)
						})
					},
					onCancel() {}
				})
			},

			selectRow(record) {
				return {
					props: {},
					on: {
						click: () => {
							this.selectRowId = record.roleId
						},
					}
				}
			},

			// 点击行变色 class
			rowClassName(record) {
				return record.roleId === this.selectRowId ? 'selectRow' : '';
			},
		}
	}
</script>

<style scoped lang="less">
</style>
