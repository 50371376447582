<template>

	<a-spin :spinning="confirmLoading">
		<a-form :form="form">

			<a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol">
				<a-input v-decorator="['enrollId']" />
			</a-form-item>

			<!-- <a-descriptions bordered>
        <a-descriptions-item label="综合得分">
          {{param.score}}分
        </a-descriptions-item>
        <a-descriptions-item label="最终赛事排名">
          第{{param.ranking}}名
        </a-descriptions-item>
      </a-descriptions> -->

			<a-descriptions bordered title="报名信息" style="margin-top: 20px;">
				<a-descriptions-item label="参赛用户" :span="2">
					{{param.name}}
				</a-descriptions-item>
				<a-descriptions-item label="性别" :span="2">
					{{param.sexName}}
				</a-descriptions-item>
				<a-descriptions-item label="年龄" :span="2">
					{{param.ageName}}
				</a-descriptions-item>
				<a-descriptions-item label="手机" :span="2">
					{{param.phone}}
				</a-descriptions-item>
				<a-descriptions-item label="邮箱" :span="2">
					{{param.email}}
				</a-descriptions-item>
				<a-descriptions-item label="购买时间" :span="2">
					{{param.payTime}}
				</a-descriptions-item>
				<a-descriptions-item label="所在城市" :span="2">
					{{param.cityName}}
				</a-descriptions-item>
				<a-descriptions-item label="购买店铺" :span="2">
					{{param.buyShop}}
				</a-descriptions-item>
				<a-descriptions-item label="服务店员" :span="2">
					{{param.payShopClerkName}}
				</a-descriptions-item>
				<a-descriptions-item label="购买种类" :span="2">
					{{param.payTypeName}}
				</a-descriptions-item>
				<a-descriptions-item label="使用感想" :span="2">
					{{param.experience}}
				</a-descriptions-item>
				<a-descriptions-item label="职业" :span="2">
					{{param.vocationName}}
				</a-descriptions-item>
				<a-descriptions-item label="参与平台" :span="2">
					{{param.platformName}}
				</a-descriptions-item>
				<a-descriptions-item label="参与平台ID" :span="2">
					{{param.platformId}}
				</a-descriptions-item>
				<a-descriptions-item label="了解骊住的途径" :span="2">
					{{param.wayName}}
				</a-descriptions-item>
				<!--        <a-descriptions-item label="如何购买建材商品">
          {{param.materialName}}
        </a-descriptions-item> -->
				<a-descriptions-item label="您选择骊住商品时重视什么" :span="2">
					{{param.pleasedName}}
				</a-descriptions-item>
				<a-descriptions-item label="对骊住品牌方面的意见" :span="2">
					{{param.proposal}}
				</a-descriptions-item>
				<!--     <a-descriptions-item label="您会向其他人推荐骊住吗">
          {{param.recommendName}}
        </a-descriptions-item> -->
				<!-- <a-descriptions-item label="票数" :span="2">
					{{param.votesNum}}票
				</a-descriptions-item> -->
				<a-descriptions-item label="审核状态" :span="2">
					{{param.verifyStateName}}
				</a-descriptions-item>
				<a-descriptions-item label="参赛作品">
					<div class="clearfix">
						<a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" list-type="picture-card"
							:file-list="matchImgUrl" @preview="handlePreview" @change="handleChange"
							:showUploadList="{showRemoveIcon:false}">
						</a-upload>
						<a-modal :visible="previewVisible" :footer="null" @cancel="handleCanceImg">
							<img alt="example" style="width: 100%" :src="previewImage" />
						</a-modal>
					</div>
				</a-descriptions-item>
			</a-descriptions>

			<!-- <a-descriptions bordered title="活动信息" style="margin-top: 20px;">
        <a-descriptions-item label="活动名称" :span="3">
          {{param.title}}
        </a-descriptions-item>
        <a-descriptions-item label="活动状态" :span="2">
          {{param.activityStateName}}
        </a-descriptions-item>
        <a-descriptions-item label="活动开启状态">
          {{param.enableName}}
        </a-descriptions-item>
        <a-descriptions-item label="主办方名称" :span="2">
          {{param.sponsor}}
        </a-descriptions-item>
        <a-descriptions-item label="主办方电话">
          {{param.sponsorPhone}}
        </a-descriptions-item>
        <a-descriptions-item label="作品征集时间" :span="2">
          {{param.signStartDate}} ~ {{param.signEndDate}}
        </a-descriptions-item>
        <a-descriptions-item label="作品评选时间">
          {{param.voteStartDate}} ~ {{param.voteEndDate}}
        </a-descriptions-item>
        <a-descriptions-item label="作品要求" :span="3">
          <div v-html="param.worksRule"></div>
        </a-descriptions-item>
        <a-descriptions-item label="注意事项" :span="3">
          <div v-html="param.matter"></div>
        </a-descriptions-item>
        <a-descriptions-item label="奖项设定" :span="3">
          <div v-html="param.reward"></div>
        </a-descriptions-item>
        <a-descriptions-item label="评选方式" :span="3">
          <div v-html="param.selection"></div>
        </a-descriptions-item>
        <a-descriptions-item label="补充说明" :span="3">
          <div v-html="param.moreInfo"></div>
        </a-descriptions-item>

      </a-descriptions> -->

			<a-modal v-model="remarksvisible" title="审核意见" @ok="handleOk">
				<a-form-item label="意见" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
					<a-textarea :max-length="500" :rows="4" placeholder="请输入意见"
						v-decorator="['verifyIdea']"></a-textarea>
				</a-form-item>
			</a-modal>
		</a-form>
		<div style="height: 60px;">
			<div class="r">
				<a-button type="primary" @click="handlePass" v-if="verifyState == 0">
					审核通过
				</a-button>
				<a-button type="danger" @click="handleRefuse" style="margin-left: 40px;" v-if="verifyState == 0">
					审核拒绝
				</a-button>
				<a-button @click="handleCancel" style="margin-left: 40px;">
					返回
				</a-button>
			</div>
		</div>
	</a-spin>
</template>

<script>
	import {
		vEnrollInfoEdit
	} from '@/api/modular/business/vEnrollInfoManage'

	function getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}
	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 15
					}
				},
				disabled: true,
				param: {},
				size: '1',
				roles: [],
				matchImgUrl: [],
				htmlvalue: '',
				remarksvisible: false,
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this),
				previewVisible: false,
				previewImage: '',
				verifyState: 0
			}
		},

		methods: {
			// 初始化方法
			detailed(record, imgPath) {
				this.confirmLoading = true
				this.visible = true
				this.verifyState = record.verifyState
				this.enrollId = record.enrollId
				//参赛图片
				if (record.matchImgUrl != null) {
					let arr = [];
					arr = (record.matchImgUrl).split(';');
					for (var i = 0; i < arr.length; i++) {
						var vote = {};
						vote.uid = i + 1;
						vote.name = 'image.png',
							vote.status = 'done',
							vote.url = imgPath + arr[i];
						this.matchImgUrl.push(vote);
					}
				}
				//赋值
				this.param = {
					score: record.score,
					ranking: record.ranking,
					title: record.title,
					sponsor: record.sponsor,
					sponsorPhone: record.sponsorPhone,
					signStartDate: record.signStartDate,
					signEndDate: record.signEndDate,
					voteStartDate: record.voteStartDate,
					voteEndDate: record.voteEndDate,
					worksRule: record.worksRule,
					moreInfo: record.moreInfo,
					selection: record.selection,
					reward: record.reward,
					matter: record.matter,
					activityStateName: record.activityStateName,
					enableName: record.enableName,
					name: record.name,
					sexName: record.sexName,
					ageName: record.ageName,
					phone: record.phone,
					email: record.email,
					payTime: record.payTime,
					cityName: record.cityName,
					buyShop: record.shopName,
					payTypeName: record.payTypeName,
					experience: record.experience,
					vocationName: record.vocationName,
					wayName: record.wayName,
					platformName: record.platformName,
					platformId: record.platformId,
					materialName: record.materialName,
					pleasedName: record.pleasedName,
					proposal: record.proposal,
					recommendName: record.recommendName,
					votesNum: record.votesNum,
					verifyStateName: record.verifyStateName,
          // -- 20241014 追加服务店员
          payShopClerkName: record.payShopClerkName,
				}
				this.confirmLoading = false
			},
			//审核拒绝弹出对话框
			handleRefuse() {
				this.remarksvisible = true;
			},
			//审核通过更新状态
			handlePass() {
				//联动查询省级下市列表
				let param = {
					enrollId: this.enrollId,
					verifyState: 1
				}
				//查询下级市列表
				vEnrollInfoEdit(param).then(res => {
					if (res.success) {
						this.$message.success('更新成功');
						this.$emit('ok', param)
						this.matchImgUrl = []
						this.confirmLoading = false;
						// 表单
						this.form.resetFields();
					}
				})
			},

			//关闭图片
			handleCanceImg() {
				this.previewVisible = false;
			},

			// 预览
			async handlePreview(file) {
				if (!file.url && !file.preview) {
					file.preview = await getBase64(file.originFileObj);
				}

				this.previewImage = file.url || file.preview;
				this.previewVisible = true;
			},

			//图片数据
			handleChange({
				fileList
			}) {
				this.fileList = fileList;
			},

			//审核聚拒绝
			handleOk() {
				var that = this
				const {
					form: {
						validateFields
					}
				} = that;
				that.confirmLoading = true

				validateFields((errors, values) => {
					values.enrollId = this.enrollId,
						values.verifyState = 2
					if (!errors) {
						vEnrollInfoEdit(values).then((res) => {
							if (res.success) {
								that.$message.success('编辑成功')
								that.visible = false
								that.remarksvisible = false
								that.confirmLoading = false
								that.$emit('ok', values)
								that.form.resetFields()
								that.matchImgUrl = []
							} else {
								that.$message.error('编辑失败：' + res.message)
								that.confirmLoading = false
							}
						}).finally(() => {
							that.confirmLoading = false
						})
					} else {
						that.confirmLoading = false
					}
				})
				this.confirmLoading = false;
			},

			// 取消、关闭
			handleCancel() {
				this.$emit('no', {});
				// this.visible = false;
				this.confirmLoading = false;
				this.form.resetFields();
				this.matchImgUrl = []
			}
		}
	}
</script>

<style scoped lang="less">
	/* you can make up upload button and sample style by using stylesheets */
	/deep/.ant-upload-select-picture-card i {
		font-size: 32px;
		color: #999;
	}

	/deep/.ant-upload-select-picture-card .ant-upload-text {
		margin-top: 8px;
		color: #666;
	}

	/deep/.ant-modal {
		top: 60px !important;
		width: 46% !important;
	}

	/deep/.r {
		position: fixed;
		bottom: 0px;
		width: 100%;
		height: 70px;
		background-color: #ededed;
		left: 125px;
		text-align: center;
		padding: 20px 0;
		z-index: 99;
	}
</style>
