<template>
  <a-card :bordered="false">
    <transition name="trans">
      <div v-show="!visible">
        <modal ref="modal" @ok="handleOk" @no="visabledModal" />
      </div>
    </transition>

    <transition name="trans">
      <div v-show="visible">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :sm="24" :md="8">
                <a-form-item label="标题">
                  <a-input
                    v-model="queryParam.title"
                    placeholder="请输入标题"
                    :max-length="50"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="24" :md="8">
                <a-form-item label="状态">
                  <a-select
                    v-model="queryParam.state"
                    allow-clear
                    placeholder="请选择状态"
                    default-value=""
                  >
                    <a-select-option
                      v-for="item in relatedParam.activityStates"
                      :key="item.dictCode"
                      :value="item.dictCode"
                    >
                      {{ item.dictValue }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!-- <template v-if="advanced">
								<a-col :md="8" :sm="24">
									<a-form-item label="征集时间">
										<a-range-picker :format="dateFormat" v-model="queryParam.signDate"
											style="width: 100%;" />
									</a-form-item>
								</a-col>

								<a-col :md="8" :sm="24">
									<a-form-item label="评选时间">
										<a-range-picker :format="dateFormat" v-model="queryParam.voteDate"
											style="width: 100%;" />
									</a-form-item>
								</a-col>
							</template> -->
            </a-row>
          </a-form>
        </div>

        <div class="table-operator">
          <a-row :gutter="16">
            <a-col :sm="24" :md="17">
              <a-button type="primary" icon="plus" @click="visabledModal('add')"
                >增加</a-button
              >
            </a-col>
            <a-col :sm="24" :md="7" style="text-align: right">
              <span
                class="table-page-search-submitButtons"
                :style="
                  (advanced && { float: 'right', overflow: 'hidden' }) || {}
                "
              >
                <a-button type="primary" @click="onSearch">查询</a-button>
                <a-button
                  style="margin-left: 8px"
                  @click="() => (queryParam = {})"
                  >重置</a-button
                >
                <a
                  v-if="queryRows.count > 1"
                  @click="toggleAdvanced"
                  style="margin-left: 8px"
                >
                  {{ advanced ? "收起" : "展开" }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </div>

        <s-table
          ref="table"
          bordered
          size="default"
          :columns="columns"
          :data="loadData"
          :alert="false"
          rowKey="activityId"
          :scroll="scroll"
          :customRow="selectRow"
          :rowClassName="rowClassName"
          :style="operaHigh"
        >
          <!-- 预览图 -->
          <template slot="previewUrl" slot-scope="text">
            <a :href="previewSrc(text)" target="_blank" v-if="text">
              <img
                :src="previewSrc(text)"
                alt=""
                style="width: 50px; height: 30px"
              />
            </a>
          </template>

          <!-- 文字提示 -->
          <template slot="titleTooltip" slot-scope="text">
            <a-tooltip
              placement="topLeft"
              :mouseEnterDelay="0.15"
              overlayClassName="general_tooltip"
            >
              <template slot="title">
                {{ text }}
              </template>
              <div class="multi-ellipsis">{{ text }}</div>
            </a-tooltip>
          </template>

          <!-- 报名时间 -->
          <template slot="signDate" slot-scope="text, record">
            <span>{{ record.signStartDate }}</span>
            <span> ~ </span>
            <span>{{ record.signEndDate }}</span>
          </template>

          <!-- 投票时间 -->
          <template slot="voteTime" slot-scope="text, record">
            <span>{{ record.voteStartDate }}</span>
            <span> ~ </span>
            <span>{{ record.voteEndDate }}</span>
          </template>

          <!-- 操作列 -->
          <template slot="action" slot-scope="text, record">
            <div style="text-align: right">
              <span v-if="relatedParam.enable && record.enable == null">
                <a @click="updateActivityEnable(record, 0, '开启')">开启</a>
                <a-divider type="vertical" />
              </span>

              <span v-else-if="record.enable == 0">
                <a @click="updateActivityEnable(record, 1, '关闭')">关闭</a>
                <a-divider type="vertical" />
              </span>

              <a @click="visabledModal('operate', record, true)">查看</a>
              <a-divider type="vertical" />

              <a-dropdown>
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                  更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <!-- 开启结束不可编辑 -->
                  <!-- <a-menu-item v-if="record.enable == null">
										<a @click="visabledModal('operate', record, false)">编辑</a>
									</a-menu-item> -->
                  <!-- 非结束可编辑 -->
                  <a-menu-item v-if="record.enable != 1">
                    <a @click="visabledModal('operate', record, false)">编辑</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="activityDelete(record)">删除</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </template>
        </s-table>
      </div>
    </transition>
  </a-card>
</template>

<script>
import { STable } from "@/components";
import {
  initRelatedParam,
  vActivityInfoPage,
  vActivityInfoDelete,
  updateActivityEnable,
} from "@/api/modular/business/activityManage";
import modal from "./modal";

export default {
  components: {
    STable,
    modal,
  },

  data() {
    return {
      // 主页可视化
      visible: true,
      // 高级搜索  false: 收取   true: 展开
      advanced: false,
      //行选中key
      selectRowKey: "",
      //关联参数
      relatedParam: {},
      // 查询参数
      queryParam: {},
      // 检索参数区行数
      queryRows: {
        count: 1,
        current: 1,
      },
      // 时间格式化
      dateFormat: "YYYY-MM-DD",
      //表格scroll
      scroll: {
        x: true,
        y: 397,
      },
      // 表头
      columns: [
        {
          title: "序号",
          dataIndex: "no",
          width: 95,
          align: "center",
        },
        {
          dataIndex: "previewUrl",
          title: "预览图",
          align: "center",
          width: 95,
          scopedSlots: {
            customRender: "previewUrl",
          },
        },
        {
          dataIndex: "title",
          title: "标题",
          width: 200,
          scopedSlots: {
            customRender: "titleTooltip",
          },
        },
        {
          dataIndex: "sponsor",
          title: "主办方",
          align: "center",
          width: 150,
        },
        {
          dataIndex: "phone",
          title: "主办方联系方式",
          align: "center",
          width: 150,
        },
        {
          dataIndex: "signDate",
          title: "作品征集时间",
          align: "center",
          width: 310,
          scopedSlots: {
            customRender: "signDate",
          },
        },
        {
          dataIndex: "voteTime",
          title: "作品评选时间",
          align: "center",
          width: 310,
          scopedSlots: {
            customRender: "voteTime",
          },
        },
        {
          dataIndex: "publishDate",
          title: "获奖公布日",
          align: "center",
          width: 165,
        },
        {
          dataIndex: "stateName",
          title: "状态",
          align: "center",
          width: 110,
        },
        {
          title: "操作",
          width: 175,
          align: "center",
          dataIndex: "action",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        // 接口
        return vActivityInfoPage(
          Object.assign(parameter, this.queryParam)
        ).then((res) => {
          // 填充序号
          res.data.rows.forEach((item, index) => {
            item.no = (res.data.pageNo - 1) * res.data.pageSize + index + 1;
          });
          // 返回page
          return res.data;
        });
      },
      // 操作者数据
      operator: {
        userId: localStorage.getItem("userId"),
      },
    };
  },

  // created钩子函数
  created() {
    this.initRelatedParam();
  },

  // 计算属性
  computed: {
    // 预览图全路径
    previewSrc() {
      return (val) => {
        return this.relatedParam.imgHttpPath + val;
      };
    },

    // 表格头部功能区高度
    operaHigh() {
      return {
        "--operaHigh": this.queryRows.current * 56 + 268 + "px",
      };
    },
  },

  // 方法函数
  methods: {
    // --- > 功能业务
    // 初始化关联参数
    initRelatedParam() {
      initRelatedParam({})
        .then((res) => {
          // 成功
          if (res.success) {
            // 关联参数取得
            this.relatedParam = res.data;
            // // 初始化子组件关联参数
            this.$refs.modal.initRelatedParam(res.data);
          } else {
            this.$error({
              okText: "确定",
              title: "提示信息",
              content: "请求关联参数失败，原因：" + res.message,
            });
          }
        })
        .catch((err) => {
          this.$error({
            okText: "确定",
            title: "提示信息",
            content: "请求关联参数失败，原因：" + err.response.data.message,
          });
        });
    },

    // 查询
    onSearch() {
      // 征集时间
      if (this.queryParam["signDate"]) {
        this.queryParam.signStartDate = this.queryParam["signDate"].at(0);
        this.queryParam.signEndDate = this.queryParam["signDate"].at(1);
      }
      // 评选时间
      if (this.queryParam["voteDate"]) {
        this.queryParam.voteStartDate = this.queryParam["voteDate"].at(0);
        this.queryParam.voteEndDate = this.queryParam["voteDate"].at(1);
      }

      // 查询
      this.$refs.table.refresh(true);
    },

    // 更新活动状态
    updateActivityEnable(record, enable, operateName) {
      // 询问框
      this.$confirm({
        title: "操作确认",
        content: "您确认要" + operateName + "该活动吗？",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          // 数据封装
          let data = {
            activityId: record.activityId,
            enable: enable,
          };

          // 调用接口
          updateActivityEnable(data)
            .then((res) => {
              // 返回成功
              if (res.success) {
                this.$message.success("操作成功");
                this.initRelatedParam();
                this.$refs.table.refresh();
                // 返回失败
              } else {
                //TODO + res.message
                this.$warning({
                  okText: "确定",
                  title: "提示信息",
                  content: "操作失败，原因：" + res.data,
                });
              }
              // 异常
            })
            .catch((err) => {
              this.$error({
                okText: "确定",
                title: "提示信息",
                content: "操作失败，原因：" + err.response.data.message,
              });
            });
        },
        // 取消按钮
        onCancel() {},
      });
    },

    // 删除
    activityDelete(record) {
      // 询问框
      this.$confirm({
        title: "删除确认",
        content: "您是否要删除吗？",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          // 调用接口
          vActivityInfoDelete(record)
            .then((res) => {
              // 返回成功
              if (res.success) {
                this.$message.success("删除成功");
                this.$refs.table.refresh();
                // 返回失败
              } else {
                //TODO + res.message
                this.$warning({
                  okText: "确定",
                  title: "提示信息",
                  content: "删除失败，原因：" + res.message,
                });
              }
              // 异常
            })
            .catch((err) => {
              this.$error({
                okText: "确定",
                title: "提示信息",
                content: "删除失败，原因：" + err.response.data.message,
              });
            });
        },
        // 取消按钮
        onCancel() {},
      });
    },

    // -> 基础功能
    // 切换page 与 组件
    visabledModal(operation, record, see) {
      // 可见性改变
      this.visible = !this.visible;
      // 可见性为false时   展开组件
      if (!this.visible && operation) {
        setTimeout(() => {
          if (operation == "add") {
            this.$refs.modal.add();
          } else {
            this.$refs.modal.operate(record, see);
          }
        }, 100);
      }
    },

    // 高级搜索切换
    toggleAdvanced() {
      this.advanced = !this.advanced;
      this.queryRows.current = this.advanced ? this.queryRows.count : 1;
    },

    // 子组件ok回执
    handleOk() {
      this.visible = !this.visible;
      this.$refs.table.refresh();
    },

    // 表格行变色 selectRowKey
    selectRow(record) {
      return {
        props: {},
        on: {
          click: () => {
            this.selectRowKey = record.activityId;
          },
        },
      };
    },

    // 表格行变色 class
    rowClassName(record) {
      return record.activityId === this.selectRowKey ? "selectRow" : "";
    },
  },
};
</script>

<style scoped lang="less">
</style>
